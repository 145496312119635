  <!-- Display tiers --->
  <div class="container">
    <div class = "left-column1"><img src="assets/0db.png" style="width: 40px; height: auto;"></div>
    <div class="left-column">
      <app-text-bar [initialText]="'Unassigned'" [tierId]=null></app-text-bar>
          <div *ngFor="let number of getUnassignedRestaurants()">
              <input type="checkbox" [checked]="number.selected" (change)="toggleSelection(number.restaurantNum)">
              {{number.restaurantNum}}  
              <div *ngIf="number.selected" ngbDropdown class="btn-group dropleft">
                <button class="btn adjustbtn" ngbDropdownToggle style="width: auto; height: auto; padding: 0; border: none; background: transparent; margin-left: 10px;"></button>
                <div ngbDropdownMenu class="dropdown-menu">
                  <div *ngFor="let number of getTiers()">
                  <button ngbDropdownItem (click)="assignToTier(number.tierId)">Move to {{getTierName(number.tierId)}}</button>
                </div></div>
            </div>
          </div>
    </div>
    <div class="vl"></div>
    <div class="right-column">
      <button (click)="addTier()" style="display: flex; align-items: center; background-color: white; color: black; border: none; cursor: pointer; margin-bottom: 30px;">
        <img src="assets/plus-icon.png" alt="Add" style="width: 20px; height: 20px; margin-right: 10px;">
        Add Group
      </button>
      <div *ngFor="let number of getTiers()">
        <app-text-bar [initialText]="getTierName(number.tierId)" [tierId]="number.tierId"></app-text-bar>
        <div class="container"></div>
          <div *ngFor="let number of getTierNumbers(number.tierId)">
            <input type="checkbox" [checked]="number.selected" (change)="toggleSelection(number.restaurantNum)">
            {{number.restaurantNum}}
            <div *ngIf="number.selected" ngbDropdown class="btn-group dropleft">
              <button class="btn adjustbtn" ngbDropdownToggle style="width: auto; height: auto; padding: 0; border: none; background: transparent; margin-left: 10px;">
              </button>
              <div ngbDropdownMenu class="dropdown-menu">
                <div *ngFor="let number of getTierIDs()">
                <button ngbDropdownItem (click)="assignToTier(number)">Move to {{getTierName(number)}}</button>
              </div></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- here
    <div *ngFor="let tier of getTiers()">
      {{tier.name}}
      //
    <div *ngFor="let restaurant of getRestaurants()">
      {{restaurant.restaurantNum}}
    </div> --->