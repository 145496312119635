import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import IdTokenVerifier from 'idtoken-verifier';
import { RestaurantService } from 'src/app/services/restaurant.service';
import { SharedEventService } from 'src/app/services/shared-event.service';
import { UiComponentsService } from 'src/app/services/ui-components.service';

@Component({
  selector: 'app-click-thru-modal',
  templateUrl: './click-thru-modal.component.html',
  styleUrls: ['./click-thru-modal.component.scss']
})
export class ClickThruModalComponent implements OnInit, OnChanges {

  @ViewChild('content', { static: false })
  private content;
  @Input()
  userId: string;
  componentData = '';
  settings: any;
  existingUserSettingId: any;
  static acceptedTerms = false;
  windowRef: Window;
  isSubAccount = false;
  roles: any[] = [];
  memberOfRecordUserId: string;
  constructor(private restaurantService: RestaurantService, private uiComponentsService: UiComponentsService, private sharedEventService: SharedEventService, private modalService: NgbModal, @Inject('Window') window: Window) {
    this.windowRef = window;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userId'] && changes['userId'].currentValue) {
      const token = (this.windowRef as any).userTK;
      var verifier = new IdTokenVerifier({ issuer: 'RSI', audience: 'RMP' });
      var tokenPayload = verifier.decode(token);
      let roleStr = (tokenPayload.payload as any)?.userRoles;
      if (!roleStr) {
        roleStr = '[]';
      }
      const firstName = (tokenPayload.payload as any).firstName;
      const lastName = (tokenPayload.payload as any).lastName
      const userId = (tokenPayload.payload as any).userId;
      const parentReportUnitNumber = (tokenPayload.payload as any).parentReportUnitNumber;
      const memberOfRecordFirstNm = (tokenPayload.payload as any)?.memberOfRecordFirstNm ?? '';
      const memberOfRecordLastNm = (tokenPayload.payload as any)?.memberOfRecordLastNm ?? '';
      this.memberOfRecordUserId = (tokenPayload.payload as any)?.memberOfRecordUserId ?? '';
      const lastLogin = (tokenPayload.payload as any)?.lastLogin ?? undefined;
      this.roles = JSON.parse(roleStr);
      const hasMR = this.roles.find(ele => {
        return ele.RoleNm == 'Member of Record';
      });

      console.log(hasMR, ' ', parentReportUnitNumber);
      const searchTerm = `?Filters=userId==${changes['userId'].currentValue}`;
      const memSearchTerm = `?Filters=userId==${this.memberOfRecordUserId}`;
      let memRecData: any;
      this.restaurantService.GetUserSettings(memSearchTerm).subscribe(data => {
        if (data && (data as Object[]).length > 0) {
          memRecData = data[0];
        }
        const lastRecordedLogin = (data && (data as Object[]).length > 0 && data[0]['settingsDocument']) ? data[0]['settingsDocument']['lastLogin'] : undefined;
        const isBeforeRSILastLogin = dayjs(lastLogin).isAfter(lastRecordedLogin);
        console.log(lastLogin, ' rsi ', lastRecordedLogin, ' ', isBeforeRSILastLogin)

        if (this.memberOfRecordUserId == userId) { // member of record is logged in
          if (!data || (data as Object[]).length == 0 || !data[0]['settingsDocument'] || !data[0]['settingsDocument']['acceptedTermsOfService'] ) { // if member of record accepted
            if (memRecData != null){
              this.settings = memRecData;
              this.existingUserSettingId = memRecData.id
            }
      
            this.showConsentForm(hasMR, parentReportUnitNumber, memberOfRecordFirstNm, memberOfRecordLastNm, true);
          }
        } else { // not member of record
          this.restaurantService.GetUserSettings(searchTerm).subscribe(user => { // check if user accepted
            if (memRecData && memRecData['settingsDocument']['acceptedTermsOfService']){ //member of record already accepted
              if (!user || (user as Object[]).length ==  0 ||  !user[0] || !user[0]['settingsDocument'] || !user[0]['settingsDocument']['acceptedTermsOfService'] || !user[0]['settingsDocument']['lastLogin'] || (dayjs(lastLogin).isAfter(user[0]['settingsDocument']['lastLogin']))){
                if (user && user[0]){
                  this.settings = user[0];
                  this.existingUserSettingId = user[0].id;

                }
                this.showConsentForm(hasMR, parentReportUnitNumber, memberOfRecordFirstNm, memberOfRecordLastNm, true);
  
              }
              
            } else{ //member of record has not accepted yet
              this.showConsentForm(hasMR, parentReportUnitNumber, memberOfRecordFirstNm, memberOfRecordLastNm, false);
            }

            // if (memRecData && memRecData['settingsDocument']['lastLogin'] && dayjs(memRecData['settingsDocument']['lastLogin']).date() == dayjs().date()) {
            //   const ownerLastRecordedLogin = memRecData['settingsDocument']['lastLogin'];
            //   const isBeforeRSILastLogin = dayjs(lastLogin).isAfter(ownerLastRecordedLogin);
            //   if ((user as Object[]).length == 0 || isBeforeRSILastLogin) {
            //     if ((user as Object[]).length > 0) {
            //       this.settings = user[0];
            //       this.existingUserSettingId = user[0].id;
            //     }

            //     const searchTerm = `?Filters=userId==${this.memberOfRecordUserId}`;

            //     const userLastRecordedLogin = (user && (user as Object[]).length > 0 && user[0]['settingsDocument']) ? user[0]['settingsDocument']['lastLogin'] : undefined;
            //     const isBeforeUserRSILastLogin = dayjs(userLastRecordedLogin).isAfter(lastLogin);
            //     console.log(userLastRecordedLogin);
            //     console.log(lastLogin);
            //     if (userLastRecordedLogin == null || isBeforeUserRSILastLogin) {
            //       this.showConsentForm(hasMR, parentReportUnitNumber, memberOfRecordFirstNm, memberOfRecordLastNm, true);
            //     }

            //   } else {
            //     this.showConsentForm(hasMR, parentReportUnitNumber, memberOfRecordFirstNm, memberOfRecordLastNm, true);
            //   }

            // } else {
            //   this.showConsentForm(hasMR, parentReportUnitNumber, memberOfRecordFirstNm, memberOfRecordLastNm, false);
            // }

          });
        }
      });
    }
  }

  showConsentForm(hasMR: any, parentReportUnitNumber: any, memberOfRecordFirstNm: string, memberOfRecordLastNm: string, showConsentForm: boolean) {
    if (showConsentForm) {
      this.uiComponentsService.getUIComponentByType('terms-of-use').subscribe(terms => {
        this.showModal(terms[0]);
      });
    } else {

      if (hasMR && parentReportUnitNumber == "0") {
        this.uiComponentsService.getUIComponentByType('terms-of-use').subscribe(terms => {
          this.showModal(terms[0]);
        });
      } else {
        this.isSubAccount = true;
        this.uiComponentsService.getUIComponentByType('terms-of-use-m-of-r').subscribe(terms => {
          let term = terms[0];
          term.componentData = term.componentData.replace('{{first}}', memberOfRecordFirstNm);
          term.componentData = term.componentData.replace('{{last}}', memberOfRecordLastNm);
          this.showModal(term);
        });
      }
    }
  }

  ngOnInit(): void {

  }

  showModal(data: any) {
    this.componentData = data.componentData;
    this.modalService.open(this.content, { size: 'xl', beforeDismiss: this.dismiss, modalDialogClass: 'modal-50' }).result.then(
      (result) => {
      },
      (reason) => {
      },
    );
  }
  dismiss() {
    if (!ClickThruModalComponent.acceptedTerms) {
      return false;
    }
    return true;
  }
  agree() {
    if (this.settings) {
      this.settings.settingsDocument.memberOfRecordUserId = this.memberOfRecordUserId;
      this.settings.settingsDocument.lastLogin = dayjs().format('MM/DD/YYYY HH:mm:ss')
      this.settings.settingsDocument.acceptedTermsOfService = true;
    }
    ClickThruModalComponent.acceptedTerms = true;
    if (this.existingUserSettingId) {
      this.restaurantService.UpdatePricingAlerts(this.existingUserSettingId, this.settings).subscribe(data => {
        this.sharedEventService.refreshUserSettings.emit(true);
        this.modalService.dismissAll();
        window.scrollTo(0, 0);
      });
    } else {
      this.restaurantService.CreateAcceptedTermsUserSetting(this.userId).subscribe(rep => {
        this.sharedEventService.refreshUserSettings.emit(true);
        this.modalService.dismissAll();
        window.scrollTo(0, 0);
      });
    }
  }
}
