import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class MenuCategoryService {
  
     apiRoot: string = `${environment.menuUrl}/api/category/`;

     constructor(private http: HttpClient){
     }

     getAll() {
        let result = this.http.get(this.apiRoot);
        return result;
      }

      GetMenuCategory(){
        let result = this.http.get(`${environment.menuUrl}/api/conceptplus/GetMenuCategory`);
        return result;
      }
  }