<div class="box">
    <div *ngIf="loadSpinner" class="d-flex justify-content-center align-items-center">
        <div class="spinner-border" role="spinnerIcon"></div>
    </div>
    <!-- <div *ngIf="(!menuItems || menuItems.length ===0)&& !loadSpinner" class="text-center text-danger">You must first save a model to be able to view the impact module. Please visit the modeling tab to save a model first</div> -->
    <div *ngIf="(!menuItems || menuItems.length ===0)&& !loadSpinner && !timePeriodSelected" class="text-center">Please select the model, price change date, and time period you would like to view</div>
    <div *ngIf="(!menuItems || menuItems.length ===0)&& !loadSpinner && timePeriodSelected" class="text-center">No model data availbale for the selected  combination</div>
    <div *ngIf="!menuItems || menuItems.length ===0" class="no-data d-flex justify-content-center align-items-center">
        <table>
            <tr style="position: sticky; top:0; background-color: white;">
                <th>
                </th>
                <th>
                    <div>
                        <div class='d-flex align-items-center justify-content-center heading-cell'></div>
                        <div class="row sub-cloumn" style="width: 100%;">
                            <span class="col sub-column-text" style="width: 100%;">
                                <span class="d-flex align-items-center"></span>
                            </span>
                        </div>
                    </div>
                </th>
                <th>
                    <div>
                        <div class='d-flex align-items-center justify-content-center heading-cell'></div>
                        <div class="row sub-cloumn" style="width: 100%;">
                            <span class="col sub-column-text" style="width: 100%;">
                                <span class="d-flex align-items-center"></span>
                            </span>
                        </div>
                    </div>
                </th>
                <th>
                    <div class='d-flex align-items-center justify-content-center heading-cell'> </div>
                    <div class="row sub-cloumn">
                        <span class="col sub-column-text"></span>
                        <span class="col-5 sub-column-text" style="display: flex; flex-direction: column;">
                            <span></span> 
                            <span style="font-size: 10px;"></span> 
                        </span>
                    </div>
                </th>
                <th >
                    <div class='d-flex align-items-center justify-content-center heading-cell'></div>
                    <div class="row sub-cloumn">
                        <span class="col sub-column-text"></span>
                        <span class="col-5 sub-column-text" style="display: flex; flex-direction: column;">
                            <span></span> 
                            <span style="font-size: 10px;"></span> 
                        </span>
                    </div>
                </th>
                <th >
                    <div class='d-flex align-items-center justify-content-center heading-cell'></div>
                    <div class="row sub-cloumn" >
                        <span class="col sub-column-text"></span>
                        <span class="col-5 sub-column-text" style="display: flex; flex-direction: column;">
                            <span></span> 
                            <span style="font-size: 10px;"></span> 
                        </span>
                       
                    </div>
                </th>
            </tr>
            <tr *ngFor="let item of dummyItems; let i = index" [ngClass]="{'shaded-cell':i%2===1}">
                <td class="justify-content-center align-items-center" style="background-color: white;text-align: right; width: 0;"></td>
                <td class="justify-content-center align-items-center" style="background-color: white;text-align: right; width: 0;"></td>
            
                <td>
                    <div class="row d-flex justify-content-center align-items-center">
                        <span class="d-flex justify-content-center align-items-center" style="width: fit-content;">
                        </span>
                    </div>
                </td>
                <td>
                    <div class="row d-flex justify-content-center align-items-center">
                        <span class="col sub-column-price"></span>
                        <span class="col sub-column-price" style="font-weight: 100;"></span>
                    </div>
    
                </td>
                <td>
                    <div class="row d-flex justify-content-center align-items-center">
                        <span class="col sub-column-price"></span>
                        <span class="col sub-column-price"style="font-weight: 100;" ></span>
                    </div>
                </td>
                <td>
                    <div class="row d-flex justify-content-center align-items-center">
                        <span class="col sub-column-price"></span>
                        <span class="col sub-column-price" style="font-weight: 100;"></span>
                    </div>
    
                </td>
            </tr>
        </table>
    </div>
    <div><span class="d-flex align-items-center" style="font-weight: bold;
        font-style: italic;
        font-size: 14px;" *ngIf="menuItems && menuItems.length !==0">Restaurant #{{(restaurantNum| number :
        '6.0-0').replace(',','')}}</span></div>
    <table *ngIf="menuItems && menuItems.length !==0">
        <tr style="position: sticky; top:0; background-color: white;">
            <th>
            </th>
            <th>
                <div>
                    <div class='d-flex align-items-center justify-content-center heading-cell'>Plu Name</div>
                    <div class="row sub-cloumn" style="width: 100%;">
                        <span class="col sub-column-text" style="width: 100%;">
                            <span class="d-flex align-items-center"></span>
                        </span>
                    </div>
                </div>
            </th>
            <th>
                <div>
                    <div class='d-flex align-items-center justify-content-center heading-cell'>Price Change ($)</div>
                    <div class="row sub-cloumn" style="width: 100%;">
                        <span class="col sub-column-text" style="width: 100%;">
                            <span class="d-flex align-items-center">{{getImpactDate()  | date:'MM/dd/yyyy' }}</span>
                        </span>
                    </div>
                </div>
            </th>
            <th>
                <div class='d-flex align-items-center justify-content-center heading-cell'> AUVs (Units) </div>
                <div class="row sub-cloumn">
                    <span class="col sub-column-text">Projected</span>
                    <span class="col sub-column-text" style="display: flex; flex-direction: column;">
                        <span>Actual</span> 
                        <span style="font-size: 10px;">{{lastWeekRange}}</span> 
                    </span>
                </div>
            </th>
            <th >
                <div class='d-flex align-items-center justify-content-center heading-cell'>Sales ($) Impact</div>
                <div class="row sub-cloumn">
                    <span class="col sub-column-text">Projected</span>
                    <span class="col sub-column-text" style="display: flex; flex-direction: column;">
                        <span>Actual</span> 
                        <span style="font-size: 10px;">{{lastWeekRange}}</span> 
                    </span>
                </div>
            </th>
            <th >
                <div class='d-flex align-items-center justify-content-center heading-cell'>GP ($) Impact</div>
                <div class="row sub-cloumn" >
                    <span class="col sub-column-text">Projected</span>
                    <span class="col sub-column-text" style="display: flex; flex-direction: column;">
                        <span>Actual</span> 
                        <span style="font-size: 10px;">{{lastWeekRange}}</span> 
                    </span>
                   
                </div>
            </th>
        </tr>
        <tr *ngFor="let item of menuItems; let i = index" [ngClass]="{'shaded-cell':i%2===1}">
            <td class="justify-content-center align-items-center" style="background-color: white;text-align: right; width: 0;">{{item.menuItemName}}</td>
            <td class="justify-content-center align-items-center" style="background-color: white;text-align: right; width: 0;">{{item.pluName}}</td>
        
            <td>
                <div class="row d-flex justify-content-center align-items-center">
                    <span class="d-flex justify-content-center align-items-center" style="width: fit-content;">
                        <span class="col sub-column-price pill" > <i class="bi bi-currency-dollar"></i> {{(item.overridePrice==0||item.overridePrice==null)? '0.00': (item.overridePrice-item.pluPrice |number:'1.1-2') }}</span>
                    </span>
                </div>
            </td>
            <td>
                <div class="row d-flex justify-content-center align-items-center">
                    <span class="col sub-column-price">{{getProrate(item.projectedUnits) |number:'1.2-2'}}</span>
                    <span class="col sub-column-price" style="font-weight: 100;">{{item.regUnits|number:'1.2-2'}}</span>
                </div>

            </td>
            <td>
                <div class="row d-flex justify-content-center align-items-center">
                    <span class="col sub-column-price">${{getProrate(item.projectedAmt) |number:'1.2-2'}}</span>
                    <span class="col sub-column-price"style="font-weight: 100;" >${{item.regAmt|number:'1.2-2'}}</span>
                </div>
            </td>
            <td>
                <div class="row d-flex justify-content-center align-items-center">
                    <span class="col sub-column-price">${{getProrate(item.gpProjectedAmt) |number:'1.2-2'}}</span>
                    <span class="col sub-column-price" style="font-weight: 100;">${{item.gpAmt|number:'1.2-2'}}</span>
                </div>

            </td>
        </tr>
    </table>
</div>