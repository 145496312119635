import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-bk-benchmark',
  templateUrl: './bk-benchmark.component.html',
  styleUrls: ['./bk-benchmark.component.scss']
})
export class BkBenchmarkComponent implements OnInit {
  assetUrl:String;
  @Input() benchMark;
  @Input() index;
  constructor() { }

  ngOnInit(): void {
    if (environment?.isTestEnvironment && this.benchMark.addr1) {
      
        const regex = new RegExp("[a-zA-Z0-9.]", "g");
        this.benchMark['addr1'] = this.benchMark['addr1'].replace(regex, '*');
        this.benchMark['cityName'] = this.benchMark['cityName'].replace(regex, '*');
        this.benchMark['stateCd'] = this.benchMark['stateCd'].replace(regex, '*');
        this.benchMark['zipCd'] = this.benchMark['zipCd'].replace(regex, '*');
      
    }
    this.assetUrl = environment.assetUrl;
  }

}
