import { NgModule } from "@angular/core";
import { CompetitorCardComponent } from './competitor-card/competitor-card.component';
import { StarRatingModule } from "levon-angular-star-rating";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbCarousel, NgbCarouselConfig, NgbCarouselModule, NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { PricingTableComponent } from './pricing-table/pricing-table.component';
import { TabViewComponent } from './tab-view/tab-view.component';
import { MatBadgeModule, MatProgressSpinnerModule, MatSpinner, MatStepper, MatStepperModule } from "@angular/material";
import { NgxSpinnerModule } from "ngx-spinner";
import { BkBenchmarkComponent } from './bk-benchmark/bk-benchmark.component';
import { ToastsContainerComponent } from "./toast-container/toast-container";
import { SalesCardsComponent } from './sales-cards/sales-cards.component';
import { TextMaskModule } from "angular2-text-mask";
import LandingDataComponent from './landing-data/landing-data.component';
import { DummyCompetitorCardComponent } from "./dummy-competitor-card/dummy-competitor-card.component";
import { ClickThruModalComponent } from './click-thru-modal/click-thru-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgxSpinnerModule,
    NgbTooltipModule,
    NgbCarouselModule,
    MatBadgeModule,
    TextMaskModule 
  ],
  declarations: [
    CompetitorCardComponent,
    PricingTableComponent,
    TabViewComponent,
    SalesCardsComponent,
    LandingDataComponent,
    DummyCompetitorCardComponent,
    ClickThruModalComponent
  ],
  exports: [
    CompetitorCardComponent,
    PricingTableComponent,
    TabViewComponent,
    SalesCardsComponent,
    LandingDataComponent,
    DummyCompetitorCardComponent,
    ClickThruModalComponent
  ],
  providers:[
    NgbCarouselConfig,
    NgbCarousel
  ]
})
export class ComponentsModule { }