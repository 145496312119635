import { computeMsgId } from '@angular/compiler';
import { Component, ElementRef, HostListener, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbCarousel, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, concat, delay, forkJoin } from 'rxjs';
import { MenuItemPriceService } from 'src/app/services/menu-item-price.service';
import { SharedEventService } from 'src/app/services/shared-event.service';
import { environment } from 'src/environments/environment';
import { RestaurantService } from 'src/app/services/restaurant.service';
import IdTokenVerifier from 'idtoken-verifier';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: '[pricing-table]',
  templateUrl: './pricing-table.component.html',
  styleUrls: ['./pricing-table.component.scss']
})
export class PricingTableComponent implements OnInit, OnChanges {

  @Input()
  pricingInput: any;
  @Input()
  selectedCategoryId: string;
  @Input()
  selectedConceptId: string;
  @Input()
  user: string;
  @Input()
  restaurants: any[];
  @Input()
  scrollY: number = 0;
  @Input()
  ServiceMode: string = '';
  @Input()
  subCategory: string = '';
  @Input()
  availableSoldTypes;
  @Input()
  mcDonaldsId;
  @Input()
  refactoredData;
  selectedRestaurantNumber: string;
  pricingDataLoaded:boolean = false;
  refactoredMenuItems: any;
  radioSelected: string = 'QPC';
  competitors: any;
  tableData: any ;
  copyTableData: any;
  isLoading: boolean = false;
  showEnterData = true;
  showAddNotifications = false;
  disableSave = false;
  brandNotification: string = '';
  brandAddress: string = '';
  itemRestaurantNum: string = '';
  alertFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  assetsUrl: string;
  textSave: string = 'Enter Data';
  ItemsToUpdate: any[] = [[], [], [], [], []];
  editMode: boolean[] = [false, false, false, false, false];
  newValue: any = [];
  tokenPayload: any = [];
  header: string = "";
  priceRevisionHistory: any = [];
  windowRef: Window;
  alertItems: any = [];
  numAlerts = 1;
  itemAlertForm: any;
  changeInPriceRange: any[] = [];
  showSetupAlertPercentage = false;
  setupAlertError = false;
  pricingAlerts = [];
  userId: string = "";
  userEmail: string = "";
  alertEmail: string = "";
  alertPhone: string = "";
  existingUserSettingId;
  existingSettingsDoc;
  showAlertConfig = true;
  showSuccessDelete = false;
  some_text = "Click Here";
  inside = true;
  storeHasItems = [];
  @ViewChild('ngcarousel', { static: false })
  ngCarousel: NgbCarousel;
  alertsSaved = false;
  showInvalidEmailMsg = false;
  showInvalidPhoneMsg = false;
  priceSourceCall;
  priceRevisionCall;
  selectedSoldTypeId = [];
  groupedData = {};
  bkResMenuItems;
  unPricedData;
  compResIds=[];
  seeChangeHistory = null;
  @ViewChild('ngcarousel') set content(content: NgbCarousel) {
    if (content) { // initially setter gets called with undefined
      this.ngCarousel = content;
    }
  }
  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

  constructor(private sharedEventService: SharedEventService, private modalService: NgbModal, private toastr: ToastrService, private _formBuilder: FormBuilder, private menuItemPriceService: MenuItemPriceService, private restaurantService: RestaurantService, @Inject('Window') window: Window) {
    this.windowRef = window;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.pricingDataLoaded=false;
    if (changes['pricingInput']) {
      this.editMode = [false, false, false, false, false];
      this.ItemsToUpdate = [[], [], [], [], []];
      const input = changes['pricingInput'].currentValue;
      this.selectedRestaurantNumber = input.selectedRestaurantNumber;
      this.competitors = input.competitors;
      this.isLoading = true;
      if (this.competitors) {
        this.refreshPricingTable();
      }
    } else if (changes['selectedCategoryId']) {
      this.selectedCategoryId = changes['selectedCategoryId'].currentValue;
      this.isLoading = true;
      if (this.competitors) {
        this.refreshPricingTable();
      }
    } else if (changes['subCategory']) {
      this.selectedCategoryId = changes['subCategory'].currentValue;
      this.isLoading = true;
      if (this.competitors) {
        this.refreshPricingTable();
      }
    } else if (changes['ServiceMode']) {
      this.ServiceMode = changes['ServiceMode'].currentValue;
      this.isLoading = true;
      if (this.competitors) {
        this.refreshPricingTable();
      }
    } else if (changes['availableSoldTypes']) {
      this.selectedSoldTypeId = [];
      if (this.competitors) {
        this.refreshPricingTable();
      }
    }
  }



  ngOnInit(): void {
    this.assetsUrl = environment.assetUrl;
    this.sharedEventService.tabChange.emit('Newest Price')
    this.sharedEventService.tabChange.subscribe(tab => {
      this.header = tab;
    });

    this.itemAlertForm = this._formBuilder.group({
      alertItems: this._formBuilder.array([new FormGroup({
        menuItemName: new FormControl(''),
        menuitemId: new FormControl(''),
        changeInPrice: new FormControl('')
      })])
    });
    let num = 0.05;

    while (num <= 1) {
      this.changeInPriceRange.push(num.toFixed(2));
      num += .05;
    }
    const token = (this.windowRef as any).userTK;
    var verifier = new IdTokenVerifier({ issuer: 'RSI', audience: 'RMP' });
    var tokenPayload = verifier.decode(token);
    const email = tokenPayload.payload['email'];
    this.userEmail = email;
    this.userId = tokenPayload.payload['userId'];
    this.getUserSettings(this.userId);
    this.sharedEventService.refreshUserSettings.subscribe(val => {
      this.getUserSettings(this.userId);
    });
  }

  getUserSettings(userId: string) {
    var searchTerm = `?Filters=userId==${userId}`;
    this.restaurantService.GetUserSettings(searchTerm).subscribe(data => {
      if (data && (data as any).length > 0) {
        this.existingSettingsDoc = data[0];
        this.existingUserSettingId = data[0].id;
        if (data[0].settingsDocument && data[0].settingsDocument['pricing_alerts']) {
          this.pricingAlerts = data[0].settingsDocument['pricing_alerts'];
        }
      }
    });
  }

  @HostListener("document:click", ['$event'])
  clickedOut(e) {
    if (!e.target.id.includes("PriceInput") && e.target.id != 'saveBtn' && !e.target.outerHTML.includes("input")) {
      this.CancelEdit();
    }
  }

  async refreshPricingTable() {
    this.selectedSoldTypeId = [];
    this.availableSoldTypes?.forEach(element => {
      if (element.isSelected) {
        this.selectedSoldTypeId.push(element.id)
      }
    });
    if (this.selectedSoldTypeId.length != 1) {
      this.selectedSoldTypeId = null;
    }

    this.isLoading = true;
    let pricingSources: Array<Observable<any>> = [];
    let pricingHistory: Array<Observable<any>> = [];
    this.subCategory = this.subCategory?.replace(/&/g, '%26');
    const cmps = this.competitors;
    const bkStore = cmps[0];
    let priceCmpRestIds = [];
    for (var i = 1; i < cmps?.length; i++) {
      priceCmpRestIds.push(cmps[i].id);
    }
    this.compResIds=priceCmpRestIds;
      pricingSources.push(this.menuItemPriceService.getCompetitorPluPriceForGroup(bkStore.id, bkStore.competitorId, this.subCategory, this.ServiceMode, this.selectedSoldTypeId ? this.selectedSoldTypeId[0] : null));
    if(priceCmpRestIds && priceCmpRestIds.length!=0){
      pricingSources.push(this.menuItemPriceService.getCompetitorPluPriceForCompetitorGroup(priceCmpRestIds.join(','), this.subCategory, this.ServiceMode, this.selectedSoldTypeId ? this.selectedSoldTypeId[0] : null));
   }
      if (this.priceSourceCall) {
        this.priceSourceCall.unsubscribe();
      }
      if (this.priceRevisionCall) {
        this.priceRevisionCall.unsubscribe();
      }
  
      this.priceSourceCall = forkJoin(pricingSources).subscribe(async results => {
        this.groupedData = {};
        let arrs: Array<any> = [];
        this.groupedData[bkStore.id] = [];
        priceCmpRestIds.forEach((el) => {
          this.groupedData[el] = [];
        })
      if(!results[1]){
        results[1]=[];
      }
        const allData = arrs.concat(results[0], results[1]);
        
        let tempGroupedData = _.groupBy(allData, "competitorRestaurantId");
        _.each(tempGroupedData, (val, key) => {
          this.groupedData[key] = val;
        });
  
        this.bkResMenuItems = results[0];
        this.unPricedData = tempGroupedData['00000000-0000-0000-0000-000000000000'];
        let allCompRest = [];
        if (!this.unPricedData) {
          this.unPricedData = [];
        }
        this.checkRadioButton();
        this.menuItemPriceService.getMenuitemPluRevisionPriceForCategory(bkStore.id, bkStore.competitorId, this.subCategory, this.ServiceMode, this.selectedSoldTypeId ? this.selectedSoldTypeId[0] : null).subscribe({
          next: res => {
            allCompRest.push(res);
          }
        });
        let competitorRestaurantIds = [];
        for (let i = 1; i < this.competitors.length; i++) {
          competitorRestaurantIds.push(this.competitors[i].id)
        }
  
        if (this.priceRevisionCall) {
          this.priceRevisionCall.unsubscribe();
        }
      if(priceCmpRestIds && priceCmpRestIds.length!=0){
  
        this.menuItemPriceService.getCompetitorPluPriceRevisionsForCompetitorGroup(competitorRestaurantIds, this.subCategory, this.ServiceMode, this.selectedSoldTypeId ? this.selectedSoldTypeId[0] : null).subscribe({
          next: res => {
           
            let revSorce = _.groupBy(res, 'competitorRestaurantId');
            competitorRestaurantIds.forEach(ele => {
              allCompRest.push(revSorce[ele])
            })
            for (let i = 0; i < allCompRest.length; i++) {
              if (allCompRest[i] == undefined) {
                allCompRest[i] = [];
              }
            }
            this.priceRevisionHistory = allCompRest;
          }
        });
    }
    this.pricingDataLoaded = true;
    });

  }

  checkRadioButton() {
    let groupedData = JSON.parse(JSON.stringify(this.groupedData));
    let bkResMenuItemsRep = [];
    bkResMenuItemsRep = this.bkResMenuItems;
    const data = [];

    _.each(groupedData, (val, key) => {

      // val.sort((a, b ) => a.name < b.name ? -1 : 1);
      // if (key !== '00000000-0000-0000-0000-000000000000') {
        // if (val[0]?.competitorId == this.mcDonaldsId && this.subCategory == 'Whopper') {
        //   val.forEach((mcd, index) => {
        //     if (this.radioSelected == 'bigMac') {
        //       if (mcd.competitorPluName.includes('Quarter Pounder with Cheese') && !mcd.competitorPluName.includes('Bacon')) {
        //         val.splice(index, 1);
        //       }
        //     } else {
        //       if (mcd.competitorPluName.includes('Big Mac')) {
        //         val.splice(index, 1);
        //       }
        //     }
        //   })
        // }

        // bkResMenuItemsRep?.forEach(el => {
        //   let compId = cmps.filter(el1 => key == el1.id)[0]?.competitorId;
        //   let unpricedMenu = unPricedData.filter((el2=>el2.conceptPluId == el.conceptPluId && el2.competitorId == compId));
        //   let pricedArr = val.filter(el1 => el1.conceptPluId == el.conceptPluId);
        //   let items=[];
        //   let index = val.findIndex(el1 => el1.conceptPluId == el.conceptPluId);
        //   if (index == -1) {
        //     let unpricedMenuItemIndex = unPricedData.findIndex(el1 => el.conceptPluId == el1.conceptPluId && compId == el1.competitorId);
        //     if (unpricedMenuItemIndex != -1){
        //       let pricingListIndex = val.findIndex(el1 => el1.competitorPluId == unPricedData[unpricedMenuItemIndex].competitorPluId);            
        //       if(pricingListIndex != -1) {
        //         let currentPluPrice = JSON.parse(JSON.stringify(unPricedData[unpricedMenuItemIndex]));
        //         currentPluPrice.price = val[pricingListIndex].price;
        //         currentPluPrice.competitorRestaurantId = val[pricingListIndex].competitorRestaurantId;
        //         currentPluPrice.servicemodeId = val[pricingListIndex].servicemodeId;
        //         currentPluPrice.priceCount = val[pricingListIndex].priceCount;
        //         currentPluPrice.priceExtractedDt = val[pricingListIndex].priceExtractedDt;
        //         val.push(currentPluPrice);
        //       }
        //       else{
        //         unPricedData[unpricedMenuItemIndex].competitorRestaurantId = key;
        //       // if (compId == this.mcDonaldsId && this.subCategory == 'Whopper') {
        //       //   if (this.radioSelected == 'bigMac') {
        //       //     if (unPricedData[unpricedMenuItemIndex].competitorPluName.includes('Big Mac')) {
        //       //       unPricedData[unpricedMenuItemIndex].servicemodeId = this.ServiceMode;
        //       //       val.push(unPricedData[unpricedMenuItemIndex]);
        //       //     } else {
        //       //       el.competitorPluName = null;
        //       //       val.push(el);
        //       //     }
        //       //   }
        //       //   if (this.radioSelected == 'QPC') {
        //       //     if (unPricedData[unpricedMenuItemIndex].competitorPluName.includes('Quarter Pounder with Cheese') && !unPricedData[unpricedMenuItemIndex].competitorPluName.includes('Bacon')) {
        //       //       unPricedData[unpricedMenuItemIndex].servicemodeId = this.ServiceMode;
        //       //       val.push(unPricedData[unpricedMenuItemIndex]);
        //       //     } else {
        //       //       el.competitorPluName = null;
        //       //       val.push(el);
        //       //     }
        //       //   }
        //       // } else {
        //         unPricedData[unpricedMenuItemIndex].servicemodeId = this.ServiceMode;
        //         val.push(unPricedData[unpricedMenuItemIndex]);
        //       // }
        //       }
        //     }
        //   }
        //   if(unpricedMenu.length>1 && this.subCategory == 'All' ){
        //     if(pricedArr.length < unpricedMenu.length){
        //       items = _.differenceWith(unpricedMenu, (val.filter(el1 => el1.conceptPluId == el.conceptPluId)), function (o1, o2) {
        //         return o1['competitorPluId'] === o2['competitorPluId']})
        //         items.forEach(el=>{
        //           let pricingListIndex = val.findIndex(el1 => el1.competitorPluId == el.competitorPluId);            
        //           if(pricingListIndex != -1) {
        //             let currentPluPrice = JSON.parse(JSON.stringify(el));
        //             currentPluPrice.price = val[pricingListIndex].price;
        //             currentPluPrice.competitorRestaurantId = val[pricingListIndex].competitorRestaurantId;
        //             currentPluPrice.servicemodeId = val[pricingListIndex].servicemodeId;
        //             currentPluPrice.priceCount = val[pricingListIndex].priceCount;
        //             currentPluPrice.priceExtractedDt = val[pricingListIndex].priceExtractedDt;
        //             val.push(currentPluPrice);
        //           }else{
        //             val.push(el);
        //           }
        //         });
        //     }
        //   }
        // })
        data.push(val);
      // }
    });
    for (var i = 1; i < data.length; i++) {
      for (var j = 0; j < data[i].length; j++) {
        if (data[i][j]?.competitorPluName?.toLowerCase()?.indexOf(data[i][j]?.size?.toLowerCase()) >= 0) {
          data[i][j].competitorPluName = data[i][j].competitorPluName.replace(data[i][j].size, '');
        }
      }
    }
    this.tableData = data;
    this.isLoading = false;
    this.refactoredData = data;
    this.formatPluPricingTable();

  }

  formatPluPricingTable() {
    let data = this.refactoredData;

    data.forEach(el => {
      el = el.sort((a, b) => {
        return a.groupSortId - b.groupSortId || a.subgroupSortId - b.subgroupSortId || a.sortId - b.sortId;
      })
    });
    let refactoredBkMenuItems = new Array(data.length).fill([]);

    if (data.length > 1) {
      let compData = new Array(data.length - 1);
      let menuItemCount = new Array(data[0].length).fill({ name: '', count: 0 });

      data.forEach((el, index) => {
        if (index == 0) return;
        compData[index - 1] = this.groupBy(el, 'name');
      })

      for (let j = 0; j < data[0].length; j++) {
        for (let k = 0; k < compData.length; k++) {
          if (menuItemCount[j].count < compData[k][data[0][j].name].length) {
            menuItemCount[j] = { name: data[0][j].name, count: compData[k][data[0][j].name].length };
          }
        }
      }

      data.forEach((element, index) => {

        //for all elements 

        if (index == 0) {
          let tempMenuPrice = [];
          element.forEach(el => {
            let idx = menuItemCount.findIndex(menu => menu.name == el.name);
              for (let i = 0; i < menuItemCount[idx].count; i++) {
                tempMenuPrice.push(el);
              }
          })
          refactoredBkMenuItems[index] = tempMenuPrice;
        } else {
          let tempMenuPrice = [];
          for (const key in compData[index - 1]) {
            let idx = menuItemCount.findIndex(menu => menu.name == key);
            let diff = menuItemCount[idx].count - compData[index - 1][key].length;
            if (diff == 0) {
              compData[index - 1][key].forEach(el => {
                tempMenuPrice.push(el);
              })
            } else  {
              for (let i = 0; i < compData[index - 1][key].length * menuItemCount[idx].count ; i++) {
              compData[index - 1][key].forEach(el => {
                  tempMenuPrice.push(el);
                })
              }
            }
          }
          refactoredBkMenuItems[index] = tempMenuPrice;
        }
      })
    }
    else {
      refactoredBkMenuItems = data;
    }
    this.refactoredMenuItems = refactoredBkMenuItems;
    this.tableData = new Array(this.refactoredMenuItems[0].length)
      .fill(false)
      .map(() =>
        new Array(this.refactoredData.length).fill('')
      );

    this.refactoredMenuItems.forEach((el, index) => {
      for (let j = 0; j < el.length; j++) {
        this.tableData[j][index] = el[j];
      }
    });
    this.copyTableData = JSON.parse(JSON.stringify(this.tableData));
  }

  formatPricingTable(data: any) {
    let cmpsCopy = this.competitors.filter(ele => ele.num !== this.selectedRestaurantNumber);

    if (data?.length > 0) {
      const restaurantItems = data[0];
      const cmpData = data[1];
      // let cmpData = data[1];
      // if(cmpData.length==0){
      //   cmpData = [[],[],[],[]];
      // }
      this.tableData = new Array(restaurantItems.length)
        .fill(false)
        .map(() =>
          new Array(cmpData.length).fill('')
          // new Array(1 + cmpData.length).fill('')
        );
      for (var i = 0; i < restaurantItems.length; i++) { // populate first column
        this.tableData[i][0] = restaurantItems[i];
      }
      const cmpsItems = [];
      for (var i = 0; i < cmpsCopy.length; i++) { // order the competitor menuitem results

        let cmpItemArray = cmpData.find(ele => {
          if (ele?.length > 0) {
            return ele[0].restaurantNum === cmpsCopy[i].num;
          }

          return undefined;
        });
        cmpItemArray = cmpItemArray?.filter(ele => {

          return ele.restaurantNum === cmpsCopy[i].num && ele.competitorId === cmpsCopy[i].competitorId;


        })

        cmpsItems.push(cmpItemArray);
      }

      for (var i = 0; i < cmpsCopy.length; i++) {
        const tableCol = i + 1; // offset for first col
        const restaurantNum = cmpsCopy[i].num;
        const compId = cmpsCopy[i].competitorId;
        const itemArray = cmpsItems.find(ele => {
          if (ele?.length > 0) {
            return ele[0].restaurantNum === restaurantNum && ele[0].competitorId === compId;
          }
          return undefined;
        })
        if (itemArray) {
          const orderedArray = this.getRowsPositions(restaurantItems, itemArray, cmpData[cmpData.length - 1]);
          for (var j = 0; j < orderedArray.length; j++) {
            this.tableData[j][tableCol] = orderedArray[j];
          }
        } else {
          for (var j = 0; j < restaurantItems.length; j++) {
            let menuItemidx = cmpData[cmpData.length - 1].findIndex(e => e.restaurantMenuItemName == restaurantItems[j].menuitemName && compId == e.competitorId);
            if (menuItemidx >= 0) {
              this.tableData[j][tableCol] = cmpData[cmpData.length - 1][menuItemidx];
            }
            else {
              this.tableData[j][tableCol] = { noItemPrice: true };
            }
          }
        }
      }
    }
    this.copyTableData = JSON.parse(JSON.stringify(this.tableData));
  }

  request$(src: Observable<any>, num: number): Observable<Response> {
    const delayTime = num * 5;
    return src.pipe(delay(delayTime));
  }

  sleep = (ms) => new Promise(r => setTimeout(r, ms));

  groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };


  getRowsPositions(items, cmpItems, cmpData) {
    const pos = [];
    for (var i = 0; i < items.length; i++) {
      const item = items[i];
      const idx = cmpItems.findIndex(e => e.restaurantMenuItemName == item.menuitemName);
      if (idx >= 0) {
        pos.push(cmpItems[idx]);
      } else {
        let menuItemidx = cmpData.findIndex(e => e.restaurantMenuItemName == item.menuitemName && e.competitorId == cmpItems[0].competitorId);
        if (menuItemidx >= 0) {
          pos.push(cmpData[menuItemidx])
        }
        else {
          pos.push({ noItemPrice: true });
        }
      }
    }
    return pos;
  }
  save(i) {
    this.textSave = 'Save Changes';
    this.editMode[i] = true;
    this.inside = this.editMode[i];

  }
  get formAlertItems(): FormArray {
    return this.itemAlertForm.get("alertItems") as FormArray;
  }

  addAlertItem(item: any) {
    const group = new FormGroup({
      competitorPluName: new FormControl(''),
      competitorPluId: new FormControl(item.menuitemId, [Validators.required]),
      isIncreased: new FormControl(item.isIncreased, [Validators.required]),
      changeInPrice: new FormControl(item.changeInPrice ? (Math.abs(item.changeInPrice).toFixed(2)) : null, [Validators.required]),
      deleteItem: new FormControl(false)
    });
    this.formAlertItems.push(group);
  }
  colHasAlerts(row: any, data: any, idx: number, isPercentage) {
    const restaurantNum = this.competitors[idx].num;
    for (var i = 0; i < this.pricingAlerts?.length; i++) {
      const alert = this.pricingAlerts[i];
      if (alert?.restaurant_num == restaurantNum + '' && alert.subgroupName == this.subCategory) {
        return true;
      }
    }
    return false;
  }

  openSetupAlert(content: any, data: any, colIdx: number, isPercentage: boolean) {
    this.showAlertConfig = true;
    this.setupAlertError = false;
    this.showSuccessDelete = false;
    this.disableSave = false;
    this.alertsSaved = false;
    this.showSetupAlertPercentage = isPercentage;

    this.itemAlertForm = this._formBuilder.group({
      alertItems: this._formBuilder.array([])
    });
    const competitor = this.competitors[colIdx];
    this.brandAddress = competitor.addr1;
    this.itemRestaurantNum = competitor.num;
    if (competitor.competitorName) {
      this.brandNotification = competitor.competitorName;
    } else {
      this.brandNotification = "Burger King";
    }
    this.alertItems = [];
    this.numAlerts = 1;
    for (var i = 0; i < this.tableData.length; i++) {
      const item = this.tableData[i][colIdx];
      if (item?.competitorPluName && item?.competitorPluId != 0) {
        this.alertItems.push(item);
      }
    }
    this.storeHasItems = this.pricingAlerts?.filter(e => {
      return e?.restaurant_num === this.itemRestaurantNum + '' && e.subgroupName === this.subCategory;
    });
    if (this.storeHasItems?.length > 0) {
      for (var i = 0; i < this.storeHasItems.length; i++) {
        const item = this.storeHasItems[i];
        this.addAlertItem({
          menuitemId: item.competitor_plu_id
          , changeInPrice: item.delta,
          isIncreased: item.isPriceIncreased
        });
        this.pricingAlerts = this.pricingAlerts.filter(e => e.competitor_plu_id != item.cmmp);

      }
    } else if (this.alertItems?.length > 0) {
      this.addAlertItem({ menuitemId: null, changeInPrice: '', isIncreased: null });
    }
    if (this.storeHasItems?.length > 0) {
      this.alertEmail = this.storeHasItems[0].email;
      this.alertPhone = this.storeHasItems[0].mobilePhone;
      this.alertPhone = this.alertPhone.substring(1, this.alertPhone.length);
    } else {
      this.alertEmail = this.userEmail;
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'alertModal' }).result.then(
      (result) => {
      },
      (reason) => {
      },
    );
  }

  public isValidEmail(emailString: string): boolean {
    try {
      let pattern = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$");
      let valid = pattern.test(emailString?.toLocaleLowerCase());
      return valid;
    } catch (TypeError) {
      return false;
    }
  }

  public isValidPhone(phone: string): boolean {
    try {
      phone = phone.replace("(", "");
      phone = phone.replace(")", "");
      phone = phone.replace("-", "");
      phone = phone.replace(" ", "");
      let pattern = new RegExp("^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$");
      let valid = pattern.test(phone);
      return phone.length == 10 && valid;
    } catch (TypeError) {
      return false;
    }
  }

  continueAlerts() {
    let isDelete = false;
    let numDelete = 0;
    for (let i in this.formAlertItems.controls) {
      this.formAlertItems.controls[i].markAsTouched();
      if (this.formAlertItems.controls[i].invalid) {
        this.setupAlertError = true;
        return;
      }
      if (this.formAlertItems.controls[i].value.deleteItem) {
        numDelete += 1;
      }
    }
    this.setupAlertError = false;
    if (this.formAlertItems.controls?.length != numDelete) {
      this.showAlertConfig = !this.showAlertConfig;
    } else {
      this.saveAlerts();
      this.showSuccessDelete = !this.showSuccessDelete;
    }
  }

  saveAlerts() {
    this.alertsSaved = false;
    if (!this.isValidEmail(this.alertEmail)) {
      this.showInvalidEmailMsg = true;
      return;
    } else {
      this.showInvalidEmailMsg = false
    }
    if (this.alertPhone?.length > 0 && !this.isValidPhone(this.alertPhone)) {
      this.showInvalidPhoneMsg = true;
      return;
    } else {
      this.showInvalidPhoneMsg = false;
    }
    const reg = new RegExp("[() -]", "g");
    this.alertPhone = this.alertPhone.replace(reg, '');
    if (this.alertPhone.length > 0) {
      this.alertPhone = "1" + this.alertPhone;
    }
    for (let i in this.formAlertItems.controls) {
      this.formAlertItems.controls[i].markAsTouched();
      if (this.formAlertItems.controls[i].invalid) {
        this.setupAlertError = true;
        return;
      }
    }
    this.setupAlertError = false;

    const alertItemVals = this.formAlertItems.controls;
    let pricing_alerts = [];
    const deleteIds = [];
    if (this.storeHasItems?.length > 0) {
      for (var i = 0; i < this.storeHasItems.length; i++) {
        const item = this.storeHasItems[i];
        this.pricingAlerts = this.pricingAlerts.filter(e => e.competitor_plu_id != item.competitor_plu_id);
      }
    }
    for (var i = 0; i < alertItemVals.length; i++) {
      const competitorPluId = alertItemVals[i].value.competitorPluId;
      const itemExists = this.storeHasItems.find(e => {
        return e.competitor_plu_id == competitorPluId && e.restaurant_num == this.itemRestaurantNum;
      });
      if (!itemExists) {
        let phone = this.alertPhone.replace("(", "");
        phone = phone.replace(")", "");
        phone = phone.replace("-", "");
        phone = phone.replace(" ", "");
        const item = this.alertItems.find(x => x.competitorPluId == competitorPluId)
        const itemName = item.competitorPluName.replace(item.size,'') + ' ' + item.size;
        const alert = {
          email: this.alertEmail,
          mobilePhone: phone,
          competitorRestaurantId: item.competitorRestaurantId,
          competitor_plu_name: itemName,
          competitor_plu_id: competitorPluId,
          newPrice: +alertItemVals[i].value.changeInPrice === item.delta ? item.delta : 0,
          restaurant_num: this.itemRestaurantNum + "",
          restaurant_address: this.brandAddress,
          serviceModeId: item.servicemodeId,
          brand_name: this.brandNotification,
          SubgroupName: this.subCategory,
          delta: alertItemVals[i].value.isIncreased == 'true' ? 0 + (+alertItemVals[i].value.changeInPrice) : 0 - (+alertItemVals[i].value.changeInPrice),
          isPriceIncreased: alertItemVals[i].value.isIncreased == 'true' || alertItemVals[i].value.isIncreased == true ? true : false,
          price_alert_date: +alertItemVals[i].value.changeInPrice === item.delta ? item.delta : null
        };
        if (!alertItemVals[i].value.deleteItem) {
          pricing_alerts.push(alert);
        }
      } else {
        this.pricingAlerts.push(itemExists);
        const idx = this.pricingAlerts.findIndex(x => x.competitor_plu_id == competitorPluId);
        if (this.pricingAlerts[idx].delta != +alertItemVals[i].value.changeInPrice) {
          this.pricingAlerts[idx].price_alert_date = null;
        }
        this.pricingAlerts[idx].isPriceIncreased = alertItemVals[i].value.isIncreased == 'true' || alertItemVals[i].value.isIncreased == true ? true : false;
        this.pricingAlerts[idx].email = this.alertEmail;
        this.pricingAlerts[idx].mobilePhone = this.alertPhone;
        this.pricingAlerts[idx].delta = alertItemVals[i].value.isIncreased == 'true' ? 0 + (+alertItemVals[i].value.changeInPrice) : 0 - (+alertItemVals[i].value.changeInPrice);
      }
      if (alertItemVals[i].value.deleteItem) {
        this.pricingAlerts = this.pricingAlerts.filter(e => e.competitor_plu_id != competitorPluId);
        deleteIds.push(competitorPluId);
      }
    }
    pricing_alerts = pricing_alerts.filter(e => e !== null);
    this.pricingAlerts.push(...pricing_alerts);
    if (this.pricingAlerts && this.pricingAlerts.length > 0) {
      if (this.existingUserSettingId) {
        this.existingSettingsDoc.settingsDocument.pricing_alerts = this.pricingAlerts
        this.restaurantService.UpdatePricingAlerts(this.existingUserSettingId, this.existingSettingsDoc).subscribe(rep => {
          if (rep !== null) {
            this.existingSettingsDoc = rep;
            this.existingUserSettingId = rep.id;
          }
          this.alertsSaved = true;
          this.showAlertConfig = false;
          this.disableSave = true;
          if (deleteIds.length > 0) {
            this.showSuccessDelete = true;
          }
          this.refreshPricingAlerts();
        });

      } else {
        this.restaurantService.CreatePricingAlerts(this.userId, this.pricingAlerts).subscribe(rep => {
          this.existingSettingsDoc = rep;
          this.existingUserSettingId = rep.id;
          this.showAlertConfig = false;
          this.alertsSaved = true;
          this.disableSave = true;
          this.refreshPricingAlerts();
        });
      }
    } else if (deleteIds.length > 0) {
      this.existingSettingsDoc.settingsDocument.pricing_alerts = this.pricingAlerts
      this.restaurantService.UpdatePricingAlerts(this.existingUserSettingId, this.existingSettingsDoc).subscribe(rep => {
        if (rep !== null) {
          this.existingSettingsDoc = rep;
          this.existingUserSettingId = rep.id;
        }
        this.alertsSaved = true;
        this.showAlertConfig = false;
        this.showSuccessDelete = true;
        this.disableSave = true;
        this.refreshPricingAlerts();
      });
    }
    if (this.alertPhone?.length > 0 && this.pricingAlerts && this.pricingAlerts.length > 0 && (!this.existingSettingsDoc || !this.existingSettingsDoc.isSMSOptedIn || this.existingSettingsDoc.isSMSOptedIn == null)) {

      this.restaurantService.sendSMSOptinMessage(this.alertPhone).subscribe(rep => {
        console.log('sent sms ', rep);
      })
    }
  }

  getPriceChangeString(change: string, isPercentage: boolean) {
    const idx = change.indexOf('.');
    const str = change.substring(idx);
    if (change?.length > 0 && idx < 0 && !isPercentage) {
      return change + ".00";
    }
    if (str.length == 2 && !isPercentage) {
      return change + "0"
    }
    return change;
  }

  errorMsg: string = '';
  showError: boolean = false;
  updatePrice(data, price, i, j, showErr?) {
    if (this.copyTableData[i][j].price != +price) {
      let newPrice = parseFloat(price).toFixed(2);
      data.price = newPrice;
      data.priceExtractedDt = new Date().toISOString();
      data.priceSourceName = this.user;

      if (this.ItemsToUpdate[j].length > 0) {
        this.ItemsToUpdate[j].forEach(el => {
          if (el.id == data.id) {
            delete data.id;
            el = data;
          } else {
            delete data.id;
            if (data.price != 0) {
              this.ItemsToUpdate[j].push(data);
            }
          }
        })
      } else {
        delete data.id;
        if (data.price >= 0.01 && data.price <= 99.99) {
          this.ItemsToUpdate[j].push(data);
        } else if (data.price < 0.01) {
          this.errorMsg = 'Please enter a number greater than $0';
          showErr == "delPress" ? this.showError = false : this.showError = true;
          this.ItemsToUpdate[j].push(data);
        } else if (data.price > 99.99) {
          this.errorMsg = 'Please enter a number less than $100';
          this.showError = true;
        }
      }
    }
    else {
      this.errorMsg = 'Please Enter a New Price';
      this.showError = true;
      this.ItemsToUpdate[j].map((el, i) => {
        if (el.menuitemId == data.menuitemId) {
          this.ItemsToUpdate[j].splice(i, 1);
        }
      })
    }

  }

  saveChanges(i) {
    this.ItemsToUpdate[i].forEach(ele => {
      if (ele.price == 0) {
        this.errorMsg = 'Please enter a number greater than $0';
        this.showError = true;
      }
    });
    if (this.ItemsToUpdate[i].length > 0 && !this.showError) {
      this.menuItemPriceService.updateMenuitemPrice(this.ItemsToUpdate[i]).subscribe({
        next: res => {
          this.editMode[i] = false;
          this.ItemsToUpdate[i] = [];
          this.refreshPricingTable();
        },
        error: err => {
          this.toastr.error('Sorry the prices changes could not be saved at this time.');
          this.CancelEdit();
        }
      });

    }
  }
  numberOnly(e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[0-9.]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }

    e.preventDefault();
    return false;

  }

  getEmail() {
    // const email = this.userEmail.substring(3);
    return this.userEmail;
  }
  menuItemPreviousPrice: any = [];
  // menuItemPreviousPriceWithPipeLine: any = [];
  selectMenuItem(data) {
    for (const element of this.priceRevisionHistory) {
      this.menuItemPreviousPrice = element.filter(el => el.competitorPluId === data.competitorPluId && el.competitorRestaurantId === data.competitorRestaurantId);
      if (this.menuItemPreviousPrice.length > 0) {
        let restaurant: any = {};
        this.menuItemPreviousPrice.forEach(previous => {
          restaurant = this.restaurants.filter(x => x.id == previous.competitorRestaurantId)[0];
          previous['competitorName'] = restaurant?.competitorName;
          previous['restaurantNum'] = restaurant?.num;
        });
        break;
      }
    }
  }


  OpenPriceHistoryModel(content, data) {
    this.modalService.open(content, { centered: true });

  }
  onPaste(e) {
    e.preventDefault();
    return false;
  }

  CancelEdit() {
    this.ItemsToUpdate = [[], [], [], [], []];
    if (this.copyTableData) {
      this.tableData = JSON.parse(JSON.stringify(this.copyTableData));
    }
    this.editMode = [false, false, false, false, false];
  }

  refreshPricingAlerts() {
    var searchTerm = `?Filters=userId==${this.userId}`;
    this.restaurantService.GetUserSettings(searchTerm).subscribe(data => {
      if (data && (data as any).length > 0) {
        this.existingSettingsDoc = data[0];
        this.existingUserSettingId = data[0].id;
        if (data[0].settingsDocument && data[0].settingsDocument['pricing_alerts']) {
          this.pricingAlerts = data[0].settingsDocument['pricing_alerts'];
        }
      }
    });
  }
  onPressDeleteKey(e: any, data, i, j) {
    e.preventDefault();
    const inputField = document.getElementById('PriceInput' + i + j) as HTMLInputElement;
    const currentValue = inputField.value;
    const newValue = currentValue.substring(0, inputField.value.length - 1);
    inputField.value = newValue;
    this.updatePrice(data, inputField.value, i, j, "delPress");
  }
}
