import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-cards',
  templateUrl: './sales-cards.component.html',
  styleUrls: ['./sales-cards.component.scss']
})
export class SalesCardsComponent implements OnInit {

  @Input()
  title:string;
  @Input()
  overallSales:number;
  @Input()
  overallUnits:number;
  @Input()
  overallGP:number;
  @Input()
  actualUnits:number;
  @Input()
  actualSales:number;
  @Input()
  actualGp:number;
  @Input()
  showCard:boolean;
  @Input()
  isModelling:boolean;
  @Input()
  showImpact:boolean;
  @Input()
  isLoading:boolean;

  @Input()
  unitsDiff:number;
  @Input()
  salesDiff:number;
  @Input()
  gpDiff:number;

  isNaN: Function = Number.isNaN;
  constructor() { }

  ngOnInit(): void {
  }

}
