<table style="width:100%">
  <tr>
      <td *ngFor="let benchMark of benchMarkToShow; let i=index" id="bkBenchmarkShow{{i}}">
        <app-bk-benchmark [benchMark]="benchMark" [index]="i"></app-bk-benchmark>
      </td>
      <td *ngFor="let benchMark of benchMarkToHide; let i=index" id="bkBenchMarkHide{{i}}">
        <app-bk-benchmark [benchMark]="benchMark" [index]="i"></app-bk-benchmark>
      </td>
  </tr>
  <tr>
    <td colspan="100%">
      <div class="box">
        <div class="loading" *ngIf="isLoading" id="loadingSpinner">
          <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!isLoading">
          <div *ngIf="!tableData || tableData.length ===0" class="no-data d-flex justify-content-center align-items-center" id="noDataMsg">
            <p>Sorry no data exists for restaurant <strong>#{{selectedRestaurantNumber}}</strong> </p>
          </div>
        </div>
        <table *ngIf="!isLoading" class="menu-item-price">
            <tr *ngFor="let row of tableData; let i =index;">
              <td style="background-color: #fff; " class="menuItemName" id="menuItemName{{i}}">
                {{row[0].name}}
              </td>
              <td *ngFor="let data of row; let j = index" id="dataPrice{{i}}{{j}}" [ngClass]="{'shaded-cell':i%2===0, 'firstCell': (j===0 && ShowDma), 'fisrtCell-dma':(j===0 && !ShowDma)}">
                <span *ngIf="data.price != null && data.price != 0">$ {{data.price | number : '0.2-2'}} </span>
                <span *ngIf="data.price == null || data.price == 0">-- </span>
                <span *ngIf="data.price != null && data.price != 0" id="tipToolInfo{{i}}{{j}}"  class="pricetooltip"><i class="bi bi-info-circle" [ngbTooltip]="tipContent"></i>
                  <ng-template #tipContent>Collected {{data.priceExtractedDt + 'Z' | date: 'MM/dd/yy' }} at {{data.priceExtractedDt + 'Z' | date: 'hh:mm
                    aa'}}</ng-template>
                </span>
              </td>
              <td *ngFor="let data of [].constructor(availableBentchMarkCount-row.length)" [ngClass]="{'shaded-cell':i%2===0}">
                <span class="mx-2" style="color: #272E3B66;"> Menu Item <strong>$ 0.00</strong></span>
              </td>
            </tr>
        </table>
        </div>
    </td>
  </tr>
</table>