import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { data } from 'src/app/services/mock-data';
import * as dayjs from 'dayjs';
import { ConceptPluSalesService } from 'src/app/services/concept-plu-sales.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-impact-pricing-table',
  templateUrl: './impact-pricing-table.component.html',
  styleUrls: ['./impact-pricing-table.component.scss']
})
export class ImpactPricingTableComponent implements OnInit, OnChanges {
  @Input() restaurantNum;
  @Input() impactDate;
  @Input() impactModelSelected: any;
  lastWeekRange: string;
  @Input() menuItems: any[] = [];
  @Input() pluLength;

  @Input() dummyItems: any[] = [];
  orgMenuItems: any[] = [];
  @Output() dateRange = new EventEmitter<any>();
  @Input() impactPeriodValue: string;
  @Input() impactModelSelectedPeriod:string;
  @Input() selectedSubGroup: string;
  @Input() loadSpinner: boolean;
  @Input() timePeriodSelected: boolean;
  selectedModelPeriod:string;
  impactPeriod:string;
  constructor(private datePipe: DatePipe, private conceptPluService: ConceptPluSalesService) {

  }

  ngOnInit(): void {
    this.calculateLastWeekRange();
  }
  ngOnChanges(changes: SimpleChanges) {
    const today = dayjs();
    let diffValue: string;
    if (this.impactPeriodValue == "7 Days") {
      diffValue = "w"
    } else if (this.impactPeriodValue == "30 Days") {
      diffValue = "m"
    }
    if(changes['impactModelSelected']) {
      this.selectedModelPeriod =  changes['impactModelSelected'].currentValue;
    }
    if(changes['impactPeriodValue']) {
this.impactPeriod = changes['impactPeriodValue'].currentValue;
    }
    this.calculateLastWeekRange();
  }
  calculateLastWeekRange() {
    let dateStr = this.impactDate;
    if (this.impactDate instanceof Object) {
      dateStr = `${this.impactDate['year']}-${this.impactDate['month']}-${this.impactDate['day']}`;
    }
    const currentDate = dayjs(dateStr);
    const lastWeekStartDate = currentDate;
    if (this.impactPeriodValue == "b7da3bfa-8b70-4d65-8f00-092946331188") {
      const lastWeekEndDate = currentDate.add(7, 'day');
      const formattedStartDate = this.datePipe.transform(lastWeekStartDate.toDate(), 'MM/dd');
      const formattedEndDate = this.datePipe.transform(lastWeekEndDate.toDate(), 'MM/dd/yyyy');

      this.lastWeekRange = `${formattedStartDate} - ${formattedEndDate}`;
    } else if (this.impactPeriodValue == "9551f8cd-8918-4a92-8cbc-99dc2cf6e9eb") {
      const lastWeekEndDate = currentDate.add(30, 'day');
      const formattedStartDate = this.datePipe.transform(lastWeekStartDate.toDate(), 'MM/dd');
      const formattedEndDate = this.datePipe.transform(lastWeekEndDate.toDate(), 'MM/dd/yyyy');

      this.lastWeekRange = `${formattedStartDate} - ${formattedEndDate}`;

    } else {
      const lastWeekEndDate = currentDate.add(90, 'day');
      const formattedStartDate = this.datePipe.transform(lastWeekStartDate.toDate(), 'MM/dd');
      const formattedEndDate = this.datePipe.transform(lastWeekEndDate.toDate(), 'MM/dd/yyyy');

      this.lastWeekRange = `${formattedStartDate} - ${formattedEndDate}`;

    }
    this.dateRange.emit(this.lastWeekRange);
  }

  getImpactDate() {
    let dateStr=this.impactDate;
    if(this.impactDate instanceof Object) {
      dateStr = `${this.impactDate['year']}-${this.impactDate['month']}-${this.impactDate['day']}`;
    }
    return dateStr;
  }
 
  getProrate(val:any) {
    const rate = (val) * this.pluLength;
    return rate;
  }
}
