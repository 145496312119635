import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class TokenService {
    private rsiWeRoot: string = `${environment.rsiWebUrl}/jwtrefresh.asmx/Refresh`;
    constructor(private http: HttpClient) { }

    refreshAuthToken(token:string) {
      const url = `${this.rsiWeRoot}`;
        let result = this.http.get(url);
        return result;
    }
  }