<div class="slide-container">
    <!-- Display current slide content -->
    <div *ngIf="TierService.getSetupPage() === 0">
        <div class="slide-content">
            <h3>Welcome to Pricing Group Validation</h3>
        </div>
    </div>
    <div>{{ slides[TierService.getSetupPage()] }}<a href="/assets/upload-instructions.pdf" target="_blank">Click here for upload instructions</a></div>
    
</div>

<div class="action-container">
     <!--- <div *ngIf="TierService.getSetupPage() === 0">
        <button class="downloadXl" [class]="checkUnassignedEmpty() ? 'btn-blue' : 'btn-grey'" (click)="checkUnassignedEmpty() && nextSlide()" [disabled]="!checkUnassignedEmpty()">
            <i class="bi bi-forward-fill"></i>Continue
        </button>
    </div>
    <div *ngIf="TierService.getSetupPage() >= 1">
        <button class="downloadXl" (click)="nextSlide()">
            <i class="bi bi-forward-fill"></i>Save Config
        </button>
    </div> --->
</div>