import { Component, Input, OnInit } from '@angular/core';
import { usStates } from 'src/app/services/us-states.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-competitor-card',
  templateUrl: './competitor-card.component.html',
  styleUrls: ['./competitor-card.component.scss']
})
export class CompetitorCardComponent implements OnInit {

  @Input()
  competitor: any;
  assetsUrl: string;
  isTestEnv = false;
  constructor() { }

  ngOnInit(): void {
    this.assetsUrl = environment.assetUrl;
    // if (this.competitor && this.competitor?.rating == undefined || this.competitor?.rating == null) {
    //   this.competitor.rating = null;
    // }
    // if (this.competitor?.trafficCount == undefined || this.competitor?.trafficCount == null) {
    //   this.competitor.trafficCount = 'N/A'
    // }
    // if (this.competitor?.houseHoldIncome == undefined || this.competitor?.houseHoldIncome == null) {
    //   this.competitor.houseHoldIncome = 'N/A'
    // }
    if (environment.isTestEnvironment) {
      this.isTestEnv = true;
    }
  }

  getIcon(brand: string) {
    if (brand == undefined || brand == null) {
      return this.assetsUrl + "/assets/BK Logo@2x.png";
    }
    if (brand == 'McDonalds') {
      return this.assetsUrl + "/assets/MCD Logo@2x.png";
    }
    if (brand == 'Jack In A Box') {
      return this.assetsUrl + "/assets/JackInTheBox Logo@2x.png";
    }
    if (brand == 'Wendys') {
      return this.assetsUrl + "/assets/Wendys Logo@2x.png";
    }
    if (brand == 'Sonic') {
      return this.assetsUrl + "/assets/sonic-logo.png"
    }
    if (brand == 'Whataburger') {
      return this.assetsUrl + "/assets/Whataburger_logo.png"
    }
    if (brand == 'Taco Bell') {
      return this.assetsUrl + "/assets/taco-bell.png"
    }
    if (brand == 'In N Out') {
      return this.assetsUrl + "/assets/In-N-Out-Burger.webp"
    }
    if (brand == 'Hardees') {
      return this.assetsUrl + "/assets/hardees.webp"
    }
    if (brand == 'Carl\'s Jr.') {
      return this.assetsUrl + "/assets/carlsjr_logo.png"
    }
    return "";
  }
  removeComma(num:string){
    return num = num.replace(/\,/g,'');
  }
}
