<mat-tree id="cat-tree" [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node id="cat-tree-node" *matTreeNodeDef="let node" style="overflow: visible;" [ngClass]="{'active':node.level==1?node.items.subgroupName===selectedCategory:node.items.name===selectedCategory}" [matTreeNodePadding]="0">
        <button id="child-node-btn" [ngStyle]="node.items.name=='All' ? {'padding-left': '18px'}: {'padding-left': '0px'}" mat-icon-button matTreeNodeToggle disableRipple tooltip="node.name" (click)="selectCategory(node)">
          {{(node.items.subgroupName==null?node.name :node.items.subgroupName)| slice:0: 26}}
          <span *ngIf="(node.items.subgroupName==null?node.name :node.items.subgroupName)?.length > 27" placement="top" [ngbTooltip]="node.items.subgroupName" #priceTooltip="ngbTooltip">...</span>
        </button>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node;when: hasChild" >
        <button mat-icon-button disableRipple matTreeNodeToggle (click)=nodeClicked(node) [disabled]="activeId !=1 && node.name=='Upcharges'" 
                [attr.aria-label]="'toggle ' + node.name">
        <i [ngClass]="{'bi bi-caret-down-fill':treeControl.isExpanded(node), 'bi bi-caret-right-fill':!treeControl.isExpanded(node)}"></i>
          {{node.name }}
        </button>
      </mat-tree-node>
    </mat-tree>

    <app-save-model-modellingtool [modalPriceToSave]="modalPriceToSave" [selectedModelingRes]="selectedModelingRes"></app-save-model-modellingtool>

    <ng-template #notSavedModel let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Warning</h4>
        <i class="bi bi-x-circle delete-cross" (click)="changeWithoutSave()"></i>
    </div>
      <div class="modal-body">
          <div class="inner-body">
            <p class="error-message">
              Please save the model before continuing, otherwise changes will be lost
            </p>
          </div>
      </div>
      <div class="footerBtn mb-3">
        <button class="btn set-btn mx-3" (click)="openSaveModel()"><i
          class="bi bi-bookmark"></i> Save Models</button>
     </div>
  
    </ng-template>