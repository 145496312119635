import { Component } from '@angular/core';
import { TierService } from 'src/app/services/tier.service';

@Component({
  selector: 'app-tier-list',
  templateUrl: './tier-list.component.html',
  styleUrls: ['./tier-list.component.css']
})

//import function needed for restuarant IDs
  export class TierListComponent {

    TierService: TierService;

    constructor(TierService: TierService) {
      this.TierService = TierService;
    }

    public ngOnInit(): void {
    this.TierService.restaurants = [];
    this.TierService.tiers = [];
    this.TierService.getRestaurantsForUser().subscribe((res: any[]) => {
      res.forEach(item => {
        if (item.restaurantNum !== 0) {
          // Add to restaurants if ruNo is not 0
          this.TierService.restaurants.push({
            selected: false,
            parentRuNum: item.parentRuNum,
            ruNum: item.ruNo,
            groupId: item.priceGroupId,
            groupName: item.priceGroupName,
            restaurantNum: item.restaurantNum,
            conceptId: item.conceptId,
          });
        }
        else {
          this.TierService.tiers.push({
            tierId: item.priceGroupId,
            name: item.priceGroupName,
          })
          this.TierService.sortTiersByName();
        }})})

      }

    getTierNumbers(tierId: string) {
      return this.TierService.getTierNumbers(tierId);
    }

    getUnassignedGroupId() {
      return this.TierService.getUnassignedGroupId();
    }

    getUnassingedTierNumbers() {
      return this.TierService.getUnassingedTierNumbers();
    }

    toggleSelection(ru: number) {
      this.TierService.toggleSelection(ru);
    }
    
    assignToTier(tierId: string) {
      this.TierService.assignToTier(tierId);
    }

    getTierIDs() {
      return this.TierService.getTierIDs();
    }

    getTierName(tierID: string) {
      return this.TierService.getTierName(tierID);
    }

    getRestaurants() {
      return this.TierService.getRestaurants();
    }

    getUnassignedRestaurants() {
      return this.TierService.getUnassignedRestaurants();
    }

    getTiers() {
      return this.TierService.getTiers();
    }

    addTier() {
      this.TierService.addTier();
    }

    deleteTier(tierId: string) {
      this.TierService.deleteTier(tierId);
    }
  }

