import { Component } from '@angular/core';
import { TierService } from 'src/app/services/tier.service';

interface TierItem {
  name: string;
  tierId: string;
}

@Component({
  selector: 'app-rule-list',
  templateUrl: './rule-list.component.html',
  styleUrls: ['./rule-list.component.css']
})

//import function needed for restuarant IDs
  export class RuleListComponent {
    tiers: TierItem[]
    //selectedTier: TierItem;
    
    constructor(private tierService: TierService) {
      this.tiers = this.tierService.getTiers();
    }

    public ngOnInit(): void {
      this.tierService.selectTier(this.tiers[0].tierId);
    }

    selectTier(tierId: string) {
      this.tierService.selectTier(tierId);
    }

    getSelectedTier() {
      return this.tierService.getSelectedTier();
    }

    onPriceChange(event: any, item: any) {
      const value = event.target.value.replace(/^\$/, ''); // Remove leading $
      item.price = parseFloat(value);
    }

    focusNextInput(currentIndex: number): void {
      const nextIndex = currentIndex + 1;
      // Check if the next index is within the bounds of the comboUpcharges array
      if (nextIndex < this.comboUpcharges.length) {
        const nextItem = this.comboUpcharges[nextIndex];
        // Automatically insert '$' into the next input if it's empty or not already set
        if (!nextItem.price || nextItem.price.trim() === '') {
          nextItem.price = '$';
        }
    
        // Focus the next input element
        setTimeout(() => {
          const nextInputId = `price-input-${nextIndex}`;
          const nextInputElement: HTMLElement | null = document.getElementById(nextInputId);
          if (nextInputElement) {
            nextInputElement.focus(); // Focus the next input element
          }
        });
      }
    }
    
      comboUpcharges = [
        { description: 'Small Breakfast Combo Upcharge', price: null },
        { description: 'Medium Breakfast Combo Upcharge', price: null },
        { description: 'Large Breakfast Combo Upcharge', price: null },
        { description: 'Small Lunch Combo Upcharge', price: null },
        { description: 'Medium Lunch Combo Upcharge', price: null },
        { description: 'Large Lunch Combo Upcharge', price: null },
      ];    
  }