<div class="tier-container">
  <div class="tiers">
    <h3>Groups</h3>
    <ul>
      <li *ngFor="let tier of tiers">
        <button
          type="button"
          class="button"
          [class.selected]="getSelectedTier().tierId === tier.tierId"
          (click)="selectTier(tier.tierId)">
          {{ tier.name }}
        </button>
      </li>
    </ul>
  </div>
    <div class="pricing-info">
      <div class="upcharge-option">
        <input type="radio" id="comboUpcharge" name="comboOption" checked>
        <label for="comboUpcharge">Combo Upcharge (price of base + flat fee)</label>
      </div>
      <div class="upcharge-option">
        <input type="radio" id="comboDiscount" name="comboOption">
        <label for="comboDiscount">Combo Discount (price of base + price of side & drink - flat discount)</label>
      </div>
        <div *ngFor="let item of comboUpcharges; let i = index" class="upcharge-item">
          <div class="input-group">
            <input type="text" 
                  [(ngModel)]="item.price" 
                  (click)="item.price = item.price || '$'" 
                  (blur)="item.price = (!item.price || item.price === '$') ? null : item.price"
                  (keyup.enter)="focusNextInput(i)"
                  autocomplete="off"
                  [id]="'price-input-' + i"
                  class="price-input"/>
          </div>
          <div class="description">{{ item.description }}</div>
      </div>
    </div>
  </div>