<div class="card shadow mb-5 bg-white rounded" style="padding:10px;width:100%" >
    <!-- [style.width]="index!=0 ? '250px' :null" -->
    <div class="card-title">
        <div class="row">
            <div class="col-1">
                <img class="comp-img" src="{{assetUrl}}/assets/BK Logo@2x.png"/>
            </div>
            <div class="col ml-1 comp-info-col d-flex align-items-center">
                <strong *ngIf="index==0 && benchMark?.toShow==true" style="padding-left:10px; text-align: left;">{{benchMark.addr1}} {{benchMark.cityName}}, {{benchMark.zipCd}}</strong>
                <strong *ngIf="(index!=0 || benchMark?.toShow==false) &&  benchMark?.title != 'BKC Division'" style="padding-left:10px">{{benchMark?.title}}<span *ngIf="benchMark?.title!='National' && benchMark?.title != 'BKC Division'"> - {{benchMark?.showName}}</span></strong>
                <strong *ngIf="(index!=0 || benchMark?.toShow==false) &&  benchMark?.title == 'BKC Division'" style="padding-left:10px">{{benchMark?.title}} - {{benchMark?.bkcDivisionName}}</strong>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div *ngIf="index==0 && benchMark?.toShow==true">
            <div class="stat d-flex justify-content-between"><span class="scores">ACR Score<i class="bi bi-info-circle" style="width:10px; height:10px;padding-left: 2px;" [ngbTooltip]="acrDt"></i></span> <strong><span *ngIf="benchMark.acrScore!=null  && benchMark.acrScore !=0">{{benchMark?.acrScore | number : '0.0-1'}}</span><span *ngIf="benchMark.acrScore==null || benchMark.acrScore ==0">--</span></strong></div>
            <div class="stat d-flex justify-content-between"><span class="scores">FSS Score<i class="bi bi-info-circle" style="width:10px; height:10px;padding-left: 2px;" [ngbTooltip]="fssDt"></i></span> <strong><span *ngIf="benchMark.fssScore!=null && benchMark.fssScore != 0">{{benchMark?.fssScore | number : '0.0-1'}}</span><span *ngIf="benchMark.fssScore==null ||  benchMark.fssScore ==0">--</span></strong></div>
            <div class="stat d-flex justify-content-between"><span class="scores">Household Income</span> <strong><span *ngIf="benchMark?.medianHhIncome!=null && benchMark?.medianHhIncome !=0">$ {{benchMark?.medianHhIncome | number : '0.0-0'  }}</span><span *ngIf="benchMark?.medianHhIncome==null || benchMark?.medianHhIncome ==0">--</span></strong></div>
            <div class="stat d-flex justify-content-between"><span class="scores">Sales</span> <strong><span *ngIf="benchMark?.salesAmt!=null && benchMark?.salesAmt !=0">$ {{benchMark.salesAmt | number : '0.0-0'  }}</span><span *ngIf="benchMark?.salesAmt==null || benchMark?.salesAmt ==0">--</span></strong></div>
        </div>
        <div id="benchmark-card-body" *ngIf="index!=0 && benchMark?.toShow==true">
        <div id="acr-score" class="stat d-flex justify-content-between"><span class="scores">ACR Score<i class="bi bi-info-circle" [ngbTooltip]="acrDt" style="width:10px; height:10px;padding-left: 2px;"></i></span>
             <strong><span *ngIf="benchMark.averageAcrScore!=null  && benchMark.averageAcrScore !=0">{{benchMark.averageAcrScore | number : '0.0-1'}}</span><span *ngIf="benchMark?.averageAcrScore==null || benchMark?.averageAcrScore ==0">--</span></strong></div>
            <div class="stat d-flex justify-content-between"><span class="scores">FSS Score 
                <i class="bi bi-info-circle" [ngbTooltip]="fssDt"  *ngIf="benchMark.averageFssScore!=null && benchMark.averageFssScore != 0" style="width:10px; height:10px;"></i>
            </span> 
                <strong><span *ngIf="benchMark.averageFssScore!=null && benchMark.averageFssScore != 0">{{benchMark.averageFssScore | number : '0.0-1'}}</span><span *ngIf="benchMark.averageFssScore==null ||  benchMark.averageFssScore ==0">--</span></strong></div>
            <div class="stat d-flex justify-content-between"><span class="scores">Household Income</span> <strong><span *ngIf="benchMark?.averageMedianHhIncome!=null && benchMark?.averageMedianHhIncome !=0">$ {{benchMark?.averageMedianHhIncome | number : '0.0-0'  }}</span><span *ngIf="benchMark?.averageMedianHhIncome==null || benchMark?.averageMedianHhIncome ==0">--</span></strong></div>
            <div class="stat d-flex justify-content-between"><span class="scores">Average Sales</span> <strong><span *ngIf="benchMark.averageSalesAmt!=null && benchMark.averageSalesAmt !=0">$ {{benchMark.averageSalesAmt | number : '0.0-0'  }}</span><span *ngIf="benchMark.averageSalesAmt==null || benchMark.averageSalesAmt ==0">--</span></strong></div>
        </div>
        <div *ngIf="benchMark?.toShow == false" class="d-flex justify-content-center align-items-center">
            <img class="placeholder-img" src="{{assetUrl}}/assets/competitor_placeholder.png"/>
        </div>        
    </div>
</div>

<ng-template #acrDt>

    <span *ngIf="index!=0 && (benchMark?.averageAcrScoreDt!=null || benchMark?.averageAcrScoreDt != undefined)">Collected  {{benchMark.averageAcrScoreDt | date: 'MM/dd/yy'  }} at {{benchMark.averageAcrScoreDt | date: 'hh:mm aa'}}</span>
    <span *ngIf="index==0 && (benchMark?.acrScoreDt!=null || benchMark?.acrScoreDt != undefined)">Collected  {{benchMark.acrScoreDt | date: 'MM/dd/yy' }} at {{benchMark.acrScoreDt | date: 'hh:mm aa'}}</span>
    <span *ngIf="index!=0 && (benchMark?.averageAcrScoreDt==null || benchMark?.averageAcrScoreDt == undefined)">No Data</span>
    <span *ngIf="index==0 && (benchMark?.acrScoreDt==null || benchMark?.acrScoreDt == undefined)">No Data</span>
</ng-template>
<ng-template #fssDt>
    <span *ngIf="index != 0 && (benchMark?.averageFssScoreDt!=null || benchMark?.averageFssScoreDt != undefined)">Collected  {{benchMark.averageFssScoreDt | date: 'MM/dd/yy' }} at {{benchMark.averageFssScoreDt | date: 'hh:mm aa'}}</span>
    <span *ngIf="index == 0 && (benchMark?.fssScoreDt !=null || benchMark?.fssScoreDt != undefined)">Collected  {{benchMark.fssScoreDt | date: 'MM/dd/yy' }} at {{benchMark.fssScoreDt | date: 'hh:mm aa'}}</span>
    <span *ngIf="index != 0 && (benchMark?.averageFssScoreDt==undefined || benchMark?.averageFssScoreDt==null)">No Data</span>
    <span *ngIf="index == 0 && (benchMark?.fssScoreDt==undefined || benchMark?.fssScoreDt==null)">No Data</span>
</ng-template>
