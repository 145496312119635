<!-- Google tag (gtag.js) -->
<script
  async
  src="https://www.googletagmanager.com/gtag/js?id=G-BR2FFNBNTX"
></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "G-BR2FFNBNTX");
</script>
<app-click-thru-modal [userId]="userId"></app-click-thru-modal>
<div class="container-fluid">
  <br />
  <div class="row">
    <div class="col-2" [ngClass]="{ hide: !showMenuTab }">
      <div class="mt-5">
        <h3>Menu</h3>
        <div>
          <app-side-menu-category
            *ngIf="isLoaded"
            [selectedCategory]="selectedSubGroup"
            [selectedModelingRes]="selectedModelingRes"
            [modalPriceToSave]="modalPriceToSave"
            (onChangeCategory)="changeCategory($event)"
            [activeId]="activeId"
          ></app-side-menu-category>
        </div>
      </div>
    </div>
    <div class="col-2" [ngClass]="{ hide: showMenuTab }">
      <div class="mt-5">
        <h3></h3>
      </div>
    </div>
    <div class="col-10">
      <div class="row me-2">
        <div class="col">
          <div class="fixed-box">
            <div class="d-flex justify-content-end text-center"></div>
            <div class="row">
              <div
                class="legendStyle d-flex justify-content-center"
                style="background-color: #e9effa; border-radius: 25px"
              >
                <i
                  class="bi bi-exclamation-triangle-fill"
                  style="color: #8b96a9"
                ></i
                >&nbsp;
                <span style="color: #8b96a9"
                  >Market Intelligence data is refreshed on a monthly basis and
                  posted by the 20th business day of each month</span
                >
              </div>
            </div>
            <div class="row">
              <ul
                class="col-5"
                ngbNav
                #nav="ngbNav"
                style="margin-top: 20px"
                [(activeId)]="activeId"
              >
                <li [ngbNavItem]="1" class="nav-tab">
                  <!-- Market Intelligence Tab -->
                  <a
                    [ngClass]="{ active: activeId == 1 }"
                    (click)="changeHeaderTab(1)"
                    (click)="changeMenuTab(1)"
                    class="nav-link tab-label"
                    >Market Intelligence</a
                  >
                  <script>
                    gtag("event", "Market Tab View", {});
                  </script>
                  <ng-template ngbNavContent>
                    <div class="row tab-content">
                      <div class="col">
                        <div class="">
                          <mat-label class="competitors-label"
                            >Restaurant Group</mat-label
                          >
                          <mat-select
                            #group
                            [(value)]="selectedRestaurantGroup"
                            [disableOptionCentering]="true"
                            (valueChange)="refreshRestaurantNum(group.value)"
                          >
                            <input
                              type="text"
                              class="search"
                              placeholder="Search"
                              (blur)="clearSearchText()"
                              aria-label="Number"
                              [(ngModel)]="groupSearchText"
                            />
                            <mat-option
                              *ngFor="
                                let val of availableRestaurantGroup
                                  | groupFilter : groupSearchText
                              "
                              [value]="val.groupName"
                            >
                              <div
                                [innerHTML]="getGroupSearchText(val.groupName)"
                              ></div>
                            </mat-option>
                          </mat-select>
                        </div>
                      </div>
                      <div class="col">
                        <div class="">
                          <mat-label class="competitors-label"
                            >Restaurant #</mat-label
                          >

                          <mat-select
                            #competitor
                            [(value)]="selectedRestaurantNumber"
                            [disableOptionCentering]="true"
                            id="restnum"
                            panelClass="resdropdown"
                            (valueChange)="RefreshCompetitors(competitor.value)"
                          >
                            <input
                              type="text"
                              class="search"
                              placeholder="Search"
                              (blur)="clearSearchText()"
                              aria-label="Number"
                              [(ngModel)]="searchText"
                            />
                            <mat-option
                              *ngFor="
                                let val of availableRestaurant
                                  | restaurantFilter : searchText
                              "
                              [value]="val.restaurantNum"
                            >
                              <div
                                [innerHTML]="
                                  getSearchText(
                                    (
                                      val.restaurantNum | number : '7.0-0'
                                    ).replace(',', '')
                                  )
                                "
                              ></div>
                              <!-- <span>   {{(val.restaurantNum | number: '6.0-0').replace(',','')}}</span> -->
                            </mat-option>
                          </mat-select>
                        </div>
                      </div>
                      <div class="col">
                        <div class="d-flex justify-content-center">
                          <mat-label class="competitors-label sold-type-lable"
                            >Competitors</mat-label
                          >
                          <i
                            style="font-size: 10px; padding-left: 5px"
                            matTooltip="Select up to 4"
                            matTooltipPosition="above"
                            class="bi bi-info-circle"
                          ></i>
                        </div>
                        <div class="d-flex justify-content-center">
                          <mat-slide-toggle
                            [disableRipple]="true"
                            [checked]="checkedCompetitors"
                            (change)="onToggleCompetitors($event)"
                          >
                          </mat-slide-toggle>
                        </div>
                      </div>
                      <div class="col-2">
                        <div
                          class="d-flex align-items-center sold-type-container"
                          *ngFor="let el of availableSoldTypes"
                        >
                          <input
                            type="checkbox"
                            [checked]="el.isSelected"
                            [(ngModel)]="el.isSelected"
                            (change)="changeSoldType()"
                          /><span class="competitors-label sold-type-lable">{{
                            el.name
                          }}</span>
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="row" *ngIf="checkedCompetitors">
                          <div class="col-4">
                            <mat-label class="competitors-label"
                              >McDonald's</mat-label
                            >
                            <mat-select
                              placeholder="Select"
                              [multiple]="true"
                              [disableOptionCentering]="true"
                              [(value)]="selectedMCD"
                              (valueChange)="changeCompititors()"
                            >
                              <mat-select-trigger>
                                <span>Selected {{ selectedMCD.length }}</span>
                              </mat-select-trigger>
                              <mat-option
                                class="options"
                                [disabled]="
                                  selectedCompetitors.length >= 4 && check(el)
                                "
                                *ngFor="let el of nearMcdCompetitors"
                                [value]="el"
                              >
                                <div
                                  class="d-flex justify-content-start cmp-dropdown"
                                >
                                  <span class="distance"
                                    >{{
                                      el.distance | number : "1.1-1"
                                    }}
                                    Miles </span
                                  >&nbsp;
                                  <span class=""
                                    >{{ el.addr1 }}, {{ el.cityName }},
                                    {{ el.stateCd }}, {{ el.zipCd }}</span
                                  >
                                </div>
                              </mat-option>
                            </mat-select>
                          </div>
                          <div class="col-4">
                            <mat-label class="competitors-label"
                              >Wendy's</mat-label
                            >
                            <mat-select
                              placeholder="Select"
                              [multiple]="true"
                              [disableOptionCentering]="true"
                              [(value)]="selectedWendy"
                              (valueChange)="changeCompititors()"
                            >
                              <mat-select-trigger>
                                <span>Selected {{ selectedWendy.length }}</span>
                              </mat-select-trigger>
                              <mat-option
                                style="font-size: 15px"
                                [disabled]="
                                  selectedCompetitors.length >= 4 && check(el)
                                "
                                *ngFor="let el of nearWendyCompetitors"
                                [value]="el"
                              >
                                <div
                                  class="d-flex justify-content-start cmp-dropdown"
                                >
                                  <span class="distance"
                                    >{{
                                      el.distance | number : "1.1-1"
                                    }}
                                    Miles </span
                                  >&nbsp;
                                  <span class="">
                                    {{ el.addr1 }}, {{ el.cityName }},
                                    {{ el.stateCd }}, {{ el.zipCd }}
                                  </span>
                                </div>
                              </mat-option>
                            </mat-select>
                          </div>
                          <div class="col-4">
                            <mat-label class="competitors-label"
                              >Others</mat-label
                            >
                            <mat-select
                              placeholder="Select"
                              [multiple]="true"
                              [disableOptionCentering]="true"
                              [(value)]="selectedOthers"
                              (valueChange)="changeCompititors()"
                            >
                              <mat-select-trigger>
                                <span
                                  >Selected {{ selectedOthers.length }}</span
                                >
                              </mat-select-trigger>
                              <mat-optgroup
                                style="font-weight: bold"
                                *ngFor="let group of nearOtherCompetitors"
                                [label]="group[0].competitorName"
                              >
                                <img
                                  class="others-img"
                                  [src]="getIcon(group[0].competitorName)"
                                />
                                <mat-option
                                  style="font-size: 15px"
                                  *ngFor="let el of group"
                                  [value]="el"
                                  [disabled]="
                                    selectedCompetitors.length >= 4 && check(el)
                                  "
                                >
                                  <div
                                    class="d-flex justify-content-start cmp-dropdown"
                                  >
                                    <span class="distance"
                                      >{{
                                        el.distance | number : "1.1-1"
                                      }}
                                      Miles </span
                                    >&nbsp;
                                    <span class="">
                                      {{ el.addr1 }}, {{ el.cityName }},
                                      {{ el.stateCd }}, {{ el.zipCd }}</span
                                    >
                                  </div>
                                </mat-option>
                              </mat-optgroup>
                            </mat-select>
                          </div>
                        </div>
                        <div class="row" *ngIf="!checkedCompetitors">
                          <div class="col-10">
                            <div class="row cmp-pad">
                              <div
                                class="col-xl-3 col-xxl-2 d-flex align-items-center me-2"
                                *ngFor="
                                  let mark of availableBenchMark;
                                  let i = index
                                "
                              >
                                <mat-checkbox
                                  [disableRipple]="true"
                                  *ngIf="
                                    (mark.name == 'DMA' &&
                                      currentConceptRestaurant?.dmaShow == 1) ||
                                    mark.name != 'DMA'
                                  "
                                  type="checkbox"
                                  (change)="ChangeBenchMarkSelection()"
                                  [(ngModel)]="mark.isShow"
                                  ><span
                                    class="bench-label"
                                    *ngIf="
                                      (mark.name == 'DMA' &&
                                        currentConceptRestaurant?.dmaShow ==
                                          1) ||
                                      mark.name != 'DMA'
                                    "
                                    >{{ mark.name }}</span
                                  ></mat-checkbox
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li
                  [ngbNavItem]="2"
                  class="nav-tab"
                  [ngClass]="{ hide: !showModelingTab }"
                >
                  <script>
                    gtag("event", "Model Tab View", {});
                  </script>
                  <a
                    [ngClass]="{ active: activeId == 2 }"
                    (click)="changeTab()"
                    (click)="changeMenuTab(1)"
                    class="nav-link tab-label"
                    >Modeling Tool</a
                  >
                  <ng-template ngbNavContent>
                    <div class="row tab-content modeling-tab">
                      <div class="col-5 row" style="margin-bottom: 1rem">
                        <div class="col-5">
                          <mat-label class="competitors-label"
                            >Restaurant Group</mat-label
                          >
                          <mat-select
                            #group
                            [(value)]="selectedRestaurantGroup"
                            [disableOptionCentering]="true"
                            (valueChange)="
                              refreshRestaurantNumModeling(group.value)
                            "
                          >
                            <input
                              type="text"
                              class="search"
                              placeholder="Search"
                              (blur)="clearSearchText()"
                              aria-label="Number"
                              [(ngModel)]="groupSearchText"
                            />
                            <mat-option
                              *ngFor="
                                let val of availableRestaurantGroup
                                  | groupFilter : groupSearchText
                              "
                              [value]="val.groupName"
                            >
                              <div
                                [innerHTML]="getGroupSearchText(val.groupName)"
                              ></div>
                            </mat-option>
                          </mat-select>
                        </div>
                        <div class="col-5">
                          <mat-label class="competitors-label"
                            >Restaurant #</mat-label
                          >

                          <mat-select
                            #competitor
                            [(value)]="selectedRestaurantNumber"
                            [disableOptionCentering]="true"
                            id="restnum"
                            (valueChange)="
                              RefreshCompetitorsModeling(competitor.value)
                            "
                          >
                            <input
                              type="text"
                              class="search"
                              placeholder="Search"
                              (blur)="clearSearchText()"
                              aria-label="Number"
                              [(ngModel)]="searchText"
                            />
                            <mat-option
                              *ngFor="
                                let val of availableRestaurant
                                  | restaurantFilter : searchText
                              "
                              [value]="val.restaurantNum"
                            >
                              <div
                                [innerHTML]="
                                  getSearchText(
                                    val.restaurantNum | number : '7.0-0'
                                  )
                                "
                              ></div>
                            </mat-option>
                          </mat-select>
                        </div>
                        <!-- <app-restaurant-selector [preSelectedRestaurantGroup]="selectedRestaurantGroup" [preSelectedRestaurantNumber]="selectedRestaurantNumber" [changeRestaurant]="changeRestaurant"
                                                    (OnRestaurantNum)="ApplyChangeRestaurantNum($event)" (OnRestaurantGroup)="ApplyChangeRestaurantsGroup($event)" ></app-restaurant-selector> -->
                        <div class="row">
                          <div class="col-5">
                            <mat-label class="competitors-label"
                              >AUV Date Range</mat-label
                            >
                            <mat-select
                              [disableOptionCentering]="true"
                              [(value)]="selectedModellingDateRange"
                            >
                              <mat-option
                                *ngFor="let val of modelingDateRange"
                                [value]="val?.name"
                                >{{ val?.name }}
                              </mat-option>
                            </mat-select>
                          </div>
                          <div class="col-5 cmp-pad">
                            <mat-slide-toggle
                              [disableRipple]="true"
                              [checked]="checkedModelCompetitors"
                              [(ngModel)]="checkedModelCompetitors"
                            >
                              <span class="comp-toggle">Competitors </span>
                            </mat-slide-toggle>
                          </div>
                        </div>
                      </div>
                      <div class="col" style="margin-left: -30px">
                        <div
                          class="d-flex align-items-center sold-type-container"
                          *ngFor="let el of availableSoldTypes"
                        >
                          <input
                            type="checkbox"
                            [checked]="el.isSelected"
                            [(ngModel)]="el.isSelected"
                            (change)="changeSoldType()"
                          /><span class="competitors-label sold-type-lable">{{
                            el.name
                          }}</span>
                        </div>
                      </div>
                      <div class="col-6" style="margin-bottom: 65px">
                        <app-competitor-dropdown
                          [nearMcdCompetitors]="nearMcdCompetitors"
                          [nearWendyCompetitors]="nearWendyCompetitors"
                          [selectedMCD]="selectedMCDL"
                          [selectedWendy]="selectedWendyL"
                          [selectedOthers]="selectedOthersL"
                          [nearOtherCompetitors]="selectedOthers"
                          [restaurantNum]="selectedRestaurantNumber"
                          (RestaurantCompititors)="
                            restaurantCompititorsChange($event)
                          "
                        >
                        </app-competitor-dropdown>
                      </div>
                    </div>
                    <div class="row mt-4" *ngIf="showCard">
                      <div class="col-2"></div>
                      <div class="col-2"></div>
                      <div
                        class="col-4 modedlingSales"
                        *ngFor="let card of salesCardDetails"
                      >
                        <app-sales-cards
                          [title]="card.title"
                          [overallSales]="card.overallSales"
                          [overallUnits]="card.overallUnits"
                          [overallGP]="card.overallGP"
                          [showCard]="showCard"
                          [isModelling]="true"
                          [actualUnits]="card?.actualUnits"
                          [actualSales]="card?.actualSales"
                          [actualGp]="card?.actualGp"
                          [unitsDiff]="card.unitsDiff"
                          [salesDiff]="card.salesDiff"
                          [gpDiff]="card.gpDiff"
                          [showImpact]="false"
                        ></app-sales-cards>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li
                  [ngbNavItem]="4"
                  class="nav-tab"
                  [ngClass]="{ hide: !showTierTab }"
                >
                  <a
                    [ngClass]="{ active: activeId == 4 }"
                    (click)="changeHeaderTab(4)"
                    (click)="showMenuTab = false"
                    class="nav-link tab-label"
                    >Pricing Groups</a
                  >
                  <!-- Tier Pricing Tab --->
                  <ng-template ngbNavContent>
                    <div class="row tab-content modeling-tab">
                      <app-slide-show></app-slide-show>
                      <div *ngIf="getSetupPage() == 0">
                        <app-contact-form></app-contact-form>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <div
                class="col-7"
                *ngIf="activeId === 2"
                style="text-align: right"
              >
                <div class="SaveBtn-container">
                  <button
                    class="downloadXl"
                    (click)="downloadRBIXlsx()"
                    [disabled]="downloadRBIXlsxBtn"
                  >
                    <i
                      class="bi bi-cloud-download"
                      [class.displayNon]="downloadRBIXlsxBtn"
                    ></i>
                    <div
                      class="spinner-border"
                      role="spinnerIcon"
                      [class.displayNon]="!downloadRBIXlsxBtn"
                    ></div>
                    <div>RBI Recommendations</div>
                  </button>
                  <button
                    (click)="openPriceExport(exportPrice)"
                    class="export-btn align-items-center"
                  >
                    <i class="bi bi-file-earmark-excel"></i>Export
                  </button>
                  <button
                    (click)="openManageModel(manageModal)"
                    class="manage-btn align-items-center"
                  >
                    <i class="bi bi-pencil"></i> Manage
                  </button>
                  <button
                    class="save-btn align-items-center"
                    (click)="openSaveModel()"
                  >
                    <i class="bi bi-bookmark"></i>Save
                  </button>
                  <button
                    class="downloadMd"
                    (click)="downloadXlsx()"
                    [disabled]="downloadDisabled"
                  >
                    <i class="bi bi-cloud-download"></i>
                    Download
                  </button>
                </div>
              </div>
              <div
                class="col-7"
                *ngIf="activeId === 1"
                style="text-align: right"
              >
                <div class="SaveBtn-container">
                  <button
                    class="downloadXl"
                    (click)="downloadRBIXlsx()"
                    [disabled]="downloadRBIXlsxBtn"
                  >
                    <i
                      class="bi bi-cloud-download"
                      [class.displayNon]="downloadRBIXlsxBtn"
                    ></i>
                    <div
                      class="spinner-border"
                      role="spinnerIcon"
                      [class.displayNon]="!downloadRBIXlsxBtn"
                    ></div>
                    <div>RBI Recommendations</div>
                  </button>
                  <button
                    class="downloadXl"
                    (click)="downloadXlsx()"
                    [disabled]="downloadXlsxBtn"
                  >
                    <i
                      class="bi bi-cloud-download"
                      [class.displayNon]="downloadXlsxBtn"
                    ></i>
                    <div
                      class="spinner-border"
                      role="spinnerIcon"
                      [class.displayNon]="!downloadXlsxBtn"
                    ></div>
                    <div>Download</div>
                  </button>
                </div>
              </div>
            </div>

            <div [ngbNavOutlet]="nav" class=""></div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="activeId === 1">
        <div class="d-flex justify-content-end mt-2">
          <div *ngIf="checkedCompetitors" style="padding: 0px">
            <app-tab-view></app-tab-view>
          </div>
          <div *ngIf="isServiceLoaded" style="padding: 0px 5px">
            <mat-button-toggle-group
              #group="matButtonToggleGroup"
              [value]="selectedServiceMode"
              (change)="ChangeServiceMode(group.value)"
            >
              <mat-button-toggle
                [ngClass]="{
                  inStore: selectedServiceMode === availableServiceMode[0]?.id
                }"
                [value]="availableServiceMode[0]?.id"
                >{{ availableServiceMode[0]?.name }}</mat-button-toggle
              >
              <mat-button-toggle
                [ngClass]="{
                  thirdp: selectedServiceMode === availableServiceMode[1]?.id
                }"
                [value]="availableServiceMode[1]?.id"
                >{{ availableServiceMode[1]?.name }}</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row mt-3" *ngIf="checkedCompetitors && pageIsLoading">
          <div
            class="d-flex justify-content-center align-items-center main-loading"
          >
            <mat-spinner></mat-spinner>
          </div>
        </div>

        <div
          class="row mt-3 adjust-row"
          *ngIf="!checkedCompetitors && !benchMarkIsLoading"
        ></div>

        <div class="row" *ngIf="checkedCompetitors && isLoaded">
          <br />
          <div class="col" style="min-height: 200px">
            <app-landing-data
              [pricingInput]="pricingInput"
              [selectedCategoryId]="selectedCategoryId"
              [selectedConceptId]="selectedConceptId"
              [user]="currentUser"
              [ServiceMode]="selectedServiceMode"
              [restaurants]="competitors"
              [competitors]="competitors"
              [subCategory]="selectedSubGroup"
              [availableSoldTypes]="availableSoldTypes"
              [hasCompetitor]="hasCompetitor"
              [scrollY]="scrollY"
            ></app-landing-data>
          </div>
        </div>
        <div *ngIf="!checkedCompetitors" style="min-height: 200px">
          <app-bk-bentchmark-pricing-table
            [selectedRestaurantNumber]="restaurantSelected"
            [selectedCategoryId]="selectedCategoryId"
            [selectedConceptId]="selectedConceptId"
            [benchMarkToShow]="benchMarkToShow"
            [currentRes]="currentRestaurant"
            [ShowDma]="currentConceptRestaurant?.dmaShow"
            [benchMarkToHide]="benchMarkToHide"
            [ServiceMode]="selectedServiceMode"
            [compId]="bkCompId"
            [currentConceptRestaurant]="currentConceptRestaurant"
            [currentCompetitorRestaurant]="currentCompRestaurant"
            [availableBenchMark]="availableBenchMark"
            [subCategory]="selectedSubGroup"
            [availableSoldTypes]="availableSoldTypes"
          ></app-bk-bentchmark-pricing-table>
        </div>
      </ng-container>
      <ng-container *ngIf="activeId === 2">
        <div style="margin-top: 280px; padding: 0%">
          <app-modeling-tool-pricing-table
            #modelingPricingTable
            [name]="modelVersionName"
            [selectedCategory]="selectedSubGroup"
            [restaurantNum]="selectedModelingRes"
            [auvsFor]="selectedModellingDateRange"
            [currentRes]="currentRestaurant"
            [dateRange]="modelingDateRange"
            [checkedCompetitors]="checkedModelCompetitors"
            [competitors]="competitorsForModelingTool"
            [availableSoldTypes]="availableSoldTypes"
            [isServiceLoaded]="isServiceLoaded"
            [availableServiceMode]="availableServiceMode"
            (onValueChange)="changeSaveModel($event)"
            (isLoadingEvent)="downloadDisabled = $event"
            (cardDetails)="changeCardDetail($event)"
            (showCard)="showCardChange($event)"
          ></app-modeling-tool-pricing-table>
        </div>
      </ng-container>
    </div>
    <div class="row mt-5" style="text-align: center; bottom: 0">
      <div *ngIf="activeId == 1 || activeId == 2" class="legendStyle row">
        <div class="col-2"></div>
        <div class="col boxLeg">
          <div>
            <i class="bi bi-exclamation-triangle-fill text-warning"> </i>
            = No price available for items
          </div>
          <div>
            <i class="bi bi-slash-circle text-danger"> </i>
            = Comparable competitor item does not exist
          </div>
        </div>
        <div class="col-2"></div>
      </div>
      <p *ngIf="activeId == 1 || activeId == 2" class="disclaimer">
        The information used in this report is privleged and confidential. It is
        intended only for viewing and us by {{ userName }}. If you are not
        {{ userName }}, you hearby notified that dissemination, distribution, or
        copying of this report is strictly prohibited.
      </p>
    </div>
  </div>
</div>

<app-toasts></app-toasts>
<ng-template #manageModal let-modal>
  <ng-container
    *ngIf="isManageModelLoading"
    class="no-data d-flex justify-content-center align-items-center"
    style="margin-top: 25px"
  >
    <mat-spinner></mat-spinner>
  </ng-container>
  <ng-container *ngIf="!showApplyMultiModel && !isManageModelLoading">
    <div class="modal-header row">
      <div class="d-flex justify-content-center col-11">
        <h4 class="modal-title">Manage Models</h4>
      </div>
      <button
        type="button"
        class="btn-close col-1"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <div class="col-2">
        <div style="margin-top: 20px; padding: 0 20px">
          <mat-label class="competitors-label">Restaurant #</mat-label>
          <div>
            <mat-label>{{
              removeComma(selectedModelingRes | number : "7.0-0")
            }}</mat-label>
          </div>
        </div>
      </div>
      <table
        *ngIf="savedModel.length != 0"
        class="table manageModel-table"
        style="margin-top: 20px; width: max-content"
      >
        <tr>
          <th>Model Name</th>
          <th>Created By</th>
          <th>Created/Modified</th>
          <th>Notes</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        <tr *ngFor="let item of savedModel; let i = index">
          <td *ngIf="!editMode[i]" [ngClass]="{ disabled: disableMode[i] }">
            {{ item.name }}
          </td>
          <td *ngIf="editMode[i]">
            <input
              type="text"
              #modelName
              class="inputBox"
              [(ngModel)]="item.name"
            />
          </td>
          <td>{{ item.createdBy }}</td>
          <td>{{ item.createdOn }}</td>
          <td>{{ item.notes }}</td>
          <td
            style="color: #3d73d1; cursor: pointer"
            [ngStyle]="{
              'pointer-events': disableMode[i] ? 'none' : 'inherit'
            }"
            (click)="editModel(item.name); modal.dismiss('Cross click')"
          >
            <img src="{{ assetUrl }}/assets/Apply-to-multi-icon.png" />EDIT
          </td>
          <td
            style="color: #3d73d1; cursor: pointer"
            [ngStyle]="{
              'pointer-events': disableMode[i] ? 'none' : 'inherit'
            }"
            (click)="applyToMulti(item, applyModel)"
          >
            <img src="{{ assetUrl }}/assets/Apply-to-multi-icon.png" />APPLY TO
            MULTI
          </td>
          <td
            style="color: #3d73d1; cursor: pointer"
            [ngStyle]="{
              'pointer-events': disableMode[i] ? 'none' : 'inherit'
            }"
            (click)="renameModel(i)"
          >
            <i class="bi bi-pencil"></i>RENAME
          </td>
          <td
            style="color: #3d73d1; cursor: pointer"
            [ngStyle]="{
              'pointer-events': disableMode[i] ? 'none' : 'inherit'
            }"
            (click)="deleteModel(i, item.name)"
          >
            <i class="bi bi-trash"></i>DELETE
          </td>
        </tr>
      </table>
      <div
        *ngIf="savedModel.length == 0"
        class="no-data d-flex justify-content-center align-items-center"
        style="margin-top: 25px"
      >
        <p>
          Sorry no data exists for restaurant
          <strong>#{{ selectedModelingRes }}</strong>
        </p>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        type="close"
        class="btn cancel-btn"
        (click)="modal.dismiss('Cross click')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn set-btn"
        *ngIf="savedModel.length > 0"
        (click)="confirmChanges(); modal.dismiss('Cross click')"
      >
        Confirm
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="showApplyMultiModel && !isManageModelLoading">
    <ng-container *ngIf="!modelIsApplied">
      <div class="applyModel">
        <div class="modal-header row">
          <div class="d-flex justify-content-center col-11">
            <h4 class="modal-title">
              <img src="{{ assetUrl }}/assets/Apply-to-multi-icon.png" />&nbsp;
              Apply Model
            </h4>
          </div>
          <button
            type="button"
            class="btn-close col-1"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          ></button>
        </div>
        <div class="modal-body" style="padding: 20px">
          <div>
            Apply model <strong>{{ applyModelName }}</strong> to
          </div>
          <br />

          <div
            *ngFor="
              let numgroups of restaurantGroupsForModelChanges;
              let i = index
            "
            class="applymodelRestaurantGroupRow"
          >
            <app-restaurant-selector
              (OnRestaurantNum)="ApplyChangeRestaurantsNum($event, i)"
              (OnSelectedRestaurantNumbersChanged)="
                selectedModelingNums = $event
              "
              [isMultiSelect]="true"
              [preSelectedRestaurantGroup]="selectedRestaurantGroup"
              [selectedModelingRes]="selectedModelingRes"
              [modalPriceToSave]="modalPriceToSave"
              [changeRestaurant]="changeRestaurant"
            ></app-restaurant-selector>
            <span
              (click)="RemoveApplyModelRestaurantRow(numgroups)"
              style="color: #3d73d1; cursor: pointer"
              ><i class="bi bi-trash"></i>
            </span>
          </div>
          <br />
          <div
            (click)="AddApplyModelRestaurantRow()"
            style="color: #3d73d1; cursor: pointer"
          >
            <i class="bi bi-plus"></i> Add Group
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            type="close"
            class="btn cancel-btn"
            (click)="cancelApplyModels()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn set-btn"
            (click)="applyModelChanges()"
          >
            Apply
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="modelIsApplied">
      <div class="applyModel">
        <div class="modal-header row">
          <div class="d-flex justify-content-center col-11">
            <h4 class="modal-title">
              <img src="{{ assetUrl }}/assets/Apply-to-multi-icon.png" />&nbsp;
              Model(s) Created
            </h4>
          </div>
          <button
            type="button"
            class="btn-close col-1"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          ></button>
        </div>
        <div class="modal-body">
          <div style="margin-left: 10px">
            Model {{ applyModelName }} will be applied to restaurants:
          </div>
          <br />
          <ul>
            <li *ngFor="let restaurant of selectedModelingNums">
              {{ restaurant }}
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn set-btn"
            (click)="modal.dismiss('Cross click')"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn set-btn"
            (click)="confirmModelApplication(); modal.dismiss('Cross click')"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #exportPrice let-modal>
  <ng-container>
    <div class="modal-header row">
      <h4 style="text-align: center; margin: 10px">POS Price Export</h4>
    </div>
    <div class="row" style="margin: 25px"></div>
    <div class="modal-body row" style="margin: 10px">
      <p>
        Provide a group name for the export. It must match the group name found
        in SICOM. Any item without a price recommendation will not be exported.
      </p>
      <div>
        <span> Group Name: </span>
        <span>
          <input
            class="inputBox"
            #exportGroupName
            type="text"
            style="font-weight: bold"
            value=""
          />
        </span>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        type="close"
        class="btn cancel-btn"
        (click)="modal.dismiss('Cross click')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn set-btn"
        (click)="
          exportPriceXlsx(exportGroupName, modelingPricingTable);
          modal.dismiss('Cross click')
        "
      >
        Export
      </button>
    </div>
  </ng-container>
</ng-template>
<app-save-model-modellingtool
  [selectedModelingRes]="selectedModelingRes"
  [modalPriceToSave]="modalPriceToSave"
></app-save-model-modellingtool>

<ng-template #applyModel let-modal>
  <ng-container *ngIf="!modelIsApplied">
    <div class="modal-header row">
      <div class="d-flex justify-content-center col-11">
        <h4 class="modal-title">
          <img src="{{ assetUrl }}/assets/Apply-to-multi-icon.png" />&nbsp;
          Apply Model
        </h4>
      </div>
      <button
        type="button"
        class="btn-close col-1"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <div>
        Apply model <strong>{{ applyModelName }}</strong> to
      </div>
      <br />

      <div
        *ngFor="let numgroups of restaurantGroupsForModelChanges; let i = index"
        class="applymodelRestaurantGroupRow"
      >
        <app-restaurant-selector
          (OnRestaurantNum)="ApplyChangeRestaurantsNum($event, i)"
          [isMultiSelect]="true"
          [preSelectedRestaurantGroup]="selectedRestaurantGroup"
          [selectedModelingRes]="selectedModelingRes"
          [modalPriceToSave]="modalPriceToSave"
          [changeRestaurant]="changeRestaurant"
        ></app-restaurant-selector>
        <span
          (click)="RemoveApplyModelRestaurantRow(numgroups)"
          style="color: #3d73d1; cursor: pointer"
          ><i class="bi bi-trash"></i>
        </span>
      </div>
      <br />
      <div
        (click)="AddApplyModelRestaurantRow()"
        style="color: #3d73d1; cursor: pointer"
      >
        <i class="bi bi-plus"></i> Add Group
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        type="close"
        class="btn cancel-btn"
        (click)="modal.dismiss('Cross click'); cancelApplyModels()"
      >
        Cancel
      </button>
      <button type="button" class="btn set-btn" (click)="applyModelChanges()">
        Apply
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="modelIsApplied" class="applyModel">
    <div class="modal-header row">
      <div class="d-flex justify-content-center col-11">
        <h4 class="modal-title">
          <img src="{{ assetUrl }}/assets/Apply-to-multi-icon.png" />&nbsp;
          Model(s) Created
        </h4>
      </div>
      <button
        type="button"
        class="btn-close col-1"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <div>New Models Created:</div>
      <br />
      <ul>
        <li>005329.2.11.23-v1</li>
      </ul>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn cancel-btn"
        (click)="modal.dismiss('Cross click')"
      >
        Close
      </button>
      <button
        type="button"
        class="btn set-btn"
        (click)="confirmModelApplication()"
      >
        Confirm
      </button>
    </div>
  </ng-container>
</ng-template>

<ng-template #notSavedModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Warning</h4>
    <i class="bi bi-x-circle delete-cross" (click)="changeWithoutSave()"></i>
  </div>
  <div class="modal-body">
    <div class="inner-body">
      <p class="error-message">
        Please save the model before continuing, otherwise changes will be lost
      </p>
    </div>
  </div>
  <div class="footerBtn mb-3">
    <button class="btn set-btn mx-3" (click)="openSaveModel()">
      <i class="bi bi-bookmark"></i> Save Models
    </button>
  </div>
</ng-template>
