import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompetitorRestaurantService {

  apiRoot: string = `${environment.competitorUrl}/api/competitorrestaurants`;
  
  constructor(private http: HttpClient) { }

  getCompetitorsyBrand(storeNum:string, competiorId:string){
      let url = `${this.apiRoot}/GetCompetitorRestaurantsForBrand?storeNumber=${storeNum}&CompetitorId=${competiorId}`
      return this.http.get(url)
    }
    getCompetitorsOthers(storeNum:string){
      let url = `${this.apiRoot}/GetCompetitorRestaurantsForOthers?storeNumber=${storeNum}`
      return this.http.get(url)
    }

  getCompetitors(storeNum:string,compId:string){
    let url = `${this.apiRoot}?Filters=competitorId%3D%3D${compId}%2C%20num%3D%3D${storeNum}`;
    return this.http.get(url);
  }
}
