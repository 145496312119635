import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbAccordionModule, NgbCarouselConfig, NgbCarouselModule, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { LandingComponent } from './landing/landing.component';
import { ComponentsModule } from './components/components.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { MatBadgeModule, MatButtonModule, MatButtonToggleModule, MatCheckbox, MatCheckboxModule, MatFormField, MatFormFieldModule, MatIconModule, MatProgressSpinnerModule, MatSelectModule,MatTooltipModule, MatTreeModule } from '@angular/material';
import { ErrorComponent } from './error/error.component';
import { BkBenchmarkComponent } from './components/bk-benchmark/bk-benchmark.component';
import { ToastsContainerComponent } from './components/toast-container/toast-container';

import { BkBentchmarkPricingTableComponent } from './components/bk-bentchmark-pricing-table/bk-bentchmark-pricing-table.component';
import { SanitizePipe } from './pipes/sanitize';
import { RestaurantSearchPipe, RestaurantGroupSearchPipe, CallbackPipe } from './pipes/filter';
import { NgSuperSelectModule } from 'ng-super-select';
import { ModelingToolPricingTableComponent } from './modeling-tool-pricing-table/modeling-tool-pricing-table.component';
import { RestaurantSelectorComponent } from './components/restaurant-selector/restaurant-selector.component';
import { DatePipe, DecimalPipe, NgFor } from '@angular/common';
import { SideMenuCategoryComponent } from './components/side-menu-category/side-menu-category.component';
import { ImpactHeaderComponent } from './components/impact-header/impact-header.component';
import { SaveModelModellingtoolComponent } from './components/save-model-modellingtool/save-model-modellingtool.component';
import { ImpactPricingTableComponent } from './components/impact-pricing-table/impact-pricing-table.component';
import { CompetitorDropdownComponent } from './components/competitor-dropdown/competitor-dropdown.component';
import { ToastrModule } from 'ngx-toastr';
import { GlobalErrorHandler } from './interceptors/global-error.handler';
import { SlideShowComponent } from './components/tier-pricing-user-tutorial/slide-show.component';
import { TierListComponent } from './components/tier-list/tier-list.component';
import { TextBarComponent } from './components/text-bar/text-bar.component';
import { RuleListComponent } from './components/rule-list/rule-list.component';
import { RestrictDecimalInputDirective } from './services/restrict-decimal-input.directive';
import { ContactFormComponent } from './components/contact-form/contact-form.component';


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    ErrorComponent,
    BkBenchmarkComponent,
    BkBentchmarkPricingTableComponent,
    SanitizePipe,
    RestaurantSearchPipe,
    CallbackPipe,
    RestaurantGroupSearchPipe,
    ModelingToolPricingTableComponent,
    RestaurantSelectorComponent,
    SideMenuCategoryComponent,
    ImpactHeaderComponent,
    SaveModelModellingtoolComponent,
    ImpactPricingTableComponent,
    CompetitorDropdownComponent,
    SlideShowComponent,
    TierListComponent,
    TextBarComponent,
    RuleListComponent,
    ContactFormComponent,
    RestrictDecimalInputDirective,
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgbNavModule,
    NgbAccordionModule,
    ComponentsModule,
    MatSlideToggleModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatTooltipModule,
    ToastsContainerComponent,
    MatProgressSpinnerModule,
    NgSuperSelectModule,
    MatCheckboxModule,
    NgbCarouselModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatBadgeModule,
    NgFor,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    ToastrModule.forRoot()
  ],
  providers: [
    NgbCarouselConfig,
    DatePipe,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: 'Window',  useValue: window }
  ],
  bootstrap: [AppComponent],
  entryComponents: [TierListComponent],
})
export class AppModule { }
