<div class="card shadow mb-5 bg-white rounded">
    <div class="card-body">
        <div class="store-num" *ngIf="!competitor?.competitorName"><img class="map-pin"
                src="{{assetsUrl}}/assets/Map Pin.png" /> My Restaurant # 
                <strong *ngIf="!isTestEnv">{{removeComma(competitor?.num == null?'':competitor?.num | number : '7.0-0')}}</strong>
                <strong *ngIf="isTestEnv">******</strong>
            </div>
        <div class="store-num" *ngIf="competitor?.competitorName">Restaurant #
            <strong *ngIf="!isTestEnv">{{removeComma(competitor?.num == null?'':competitor?.num| number : '7.0-0')}}</strong>
            <strong *ngIf="isTestEnv">******</strong>
        </div>
        <div class="card-title">
            <div class="row">
                <div class="col-sm-1 col-md-1 ">
                    <img class="comp-img" [src]="getIcon(competitor?.competitorName)" />
                </div>
                <div class="col ml-1 comp-info-col">
                    <span class="">
                        <span *ngIf="!competitor?.competitorName" style="margin-bottom:-6px" class="distance"><br/></span>
                        <span *ngIf="competitor?.competitorName" class="distance">{{competitor?.distance| number : '1.1-1'}} Miles
                            Away</span>
                        <strong>{{competitor?.addr1}} <span  >{{competitor?.cityName}}, {{competitor?.stateCd}} {{competitor?.zipCd?.length >5 ? competitor?.zipCd?.slice(0,5) : competitor?.zipCd}}</span></strong>
                    
                    </span>

                </div>
            </div>

        </div>

        <ng-template #stars let-fill="fill">
            <i class="bi-star-fill">
                <i *ngIf="fill > 0" class="bi-star-fill filled" [style.width.%]="fill"></i>
            </i>
        </ng-template>        
        <div class="stat d-flex justify-content-between" style="padding-top:5px"><strong>Household Income</strong> <span style="padding-left:41px ;" *ngIf="competitor?.medianHhIncome">${{competitor?.medianHhIncome | number}}</span><span style="padding-left:41px ;" *ngIf="!competitor?.medianHhIncome">N/A</span></div>
    </div>
</div>