<div class="row">
    <div class="col-4">
        <mat-label class="competitors-label">McDonald's</mat-label>
        <mat-select placeholder="Select" [multiple]="true" [disableOptionCentering]="true" [(value)]="selectedMCDL"
            (valueChange)="changeCompititors()">
            <mat-select-trigger>
                <span>Selected {{selectedMCDL.length}}</span>
            </mat-select-trigger>
            <mat-option class="options" [disabled]="selectedCompetitors.length>=2 && check(el)"
                *ngFor="let el of nearMcdCompetitors" [value]="el">
                <div class="d-flex justify-content-between">
                    {{el.addr1}},
                    {{el.cityName}}, {{el.stateCd}},
                    {{el.zipCd}} <span class="distance">{{el.distance|
                        number :
                        '1.1-1'}} Miles
                        Away</span></div>
            </mat-option>
        </mat-select>
    </div>
    <div class="col-4">
        <mat-label class="competitors-label">Wendy's</mat-label>
        <mat-select placeholder="Select" [multiple]="true" [disableOptionCentering]="true" [(value)]="selectedWendyL"
            (valueChange)="changeCompititors()">
            <mat-select-trigger>
                <span>Selected {{selectedWendyL.length}}</span>
            </mat-select-trigger>
            <mat-option style="font-size: 15px;" [disabled]="selectedCompetitors.length>=2 && check(el)"
                *ngFor="let el of nearWendyCompetitors" [value]="el">
                <div class="d-flex justify-content-between">
                    {{el.addr1}},
                    {{el.cityName}}, {{el.stateCd}},
                    {{el.zipCd}} <span class="distance">{{el.distance|
                        number :
                        '1.1-1'}} Miles
                        Away</span></div>
            </mat-option>
        </mat-select>
    </div>
    <div class="col-4">
        <mat-label class="competitors-label">Others</mat-label>
        <mat-select placeholder="Select" [multiple]="true" [disableOptionCentering]="true" [(value)]="selectedOthersL"
            (valueChange)="changeCompititors()">
            <mat-select-trigger>
                <span>Selected {{selectedOthersL.length}}</span>
            </mat-select-trigger>
            <mat-optgroup style="font-weight: bold;" *ngFor="let group of nearOtherCompetitors"
                [label]="getOtherCompetitorName(group)">
                <img class="others-img" [src]="getIcon(getOtherCompetitorName(group))">
                <ng-container *ngIf="isObjArray(group)">
                    <mat-option style="font-size: 15px;" *ngFor="let el of group" [value]="el"
                        [disabled]="selectedCompetitors.length>=2 && check(el)">
                        <div class="d-flex justify-content-between">
                            {{el.addr1}},
                            {{el.cityName}}, {{el.stateCd}},
                            {{el.zipCd}} <span class="distance">{{el.distance|
                                number :
                                '1.1-1'}} Miles
                                Away</span></div>
                    </mat-option>
                </ng-container>
                <ng-container *ngIf="!isObjArray(group)">
                    <mat-option style="font-size: 15px;" [value]="group"
                    [disabled]="selectedCompetitors.length>=2 && check(group)">
                    <div class="d-flex justify-content-between">
                        {{group.addr1}},
                        {{group.cityName}}, {{group.stateCd}},
                        {{group.zipCd}} <span class="distance">{{group.distance|
                            number :
                            '1.1-1'}} Miles
                            Away</span></div>
                </mat-option>
                </ng-container>
            </mat-optgroup>
        </mat-select>
    </div>
</div>