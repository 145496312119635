import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { MenuCategoryService } from 'src/app/services/menu-category.service';
import { SharedEventService } from 'src/app/services/shared-event.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaveModelModellingtoolComponent } from '../save-model-modellingtool/save-model-modellingtool.component';

@Component({
  selector: 'app-side-menu-category',
  templateUrl: './side-menu-category.component.html',
  styleUrls: ['./side-menu-category.component.scss']
})
export class SideMenuCategoryComponent implements OnInit {

  DEFAULT_CATEGORY = 'Whopper';
  @Output() onChangeCategory = new EventEmitter<object>();

  @Input() selectedCategory;
  @Input() selectedModelingRes;
  @Input() modalPriceToSave;
  @Input() activeId;
  @ViewChild('notSavedModel') mymodal: ElementRef;
  @ViewChild(SaveModelModellingtoolComponent) saveModalRef: SaveModelModellingtoolComponent;
  parantNode;
  dataArray = [];
  canChange=true;
  categoryList=[];
  private transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children?.length > 0,
      name: node.name,
      level: level,
      items: node,
    };
  };

  treeControl = new FlatTreeControl<any>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: any) => node.expandable;

  constructor(private categoryService: MenuCategoryService,private sharedService: SharedEventService, private modalService: NgbModal) { 
    this.sharedService.canChange.subscribe(res=>{
      this.canChange = res;  
    })
  }

  async ngOnInit() {
    // we need to wait for the jwt auth token to be available in the window object
    await this.delay(3000);
    this.categoryList=[];
    this.categoryService.GetMenuCategory().subscribe(res => {
      let data: any = res as [];      
      data.forEach((el) => {
        this.categoryList.push(...el);
        let modifiedData = {
          name: el[0].groupName,
          children: el.sort((a: any, b: any) => {
            return a.subgroupSortId - b.subgroupSortId;
          }),
          groupSortId: el[0].groupSortId,
        };
        this.dataArray.push(modifiedData);
      });

      this.sharedService.categoryList.emit(this.categoryList);
      this.dataArray = this.dataArray.sort(
        (a: any, b: any) => a.groupSortId - b.groupSortId
      );
        let allMenuCategory = {
          name: 'All'
        }
  
        this.dataArray.unshift(allMenuCategory);
        this.dataSource.data = this.dataArray;
      if (this.dataArray.some(category => category.name == this.DEFAULT_CATEGORY)){
        this.selectedCategory = this.DEFAULT_CATEGORY;
      }
      else{
        this.selectedCategory = data[0][0].subgroupName;
      }
      this.onChangeCategory.emit(this.selectedCategory);
      let indexToOpen = -1;
      let i = 1;
      let j;
      data.forEach((el) => {
        j = 0;
        if (el.length != 0) {
          el.forEach((el1) => {
            i = i + 1;
            j = j + 1;
            if (el1.subgroupName == this.selectedCategory) {
              indexToOpen = i - j;
            }
          });
        }
        i = i + 1;
      });
      this.parantNode = this.treeControl.dataNodes[indexToOpen];

      this.treeControl.expand(this.treeControl.dataNodes[indexToOpen]);

    });


  }
  subNode;
  nodeClicked(node) {
    this.subNode = node;
    this.node = null;
    if(this.canChange==true){
      this.changeNodeWhenClickedExpand();
    }else{
      this.modalService.open(this.mymodal);
    }
  }

  changeNodeWhenClickedExpand(){
    if (this.treeControl.isExpanded(this.subNode)) {
      let parent = null;

      let index = this.treeControl.dataNodes.findIndex((n) => n === this.subNode);

      for (let i = index; i >= 0; i--) {
        if (this.subNode.level > this.treeControl.dataNodes[i].level) {
          parent = this.treeControl.dataNodes[i];
          break;
        }
      }

      if (parent) {
        this.treeControl.collapseDescendants(parent);
        this.treeControl.expand(parent);
      } else {
        this.treeControl.collapseAll();
      }

      this.treeControl.expand(this.subNode);
    }
    this.selectedCategory = this.subNode.items.children[0].subgroupName;
    this.onChangeCategory.emit(this.selectedCategory);
  }

  getParent(node) {
    const { treeControl } = this;
    const currentLevel = treeControl.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const index = treeControl.dataNodes.indexOf(node) - 1;

    for (let i = index; i >= 0; i--) {
      const currentNode = treeControl.dataNodes[i];

      if (treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
  }
  node;
  selectCategory(node) { 
    this.node = node;  
    this.subNode= null;
    if(this.canChange){
      this.changeNode()
    }else{
      this.modalService.open(this.mymodal,{backdrop: 'static'});
    }
  }

  changeNode(){
    if (this.node.level >= 1) {
      this.selectedCategory = this.node.items.subgroupName;
      this.parantNode = this.getParent(this.node);
    } else {
      this.parantNode = null;
      this.selectedCategory = this.node.items.name;
      if(this.selectedCategory == 'All'){
        this.treeControl.collapseAll();
      }

    }
    this.onChangeCategory.emit(this.selectedCategory);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  changeWithoutSave(){    
    this.canChange=true;
    this.sharedService.canChange.emit(true);
    if(this.subNode == null){
      this.changeNode();
    }else if ( this.node == null){
      this.changeNodeWhenClickedExpand();
    }
    this.modalService.dismissAll();
  }

  openSaveModel() {
    this.modalService.dismissAll();
    this.saveModalRef.openModal();
  }

}
