import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class MenuItemPriceService {
  
     restaurantMenuApiRoot: string = `${environment.menuUrl}/api/menuitemprice`;
     competitorRestaurantMenuApiRoot: string = `${environment.menuUrl}/api/competitormenuitemprices`;

     constructor(private http: HttpClient){
     }

     getAll() {
        let result = this.http.get(this.restaurantMenuApiRoot);
        return result;
      }

      getAnchorMenuItemPrice(restrurantNum:string) {
        let result = this.http.get<Array<any>>(`${this.restaurantMenuApiRoot}/GetAnchorMenuItemPrice?restrurantNumber=${restrurantNum}`);
        return result;
      }

      updateMenuitemPrice(data){        
        let result = this.http.post<Array<any>>(`${environment.menuUrl}/api/competitorpluprices`,data);
        return result;
      }

      getCompetitorAnchorMenuItemPrice(restaurantNum:string, competitorRestrurantNumber:string) {
        let result = this.http.get<Array<any>>(`${this.competitorRestaurantMenuApiRoot}/GetCompetitorAnchorMenuItemPrice?restrurantNumber=${restaurantNum}&competitorRestrurantNumber=${competitorRestrurantNumber}`);
        return result;
      }
      updateCompetitorMenuitemPrice(data) {
        let result = this.http.post<Array<any>>(`${this.competitorRestaurantMenuApiRoot}/AddCompetitorMenuitemPriceList`,data);
        return result;
      }

      getMenuitemPriceForCategory(restaurantNum:string, categoryId:string, conceptId:string,serviceMode:string,soldTypeId:string) {
        let result = this.http.get<Array<any>>(`${this.restaurantMenuApiRoot}/GetMenuitemPriceForCategory?restrurantNumber=${restaurantNum}&categoryId=${categoryId}&conceptId=${conceptId}&serviceModeId=${serviceMode}&soldType=${soldTypeId}`);
        return result;
      }

      getMenuitemRevisionPriceForCategory(restaurantNum:string, categoryId:string, conceptId:string,serviceMode:string,soldTypeId:string){
        let result = this.http.get<Array<any>>(`${this.restaurantMenuApiRoot}/GetMenuitemPriceRevisionsForCategory?restrurantNumber=${restaurantNum}&categoryId=${categoryId}&conceptId=${conceptId}&serviceModeId=${serviceMode}&soldType=${soldTypeId}`);
        return result;
      }

      getMenuItemPricePlu(resId:string,compId:string,subGroupName:string,serviceMode:string,soldTypeId:string){
        let result;

        if(soldTypeId && subGroupName!="All"){
          result = this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForGroup?restaurantId=${resId}&competitorId=${compId}&subGroupName=${subGroupName}&servicemodeId=${serviceMode}&soldtypeId=${soldTypeId}`); 
        }
        else if(!soldTypeId && subGroupName != "All"){
          result = this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForGroup?restaurantId=${resId}&competitorId=${compId}&subGroupName=${subGroupName}&servicemodeId=${serviceMode}`); 
        }
        else if(soldTypeId && subGroupName== "All"){
          result = this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForGroup?restaurantId=${resId}&competitorId=${compId}&servicemodeId=${serviceMode}&soldtypeId=${soldTypeId}`); 
        }
        else if(!soldTypeId && subGroupName== "All"){
          result = this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForGroup?restaurantId=${resId}&competitorId=${compId}&servicemodeId=${serviceMode}`); 
        }

        return result;
      }
      getMenuitemPluRevisionPriceForCategory(restaurantId:string,competitorId:string,subGroupName:string,serviceMode:string,soldType:string){
        let result;
        if(soldType && subGroupName!="All"){
          result= this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceRevisionsForGroup?restaurantId=${restaurantId}&competitorId=${competitorId}&subGroupName=${subGroupName}&servicemodeId=${serviceMode}&soldtypeId=${soldType}`); 
        }
        else if(!soldType && subGroupName != "All"){
          result= this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceRevisionsForGroup?restaurantId=${restaurantId}&competitorId=${competitorId}&subGroupName=${subGroupName}&servicemodeId=${serviceMode}`); 
        }
        else if(soldType && subGroupName== "All"){
          result= this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceRevisionsForGroup?restaurantId=${restaurantId}&competitorId=${competitorId}&servicemodeId=${serviceMode}&soldtypeId=${soldType}`); 
        }
        else if(!soldType && subGroupName== "All"){
          result= this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceRevisionsForGroup?restaurantId=${restaurantId}&competitorId=${competitorId}&servicemodeId=${serviceMode}`); 
        }
        return result;
      }

      getCompetitorPluPriceForCompetitorGroup(restaurantIds:string, subGroupName:string, serviceMode:string,soldType:string){
        let result;
        if(soldType && subGroupName != "All"){
          result = this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForCompetitorGroup?restaurantIds=${restaurantIds}&subGroupName=${subGroupName}&servicemodeId=${serviceMode}&soldtypeId=${soldType}`); 
        }else if(!soldType && subGroupName != "All"){
          result = this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForCompetitorGroup?restaurantIds=${restaurantIds}&subGroupName=${subGroupName}&servicemodeId=${serviceMode}`); 
        }
        else if(soldType && subGroupName== "All"){
          result = this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForCompetitorGroup?restaurantIds=${restaurantIds}&servicemodeId=${serviceMode}&soldtypeId=${soldType}`);  
        }
        else if(!soldType && subGroupName== "All"){
          result = this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForCompetitorGroup?restaurantIds=${restaurantIds}&servicemodeId=${serviceMode}`); 
        }
        return result;
      }
      getCompetitorPluPriceRevisionsForCompetitorGroup(restaurantIds:string[], subGroupName:string, serviceMode:string,soldType:string){
        let result;
        if(soldType && subGroupName != "All"){
          result = this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceRevisionsForCompetitorGroup?restaurantIds=${restaurantIds}&subGroupName=${subGroupName}&servicemodeId=${serviceMode}&soldtypeId=${soldType}`); 
        }else if(!soldType && subGroupName != "All"){
          result = this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceRevisionsForCompetitorGroup?restaurantIds=${restaurantIds}&subGroupName=${subGroupName}&servicemodeId=${serviceMode}`); 
        }
        else if(soldType && subGroupName== "All"){
          result = this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceRevisionsForCompetitorGroup?restaurantIds=${restaurantIds}&servicemodeId=${serviceMode}&soldtypeId=${soldType}`);  
        }
        else if(!soldType && subGroupName== "All"){
          result = this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceRevisionsForCompetitorGroup?restaurantIds=${restaurantIds}&servicemodeId=${serviceMode}`); 
        }
        return result;
      }

      getCompetitorPluPriceForGroup(restaurantId:string, competitorId:string, subGroupName:String, serviceMode:string,soldType:string) {
        let result 
        if(soldType && subGroupName != "All"){
          result= this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForGroup?restaurantId=${restaurantId}&competitorId=${competitorId}&subGroupName=${subGroupName}&servicemodeId=${serviceMode}&soldtypeId=${soldType}`); 
        }
        else if(!soldType && subGroupName != "All"){
          result= this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForGroup?restaurantId=${restaurantId}&competitorId=${competitorId}&subGroupName=${subGroupName}&servicemodeId=${serviceMode}`); 
        }
        else if(soldType && subGroupName== "All"){
          result= this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForGroup?restaurantId=${restaurantId}&competitorId=${competitorId}&servicemodeId=${serviceMode}&soldtypeId=${soldType}`); 
        }
        else if(!soldType && subGroupName== "All"){
          result= this.http.get(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForGroup?restaurantId=${restaurantId}&competitorId=${competitorId}&servicemodeId=${serviceMode}`); 
        }
        return result;
      }

      getCompetitorMenuitemPriceForCategory(restaurantNum:string, competitorRestrurantNumber:string[], categoryId:string, conceptId:string,serviceMode:string,soldTypeId:string) {
      const cmpStr =[];
      competitorRestrurantNumber.forEach(num=>{
        cmpStr.push('"'+num+'"');
      })
     const str=  competitorRestrurantNumber.join(",");
        let result = this.http.get<Array<any>>(`${this.competitorRestaurantMenuApiRoot}/GetCompetitorMenuitemPriceForCategory?restrurantNumber=${restaurantNum}&competitorRestrurantNumbers=${str}&categoryId=${categoryId}&conceptId=${conceptId}&serviceModeId=${serviceMode}&soldType=${soldTypeId}`);
       // let result = this.http.get<Array<any>>(`${this.competitorRestaurantMenuApiRoot}/GetCompetitorMenuitemPriceForCategory`);
        return result;
      }
      getCompetitorMenuitemRevisionPriceForCategory(restaurantNum:string, competitorRestrurantNumber:string[], categoryId:string, conceptId:string,serviceMode:string,soldTypeId:string) {
      const cmpStr =[];
      competitorRestrurantNumber.forEach(num=>{
        cmpStr.push('"'+num+'"');
      })
     const str=  competitorRestrurantNumber.join(",");

        let result = this.http.get<Array<any>>(`${this.competitorRestaurantMenuApiRoot}/GetCompetitorMenuitemPriceRevisionsForCategory?restrurantNumber=${restaurantNum}&competitorRestrurantNumbers=${str}&categoryId=${categoryId}&conceptId=${conceptId}&serviceModeId=${serviceMode}&soldType=${soldTypeId}`);
        return result;
      }

      GetMenupriceForRestaurantSalesBand(num , conceptId, categortId,serviceModeId,soldTypeId){
        let result = this.http.get<Array<any>>(`${this.restaurantMenuApiRoot}/GetMenupriceForRestaurantSalesBand?num=${num}&categoryId=${categortId}&conceptId=${conceptId}&serviceModeId=${serviceModeId}&soldType=${soldTypeId}`);
        return result;
      }
      GetMenuPriceForRestaurantDma(num , conceptId, categortId,serviceModeId,soldTypeId){
        let result = this.http.get<Array<any>>(`${this.restaurantMenuApiRoot}/GetMenuPriceForRestaurantDma?num=${num}&categoryId=${categortId}&conceptId=${conceptId}&serviceModeId=${serviceModeId}&soldType=${soldTypeId}`);
        return result;
      }
      GetMenuPriceForRestaurantNational(conceptId, categortId,serviceModeId,soldTypeId){
        let result = this.http.get<Array<any>>(`${this.restaurantMenuApiRoot}/GetMenuPriceForRestaurantNational?categoryId=${categortId}&conceptId=${conceptId}&serviceModeId=${serviceModeId}&soldType=${soldTypeId}`);
        return result;
      }

      // GetCompetitorPluPriceForCluster(resNum:string,compId:string,subGroupName:string,serviceModeId:string){
      //   let result = this.http.get<any>(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForCluster?restaurantNumber=${resNum}&competitorId=${compId}&subGroupName=${subGroupName}&servicemodeId=${serviceModeId}`);
      //   return result;
      // }
      GetCompetitorPluPriceForSalesband(resNum:string,compId:string,subGroupName:string,serviceModeId:string,soldType:string){
        let result;
        if(soldType){
          result = this.http.get<any>(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForSalesband?restaurantNumber=${resNum}&competitorId=${compId}&subGroupName=${subGroupName}&servicemodeId=${serviceModeId}&soldtypeId=${soldType}`);
        }else{
          result = this.http.get<any>(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForSalesband?restaurantNumber=${resNum}&competitorId=${compId}&subGroupName=${subGroupName}&servicemodeId=${serviceModeId}`);

        }
        return result;
      }
      // GetCompetitorPluPriceForDma(resNum:string,compId:string,subGroupName:string,serviceModeId:string){
      //   let result = this.http.get<any>(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForDma?restaurantNumber=${resNum}&competitorId=${compId}&subGroupName=${subGroupName}&servicemodeId=${serviceModeId}`);
      //   return result;
      // }

      // GetCompetitorPluPriceForNational(resNum:string,compId:string,subGroupName:string,serviceModeId:string,soldType:string){
      //   let result;
      //   if(soldType){
      //     result = this.http.get<any>(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForNational?restaurantNumber=${resNum}&competitorId=${compId}&subGroupName=${subGroupName}&servicemodeId=${serviceModeId}&soldtypeId=${soldType}`);
      //   }else{
      //     result = this.http.get<any>(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForNational?restaurantNumber=${resNum}&competitorId=${compId}&subGroupName=${subGroupName}&servicemodeId=${serviceModeId}`);
      //   }
      //   return result;
      // }    

      // GetCompetitorPluPriceForBkcDivision(resNum:string,compId:string,subGroupName:string,serviceModeId:string,soldType:string){
      //   let result;
      //   if(soldType){
      //     result = this.http.get<any>(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForBkcDivision?restaurantNumber=${resNum}&competitorId=${compId}&subGroupName=${subGroupName}&servicemodeId=${serviceModeId}&soldtypeId=${soldType}`);
      //   }else{
      //     result = this.http.get<any>(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForBkcDivision?restaurantNumber=${resNum}&competitorId=${compId}&subGroupName=${subGroupName}&servicemodeId=${serviceModeId}`);
      //   }
      //   return result;
      // }
      GetCompetitorPluPriceForBenchMark(resNum:string,compId:string,subGroupName:string,serviceModeId:string,soldType:string){
        let result;

        if(soldType && subGroupName!="All"){
          result = this.http.get<any>(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForBenchMark?restaurantNumber=${resNum}&competitorId=${compId}&subGroupName=${subGroupName}&servicemodeId=${serviceModeId}&soldtypeId=${soldType}`);
        }
        else if(!soldType && subGroupName != "All"){
          result = this.http.get<any>(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForBenchMark?restaurantNumber=${resNum}&competitorId=${compId}&subGroupName=${subGroupName}&servicemodeId=${serviceModeId}`);
        }
        else if(soldType && subGroupName== "All"){
          result = this.http.get<any>(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForBenchMark?restaurantNumber=${resNum}&competitorId=${compId}&servicemodeId=${serviceModeId}&soldtypeId=${soldType}`);    
        }
        else if(!soldType && subGroupName== "All"){
          result = this.http.get<any>(`${environment.menuUrl}/api/competitorpluprices/GetCompetitorPluPriceForBenchMark?restaurantNumber=${resNum}&competitorId=${compId}&servicemodeId=${serviceModeId}`);
        }
        
        return result;
      }
      GetExcelExportDetailsToDownload(){
        let result = this.http.get<any>(`${environment.menuUrl}/api/competitorpluprices/GetExcelExportDetailsToDownload`);
        return result
      }

      getAllSoldTypes(){
        let result = this.http.get<any>(`${environment.menuUrl}/api/soldtypes`);
        return result;
      }

      getAllServiceMode(){
        let result = this.http.get<any>(`${environment.menuUrl}/api/servicemodes`);
        return result;
      }

      getTimePeriods(){
        let result = this.http.get<any>(`${environment.menuUrl}/api/timeperiods`);
        return result;
      }
  }