export const environment = {
  production: true,
  isTestEnvironment:false,
  showImpactTab:false,
  showModelingTab:true,
  showTierTab:true,
  showMenuTab: true,
  rootUrl: 'https://d34u520zvzit7u.cloudfront.net',
  competitorUrl: 'https://rmp-rsiweb.rsilink.com',
  restaurantUrl: 'https://rmp-rsiweb.rsilink.com',
  rsiWebUrl:"https://rsiweb.rsilink.com/Net/RMP",
  menuUrl: 'https://rmp-rsiweb.rsilink.com',
  assetUrl:'https://rmp-rsiweb.rsilink.com'
};
