import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { data } from '../services/mock-data';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedEventService } from '../services/shared-event.service';
import { MenuItemPriceService } from '../services/menu-item-price.service';
import * as _ from 'lodash';
import { RestaurantService } from '../services/restaurant.service';
import { ConceptPluSalesService } from '../services/concept-plu-sales.service';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, first, forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { RecommendationsService } from '../services/recommendations.service';
import * as dayjs from 'dayjs';
import * as minMax from 'dayjs/plugin/minMax';
dayjs.extend(minMax)

@Component({
  selector: 'app-modeling-tool-pricing-table',
  templateUrl: './modeling-tool-pricing-table.component.html',
  styleUrls: ['./modeling-tool-pricing-table.component.scss']
})
export class ModelingToolPricingTableComponent implements OnInit, OnChanges {
  @Input()
  selectedCategory: string;
  @Input()
  groupName: string;
  @Input() activeId;
  @Input()
  restaurantNum: any;
  @Input()
  auvsFor: any;
  @Input()
  competitors: any;
  @Input()
  currentRes: any;
  @Input()
  dateRange: any;
  @Input()
  checkedCompetitors: boolean;
  @Input()
  availableSoldTypes: any;
  @Input()
  availableServiceMode: any;
  @Input()
  isServiceLoaded: any;
  @Input()
  name: string;
  @Output() onValueChange = new EventEmitter<object>();
  @Output() cardDetails = new EventEmitter<object>();
  @Output() showCard = new EventEmitter<boolean>();
  @Output() isLoadingEvent = new EventEmitter<boolean>();
  assetsUrl: string;
  menuItems: any[] = [];
  copyMenuItems: any;
  btnFlag: boolean = false;
  btnText: string = 'Load RBI Recommendation';
  projectionFlag: boolean = false;
  projectionValue: string = 'Enter Projected Change %';
  priceToBeSaved: any[] = [];
  isLoadRBIClicked: boolean = false;
  selectedServiceMode: string = 'In-store';
  selectedServiceModeId: string = '11e6cd44-4b53-4579-bd23-8f719677470a';
  priceIncrease;
  priceChangePercentage;
  tempPriceIncrease = '';
  tempPriceChangePercentage = '';
  saveModel: boolean = false;
  savedDate = new Date();
  canChange = true;
  isSaved = false;
  tableData = [];
  conceptResId;
  selectedSoldTypeId = [];
  isLoading = true;
  response = [];
  totalCurrentAuv = 0;
  totalProjectedAuv = 0;
  totalcurrentSales = 0;
  totalProjectedSales = 0;
  totalcurrentgp = 0;
  totalProjectedGp = 0;
  isTestEnv = false;
  timePeriodId;
  conceptId;
  modelVersion = "v1";
  normalizeValue = 30;
  salesData;
  cardData = [];
  recommendations = [];
  showRecommendations = false;
  lastRecommendationsDate = null;
  loadedRecommendationsDate = null;
  enableLoadRecommendations = false;

  private parameterChangeSubject = new Subject<any>();

  constructor(private recommendationsService: RecommendationsService, private ngbmodalService: NgbModal, private sharedService: SharedEventService, private menuService: MenuItemPriceService, private restaurantService: RestaurantService, private conceptSalesService: ConceptPluSalesService, private toastr: ToastrService) {
    this.sharedService.canChange.subscribe(res => {
      this.canChange = res;
    });
    this.sharedService.isSaved.subscribe(res => {
      this.isSaved = res;
    });
    this.sharedService.savedModelVersion.subscribe(res => {
      this.modelVersion = res.version;
      this.name = res.name
    });
    this.sharedService.downloadModelEvent.subscribe(res => {
      this.downloadModel();
    })

    this.parameterChangeSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(() => this.refreshTableData());

  }

  ngOnInit(): void {
    this.assetsUrl = environment.assetUrl;
    if (environment.isTestEnvironment) {
      this.isTestEnv = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showCard.emit(false);
    this.isLoading = true;
    this.isLoadingEvent.emit(true);
    this.cardDetails.emit([])
    this.projectionFlag = false;
    if ('restaurantNum' in changes) {
      this.name = null;
      this.sharedService.isSaved.emit(false);
    }
    if ('name' in changes && changes['name']['previousValue'] == null && changes['name']['currentValue'] != null) //if the change is result of an edit call
    {
      this.sharedService.isSaved.emit(true);
    }
    else {
      this.sharedService.isSaved.emit(false);
    }
    if (changes['currentRes']) {
      this.restaurantService.getCurrentRestaurantByNum(this.currentRes.num).subscribe((res: any) => {
        this.conceptId = res[0].conceptId;
        this.conceptResId = res[0].id;
        this.parameterChangeSubject.next(changes);
      })
    } else if (!changes['currentRes']) {
      this.parameterChangeSubject.next(changes);
    }

    this.isLoadRBIClicked = false;
    this.priceToBeSaved = [];
    this.saveModel = false;
  }
  async refreshTableData() {

    this.isLoading = true;
    this.isLoadingEvent.emit(true);
    switch (this.auvsFor) {
      case '30 Days':
        this.normalizeValue = 30;
        break;
      case '7 Days':
        this.normalizeValue = 7;
        break;
      case '90 Days':
        this.normalizeValue = 90;
        break;
    }
    this.selectedSoldTypeId = [];
    this.availableSoldTypes?.forEach(element => {
      if (element.isSelected) {
        this.selectedSoldTypeId.push(element.id);
      }
    });
    this.timePeriodId = this.dateRange.filter(el => el.name == this.auvsFor)[0].id;

    if (this.selectedSoldTypeId.length != 1) {
      this.selectedSoldTypeId = null;
    }

    let restaurantIds = [this.currentRes.id];
    if (this.checkedCompetitors) this.competitors.forEach(el => restaurantIds.push(el.id));
    this.selectedCategory = this.selectedCategory?.replace(/&/g, '%26');

    let modellingSources = [this.menuService.getCompetitorPluPriceForCompetitorGroup(restaurantIds.join(','), this.selectedCategory, this.selectedServiceModeId, this.selectedSoldTypeId ? this.selectedSoldTypeId[0] : null)];
    modellingSources.push(this.conceptSalesService.GetConceptRestaurantPluSalesForCategory(this.conceptResId, this.timePeriodId, this.selectedCategory, this.selectedServiceModeId, this.selectedSoldTypeId ? this.selectedSoldTypeId[0] : null));
    forkJoin(modellingSources).subscribe(res => {
      var restaurantId = this.currentRes.id;
      var serviceMode = this.selectedServiceModeId;
      var groupName = this.selectedCategory;

      this.response = res;
      this.tableData = [];
      let groupedData = {};
      this.competitors.forEach((el) => {
        groupedData[el.id] = [];
      })
      this.salesData = this.response[1];
      let tempGroupedData = _.groupBy(res[0], "competitorRestaurantId");

      _.each(tempGroupedData, (val, key) => {
        groupedData[key] = val;
      });
      _.each(groupedData, ((val, key) => {
        val = val.sort((a, b) => {
          return a.groupSortId - b.groupSortId || a.subgroupSortId - b.subgroupSortId || a.sortId - b.sortId;
        })
      }))
      groupedData[this.currentRes.id]?.forEach(el => {
        if (el.groupName !== 'Upcharges') {
          let obj = {
            conceptPluId: el.conceptPluId,
            pluName: el.name,
            pluNum: el.num,
            pluPrice: el.price,
            sortId: el.sortId,
            timeperiodId: this.timePeriodId,
            conceptId: this.conceptId,
            servicemodeId: this.selectedServiceModeId,
            subgroupName: el.subgroupName,
            soldType: this.availableSoldTypes.find(ast => ast.id == el.soldtypeId)?.name,
            delivery: el.delivery ? 'Y' : 'No'
          }
          this.tableData.push(obj);
        }
      });

      if (this.checkedCompetitors) {
        _.each(groupedData, (val, key) => {
          this.tableData.forEach(el => {
            let itemIdx = val.findIndex(td => td.conceptPluId == el.conceptPluId);
            if (itemIdx != -1) {
              if (val[itemIdx].competitorPluName != null) {
                el[key] = {
                  price: val[itemIdx].price,
                  competitorPluName: val[itemIdx].competitorPluName
                };
              } else {
                el[key] = {
                  price: -1,
                  competitorPluName: null
                }
              }

            }
            else {
              el[key] = {
                price: -1,
                competitorPluName: null
              }
            }
          });
        });
      }
      this.tableData.forEach(el => {
        // if the model is locked, stay with values from the db
        let itemidx = this.response[1].findIndex(td => td.conceptPluId == el.conceptPluId);
        if (itemidx != -1) {
          el.regUnits = this.response[1][itemidx].totalQty / this.normalizeValue;
          el.pluCostAmt = this.response[1][itemidx].regCostAmt / this.normalizeValue;
          el.totalCostAmt = this.response[1][itemidx].totalCostAmt / this.normalizeValue;
        }
      })

      this.showRecommendations = false;

      this.restaurantService.restaurantplupricemodels(this.restaurantNum, this.selectedCategory, this.name).subscribe({
        next: resp => {
          this.isLoading = false;
          this.isLoadingEvent.emit(false);
          this.loadedRecommendationsDate = dayjs(null);

          if (resp.length >= 1) {
            var modelFullName = resp[0].name;
            var modelNameParts = modelFullName.split("-");
            var version = modelNameParts[2];
            this.sharedService.savedModelVersion.emit({
              version: version, name: modelFullName
            });
            this.sharedService.isSaved.emit(true);
            this.tableData.forEach(el => {
              let itemidx = resp.findIndex(td => td.conceptPluId == el.conceptPluId);
              if (itemidx != -1) {
                el.overridePrice = (resp[itemidx].overridePrice)?.toFixed(2);
                el.recommendedPrice = (resp[itemidx].recommendedPrice)?.toFixed(2);
                el.recommendationsLoadedOn = resp[itemidx].recommendationsLoadedOn;
                el.projectedUnits = resp[itemidx].projectedUnits;
                el.projectedUnitsCalculation = resp[itemidx].projectedUnits != null ? (resp[itemidx].projectedUnits).toFixed(2) : null;
              }
            })
            this.loadedRecommendationsDate = dayjs.max(this.tableData.map(td => dayjs(td.recommendationsLoadedOn)));


            this.modelingToolCalculations();

          }

          this.recommendationsService.getRBIRecommendations(restaurantId, null, groupName, serviceMode).subscribe({
            next: response => {

              this.recommendations = response;

              this.lastRecommendationsDate = dayjs.max(this.recommendations.map(x => dayjs(x.recommendationMonth)))?.format("MMM YYYY") ?? "Unknown";

              this.tableData.forEach(item => {
                var itemRecommendation = this.recommendations.find(x => x.conceptPluId == item.conceptPluId);
                item.recommendation = itemRecommendation?.price;
                item.recommendationMonth = (this.isdefined(item.recommendationsLoadedOn) ? dayjs(item.recommendationsLoadedOn).format("MMM YYYY") : dayjs(itemRecommendation?.recommendationMonth)?.format("MMM YYYY")) ?? "Unknown";

              });

              this.enableLoadRecommendations = this.recommendations.length > 0 && (!this.loadedRecommendationsDate.isValid() || this.loadedRecommendationsDate.isBefore(dayjs(this.lastRecommendationsDate)));
            },
            error: error => this.toastr.error("There was an error when loading RBI Recommendations")
          });

        },
        error: e => {
        }
      })


      this.modelingToolCalculations();
      this.copyMenuItems = JSON.parse(JSON.stringify(this.tableData));
    })
  }

  modelingToolCalculations() {
    this.totalCurrentAuv = 0;
    this.totalProjectedAuv = 0;
    this.totalcurrentSales = 0;
    this.totalProjectedSales = 0;
    this.totalcurrentgp = 0;
    this.totalProjectedGp = 0;
    this.tableData.forEach(el => {
      el.actualDailyCost = +(el.pluCostAmt);
      el.regAmt = el.regUnits * el.pluPrice;
      el.costofOneUnit = (el.totalCostAmt / el.regUnits).toFixed(2);
      el.projectedAmt = this.isdefinedNumber(el.projectedUnits) && this.isdefinedNumber(el.overridePrice)
        ? el.overridePrice * el.projectedUnits : null;
      el.gpAmt = el.regAmt - (el.regUnits * el.costofOneUnit);
      el.gpProjectedAmt = this.isdefinedNumber(el.projectedAmt) && this.isdefinedNumber(el.projectedUnits)
        ? (el.projectedAmt - (el.projectedUnits * el.costofOneUnit)) : null;
    })
    let totalDailyUnits = 0;
    let totalDailySales = 0;
    let totalDailyGp = 0;
    let totalActualUnits = 0;
    let totalActualSales = 0;
    let totalActualGp = 0;
    this.tableData.forEach(el => {
      totalActualUnits += this.isdefinedNumber(el.regUnits) ? el.regUnits : 0;
      totalActualSales += this.isdefinedNumber(el.regAmt) ? el.regAmt : 0;
      totalActualGp += this.isdefinedNumber(el.gpAmt) ? el.gpAmt : 0;
      totalDailyUnits += this.isdefinedNumber(el.projectedUnits) ? el.projectedUnits : (this.isdefinedNumber(el.regUnits) ? el.regUnits : 0);
      totalDailySales += this.isdefinedNumber(el.projectedAmt) ? el.projectedAmt : (this.isdefinedNumber(el.regAmt) ? el.regAmt : 0);
      totalDailyGp += this.isdefinedNumber(el.gpProjectedAmt) ? el.gpProjectedAmt : (this.isdefinedNumber(el.gpAmt) ? el.gpAmt : 0);

    });
    this.cardData = [];
    let monthly = {
      title: 'Monthly Estimates',
      overallUnits: totalDailyUnits * 30,
      overallSales: totalDailySales * 30,
      overallGP: totalDailyGp * 30,
      actualUnits: totalActualUnits * 30,
      actualSales: totalActualSales * 30,
      actualGp: totalActualGp * 30,
    }
    monthly['unitsDiff'] = monthly.overallUnits - monthly.actualUnits;
    monthly['salesDiff'] = monthly.overallSales - monthly.actualSales;
    monthly['gpDiff'] = monthly.overallGP - monthly.actualGp;
    this.cardData.push(monthly);
    let annual = {
      title: 'Annual Estimates',
      overallUnits: totalDailyUnits * 365,
      overallSales: totalDailySales * 365,
      overallGP: totalDailyGp * 365,
      actualUnits: totalActualUnits * 365,
      actualSales: totalActualSales * 365,
      actualGp: totalActualGp * 365,
    }
    annual['unitsDiff'] = annual.overallUnits - annual.actualUnits;
    annual['salesDiff'] = annual.overallSales - annual.actualSales;
    annual['gpDiff'] = annual.overallGP - annual.actualGp;
    this.cardData.push(annual);

    if (this.tableData && this.tableData.length > 0) {
      this.showCard.emit(true);
    }
    this.cardDetails.emit(this.cardData);
    this.onValueChange.emit(this.tableData);
  }

  ChangeServiceMode(value) {
    this.selectedServiceModeId = value;
    this.showCard.emit(false);
    this.refreshTableData();

  }
  getIcon(brand: string) {
    if (brand == undefined || brand == null) {
      return this.assetsUrl + "/assets/BK Logo@2x.png";
    }
    if (brand == 'McDonalds') {
      return this.assetsUrl + "/assets/MCD Logo@2x.png";
    }
    if (brand == 'Jack In A Box') {
      return this.assetsUrl + "/assets/JackInTheBox Logo@2x.png";
    }
    if (brand == 'Wendys') {
      return this.assetsUrl + "/assets/Wendys Logo@2x.png";
    }
    if (brand == 'Sonic') {
      return this.assetsUrl + "/assets/sonic-logo.png"
    }
    if (brand == 'Whataburger') {
      return this.assetsUrl + "/assets/Whataburger_logo.png"
    }
    if (brand == 'Taco Bell') {
      return this.assetsUrl + "/assets/taco-bell.png"
    }
    if (brand == 'In N Out') {
      return this.assetsUrl + "/assets/In-N-Out-Burger.webp"
    }
    if (brand == 'Hardees') {
      return this.assetsUrl + "/assets/hardees.webp"
    }
    if (brand == "BK") {
      return this.assetsUrl + "/assets/BK Logo@2x.png"
    }
    if (brand == 'Carl\'s Jr.') {
      return this.assetsUrl + "/assets/carlsjr_logo.png"
    }
    return "";
  }

  openManageModel(content) {
    this.tempPriceIncrease = '';
    this.tempPriceChangePercentage = '';
    this.ngbmodalService.open(content, { windowClass: 'manageModel' });
  }

  openRecommendationsModal(content) {
    if (!this.showRecommendations) {
      this.ngbmodalService.open(content);
    }
  }

  confirmLoadRecommendations() {
    this.showRecommendations = true;
    this.populateRecommendations();

  }

  populateRecommendations() {
    this.tableData.forEach(el => {
      el.recommendedPrice = el.recommendation;
      el.recommendationsLoadedOn = dayjs(this.lastRecommendationsDate);
      el.recommendationMonth = el.recommendationsLoadedOn.format("MMM YYYY");
      if ((el.overridePrice == null && el.pluPrice < el.recommendation) || el.overridePrice < el.recommendation) el.overridePrice = el.recommendation;
    });
    this.enableLoadRecommendations = false;
  }
  EnterProjectionChange() {
    this.projectionFlag = true;
    this.saveModel = true;
    const priceVariation = this.tempPriceIncrease == 'true' ? 1 : -1;
    this.priceChangePercentage = +this.tempPriceChangePercentage;
    this.tableData.forEach(el => {
      if (this.isdefinedNumber(el.overridePrice)) {
        el.projectedUnits = (el.regUnits + priceVariation * (el.regUnits * this.priceChangePercentage / 100));
        el.projectedUnitsCalculation = el.projectedUnits.toFixed(2);
      }
    })
    this.modelingToolCalculations();
    this.ngbmodalService.dismissAll();
    if (this.priceToBeSaved.length > 0 || this.saveModel) {
      this.sharedService.canChange.emit(false);
    } else {
      this.sharedService.canChange.emit(true);
    }
  }

  restrictDigit(index) {
    this.tableData[index].projectedUnitsCalculation = (this.tableData[index].projectedUnitsCalculation)?.toFixed(2);
  }

  onChangeprice(index, isProjetedRSI?, value?) {
    if (isProjetedRSI) {
      this.tableData[index].projectedUnitsCalculation = (value && value.length > 0) ? +value : null;
    }
    if (isProjetedRSI) {
      let projectedAuvChangedCount = 0;
      this.tableData[index].projectedUnits = (value && value.length > 0) ? +value : null;
      this.tableData.forEach((el, idx) => {
        let changed = el.projectedUnits != this.copyMenuItems[idx].projectedUnits;
        if (changed) {
          projectedAuvChangedCount++;
        }
      })
      if (projectedAuvChangedCount > 0) {
        this.projectionFlag = true;
      }
    } else {
      this.tableData[index].overridePrice = value;
    }
    if (this.priceToBeSaved.length == 0) {
      if (this.copyMenuItems[index]?.overridePrice != this.tableData[index]?.overridePrice || this.copyMenuItems[index]?.projectedUnits != this.tableData[index]?.projectedUnits) {
        this.priceToBeSaved.push(this.tableData[index])
      }
    } else {
      let idx = this.priceToBeSaved.findIndex(el => el?.conceptPluId == this.tableData[index].conceptPluId);
      this.priceToBeSaved.splice(idx, 1);
      if (this.copyMenuItems[index]?.overridePrice != this.tableData[index]?.overridePrice || this.copyMenuItems[index]?.projectedUnits != this.tableData[index]?.projectedUnits) {
        this.priceToBeSaved.push(this.tableData[index]);
      }
    }
    this.modelingToolCalculations();
    if (this.priceToBeSaved.length > 0 || this.saveModel) {
      this.sharedService.canChange.emit(false);
    } else {
      this.sharedService.canChange.emit(true);
    }
  }
  removeComma(num: string) {
    return num = num.replace(/\,/g, '');
  }

  isdefined(elem) {
    return elem != null && elem !== undefined;
  }
  isdefinedNumber(elem) {
    return this.isdefined(elem) && !isNaN(parseFloat(elem));
  }
  downloadModel() {
    var tableDataToExport = [];
    const firstCompetitor = this.competitors[0];
    for (var item of this.tableData) {
      var itemToExport = {
        "BK PLU": item.pluName,
        "BK Current Price": item.pluPrice?.toFixed(2),
        "BK Recommended Price": item.recommendedRBIPrice?.toFixed(2),
        "New Price": item.overridePrice,
        "Sold Type": item.soldType,
        "Delivery": item.delivery,
        "AUVs Current": item.regUnits?.toFixed(2),
        "AUVs Projected": item.projectedUnitsCalculation,
        "Sales Current": item.regAmt?.toFixed(2),
        "Sales Projected": item.projectedAmt?.toFixed(2),
        "Gross Profit Current": item.gpAmt?.toFixed(2),
        "Gross Profit Projected": item.gpProjectedAmt?.toFixed(2),

      };
      if (this.competitors.length > 0) {
        var competitorData = item[firstCompetitor.id];
        itemToExport["#1 Competitor PLU"] = competitorData.competitorPluName;
        itemToExport[`#1 Competitor Price`] = competitorData.price > 0 ? competitorData.price?.toFixed(2) : '';
      }
      tableDataToExport.push(itemToExport);

    }
    let commonHeaders = [];
    commonHeaders.push(['Model:', this.name != null ? `${this.name}-${this.modelVersion}` : '']);
    commonHeaders.push(['Restaurant #:', this.currentRes.num.toString().padStart(7, '0'), '', '#1 Competitor Name:', firstCompetitor.competitorName]);
    commonHeaders.push(['BKC Address:', `${this.currentRes.addr1} ${this.currentRes.cityName}, ${this.currentRes.stateCd} ${this.currentRes.zipCd}`, '', '#1 Competitor Restaurant #:', firstCompetitor.num.toString().padStart(7, '0')]);
    commonHeaders.push(['AUV Date Range:', this.auvsFor, '', '#1 Competitor Address:', `${firstCompetitor.addr1} ${firstCompetitor.cityName}, ${firstCompetitor.stateCd} ${firstCompetitor.zipCd}`]);
    commonHeaders.push([]);

    var headers = ["BK PLU", "BK Current Price", "BK Recommended Price", "New Price", "Sold Type", "Delivery", "AUVs Current", "AUVs Projected", "Sales Current", "Sales Projected", "Gross Profit Current", "Gross Profit Projected", "#1 Competitor PLU", "#1 Competitor Price"];
    commonHeaders.push(headers);


    let worksheet = XLSX.utils.sheet_add_aoa([[]], commonHeaders);
    let ws = XLSX.utils.sheet_add_json(worksheet, tableDataToExport, { origin: `A${commonHeaders.length}` });
    // format price columns as numbers 
    var columnFormats = ['s', 'n', 'n', 'n', 's', 's', 'n', 'n', 'n', 'n', 'n', 'n', 's', 'n'];


    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "Model");
    ws = workbook.Sheets[workbook.SheetNames[0]];
    for (var i = 0; i < tableDataToExport.length; i++) {
      for (var j = 0; j < columnFormats.length; j++) {
        var cell = ws[i + commonHeaders.length][j];
        if (cell != null && cell.v != null && cell.v !== undefined) {
          cell.t = columnFormats[j];
          if (columnFormats[j] == 'n' && this.isdefinedNumber(cell.v)) cell.z = "0.00"
        }

      }
    }

    XLSX.writeFile(workbook, `RMP_modeling_${this.currentRes.num.toString().padStart(7, '0')}.xlsx`);

  }


}
