<div class="sales-card" *ngIf="showCard">
    <div class="row">
        <div class="col-12 modedlingSales text-Center">
            <p class="title" id="salesCardTitle">{{title}}</p>
        </div>
    </div>
    <div class="d-flex justify-content-center align-items-center" *ngIf="isLoading">
        <div class="spinner-border" role="spinnerIcon"></div>
    </div>
    <table style="width:100%" *ngIf="!isLoading">
        <thead class="heading-cell" id="headingCell">
            <tr>
                <th></th>
                <th >Actuals</th>
                <th id="headingOverall" *ngIf="title">Projected</th>
                <th *ngIf="title">Differences</th>
            </tr>
        </thead>
        <tbody>
            <tr id="unitsRow">
                <th class="pb-2" *ngIf="title">Units</th>
                <td >
                    <p class="overall-sales" [ngClass]="{'red':actualUnits<0}" >
                        {{actualUnits | number : '1.2-2'}}
                        <i class="bi bi-arrow-up" *ngIf="actualUnits>0"></i>
                        <i class="bi bi-arrow-down" *ngIf="actualUnits<0"></i>
                    </p>
                </td>
                <td *ngIf="title">
                    <p class="overall-sales" [ngClass]="{'red':overallUnits<0}" >

                        {{overallUnits | number : '1.2-2'}}
                        <i class="bi bi-arrow-up" *ngIf="overallUnits>0"></i>
                        <i class="bi bi-arrow-down" *ngIf="overallUnits<0"></i>
                    </p>
                </td>
                <td  >
                    <p class="overall-sales" [ngClass]="{'red':unitsDiff<0}" >

                        {{unitsDiff | number : '1.2-2'}}
                        <i class="bi bi-arrow-up" *ngIf="unitsDiff>0"></i>
                        <i class="bi bi-arrow-down" *ngIf="unitsDiff<0"></i>
                    </p>
                </td>
            </tr>
            <tr id="salesRow">
                <th *ngIf="title" class="pb-2">Sales ($)</th>
                <td >
                    <p class="overall-sales" [ngClass]="{'red':actualSales<0}" >

                        <span *ngIf="actualSales && !isNaN(actualSales)">$</span>{{actualSales | number : '1.2-2'}}
                        <i class="bi bi-arrow-up" *ngIf="actualSales>0"></i>
                        <i class="bi bi-arrow-down" *ngIf="actualSales<0"></i>
                    </p>
                </td>
                <td *ngIf="title">
                    <p class="overall-sales" [ngClass]="{'red':overallSales<0}" >

                        <span *ngIf="overallSales && !isNaN(overallSales)">$</span>{{overallSales | number : '1.2-2'}}
                        <i class="bi bi-arrow-up" *ngIf="overallSales>0"></i>
                        <i class="bi bi-arrow-down" *ngIf="overallSales<0"></i>
                    </p>
                </td>
                <td  >
                    <p class="overall-sales" [ngClass]="{'red':salesDiff<0}" >

                        {{(salesDiff!=undefined && !isNaN(salesDiff))?'$'+(salesDiff | number : '1.2-2'):''}}
                        <i class="bi bi-arrow-up" *ngIf="salesDiff>0"></i>
                        <i class="bi bi-arrow-down" *ngIf="salesDiff<0"></i>
                    </p>
                </td>
            </tr>
            <tr id="gpRow">
                <th *ngIf="title" class="pb-2">GP ($)</th>
                <td >
                    <p class="overall-sales" [ngClass]="{'red':actualGp<0}" *ngIf="title">
                        <span *ngIf="actualGp && !isNaN(actualGp)">$</span>{{actualGp | number : '1.2-2'}}
                        <i class="bi bi-arrow-up" *ngIf="actualGp>0"></i>
                        <i class="bi bi-arrow-down" *ngIf="actualGp<0"></i>
                    </p>
                </td>
                <td  >
                    <p class="overall-sales" [ngClass]="{'red':overallGP<0}" >
                        <span *ngIf="overallGP && !isNaN(overallGP)">$</span>{{overallGP | number : '1.2-2'}}
                        <i class="bi bi-arrow-up" *ngIf="overallGP>0"></i>
                        <i class="bi bi-arrow-down" *ngIf="overallGP<0"></i>
                    </p>
                </td>
                <td  >
                    <p class="overall-sales" [ngClass]="{'red':gpDiff<0}">
                        {{(gpDiff!=undefined && !isNaN(gpDiff) ) ?'$'+(gpDiff | number : '1.2-2'):''}}
                        <i class="bi bi-arrow-up" *ngIf="gpDiff>0"></i>
                        <i class="bi bi-arrow-down" *ngIf="gpDiff<0"></i>
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>