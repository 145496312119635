import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { delay, forkJoin } from 'rxjs';
import { ConceptPluSalesService } from 'src/app/services/concept-plu-sales.service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import { data } from 'src/app/services/mock-data';
import { SharedEventService } from 'src/app/services/shared-event.service';

@Component({
  selector: 'app-impact-header',
  templateUrl: './impact-header.component.html',
  styleUrls: ['./impact-header.component.scss']
})
export class ImpactHeaderComponent implements OnInit, OnChanges {
  @Output() OnRestaurantNum = new EventEmitter<number>();
  @Output() OnRestaurantGroup = new EventEmitter<string>();
  @Input() availableSoldTypes;
  impactDate: any=undefined;
  @Input() selectedRestaurant: any;
  @Input() changeRestaurant: any;
  searchModelForm: FormGroup;
  menuItems: any[];
  @Input() selectedSubGroup;
  dummyItems: any[];
  @Input() firstLoad: boolean;
  @Input() selectedRestaurantGroup: string;
  @Input() categoryList;
  createOnDate: any;
  monthCardDetails = {
    title: 'Monthly Differences',
    overallUnits: undefined,
    overallSales: undefined,
    overallGP: undefined,
    actualUnits:undefined,
    actualSales: undefined,
    actualGp: undefined,
    unitsDiff:undefined,
    salesDiff:undefined,
    gpDiff:undefined
  }
  yearCardDetails = {
    title: 'Annual Differences',
    overallUnits: undefined,
    overallSales: undefined,
    overallGP: undefined,
    actualUnits:undefined,
    actualSales: undefined,
    actualGp: undefined,
    unitsDiff:undefined,
    salesDiff:undefined,
    gpDiff:undefined
  }
  modelList = []
  impactModelSelected: string;
  impactModelSelectedPeriod: string;
  static selectedModel: string;
  impactPeriodValue: string = "";
  @Input() impactPeriodList: any[];
  @ViewChild('notSavedModel') mymodal: ElementRef;
  model: NgbDateStruct;
  thirtyDayPeriodId = '9551f8cd-8918-4a92-8cbc-99dc2cf6e9eb';
  yearTimePeriodId = '69249368-7f0e-4e9d-8fe7-f2c06fa5b584';
  searchText='';
  groupSearchText='';
  availableModelingRestaurantGroup=[];
  availableModelingRestaurant=[];
  preSelectedRestaurantGroup;
  previousSelectedRestaurantGroup;
  restaurantSelectForm;
  changeRest: boolean = false;
  changeGroup: boolean = false;
  pluLength;
  timePeriodSelected:boolean=false;
  constructor(private ngbmodalService: NgbModal, private _fb: FormBuilder, private datePipe: DatePipe, private conceptPluService: ConceptPluSalesService,private fb: FormBuilder,private sharedService: SharedEventService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.timePeriodSelected=false;
    this.selectedSubGroup = this.selectedSubGroup?.replace(/&/g, '%26');
    this.availableModelingRestaurant=[]
    this.changeRestaurant.forEach((x: any) => {
      if (x.groupName ==  this.selectedRestaurantGroup) {
        this.availableModelingRestaurant.push(x);
      }
    }) 
    this.conceptPluService.GetRestaurantPluPriceModelsForStore(this.selectedRestaurant).subscribe({
      next: resp => {
        if (resp.length > 0) {
          this.modelList = resp;
          this.impactModelSelected = this.modelList[0]?.name;
          this.impactModelSelectedPeriod = this.modelList[0].timperiodId;
          ImpactHeaderComponent.selectedModel = this.modelList[0];
          this.impactPeriodValue = undefined;
          this.menuItems=[];
          this.impactDate = undefined;
          this.refreshImpact();
        } else {
          this.modelList=[];
          this.menuItems=[];
          this.impactPeriodValue = undefined;
          this.impactPeriodList = [];
          this.impactDate = undefined
          this.ngbmodalService.open(this.mymodal);
        }
      }
    })
  }

  ngOnInit(): void {
    this.searchModelForm = this._fb.group({
      searchModelInputValue: ['']
    });
    this.mtitle = "Monthly Differences";

    this.ytitle = "Annual Differences";
    this.searchModelForm.get('searchModelInputValue').valueChanges.pipe(delay(500)).subscribe((text) => {
      // API call here
      this.conceptPluService.GetRestaurantPluPriceModelsForStore(this.selectedRestaurant).subscribe({
        next: resp => {
          this.impactPeriodValue = undefined;
          this.modelList = resp;
          this.modelList = this.modelList.filter((el: any) => {
            if (el.name.includes(text)) {

              return el;
            }
          })
        }
      })
    })
    if (this.changeRestaurant?.length > 0) {
      var options = this.changeRestaurant.filter((item, i, arr) => arr.findIndex((t) => t.groupId === item.groupId) === i);
      this.availableModelingRestaurantGroup = options;
      this.availableModelingRestaurantGroup = this.availableModelingRestaurantGroup.sort((a, b) => {
        let textA = a.groupName.toUpperCase();
        let textB = b.groupName.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
      })
    }

    this.changeRestaurant?.forEach((x: any) => {
      if (x.groupName ==  this.selectedRestaurantGroup) {
        this.availableModelingRestaurant.push(x);
      }
    })

  this.restaurantSelectForm = this.fb.group({
    restaurantNums: new FormControl(''),
    searchText: new FormControl('')
  });

  this.restaurantSelectForm.valueChanges.subscribe(val => {
    this.getSearchText(val.searchText);
  })

    // this.conceptPluService.restaurantplupricemodels(this.impactModelSelected, this.selectedSubGroup).subscribe({
    //     next: resp => {
    //       this.impactDate = resp?.length > 0 ? this.datePipe.transform(new Date(resp[0]?.createdOn), 'yyyy-MM-dd') : '';
    //     }
    // })

  }

  formatDate(d: any) {
    return dayjs(d).format('MM/DD/YY');
  }

  selectedModelValueChange(selectValue?: any) {
    this.resetCard();
    this.timePeriodSelected=false;
    this.searchModelForm.get('searchModelInputValue').patchValue('');
    if (selectValue != undefined) {
      this.impactModelSelected = selectValue;
      const model = this.modelList.find(ele => ele.name == this.impactModelSelected);
      ImpactHeaderComponent.selectedModel = model;
      this.impactModelSelectedPeriod = model.timperiodId;
    }
    this.menuItems = [];

    this.moverallSales = 0;
    this.moverallUnits = 0;
    this.moverallGP = 0;
    this.loadingCards = false;
    this.yoverallSales = 0;
    this.yoverallUnits = 0;
    this.yoverallGP = 0;

    this.dummyItems = [];
    this.dummyItems = data.menuItemsPriceImpact.filter(el => {
      return el.resNum == 1;
    })

    this.conceptPluService.restaurantplupricemodels(this.impactModelSelected, this.selectedSubGroup).subscribe({
      next: resp => {
        this.impactDate = resp?.length > 0 ? this.datePipe.transform(new Date(resp[0]?.createdOn), 'yyyy-MM-dd') : '';
      }
    })

  }
  canPickDate(date: NgbDateStruct) {
    const dateStr = `${date.year}-${date.month}-${date.day}`;
    let d = dayjs(ImpactHeaderComponent.selectedModel['createdOn']);
    const dStr = `${d.year()}-${d.month() + 1}-${d.date()}`;    
    let createdOn = dayjs(dStr);
    let currentDate = dayjs().add(1, 'days').format("YYYY-MM-DD");
    const theDate = dayjs(dateStr);
    if (theDate.isSame(createdOn) || (theDate.isAfter(createdOn) && theDate.isBefore(currentDate))) {
      return false;
    }
    return true;
  }

  changeSoldType() {
    this.availableSoldTypes = [...this.availableSoldTypes];
  }

  mtitle: any
  moverallSales: any
  moverallUnits: any
  moverallGP: any
  ytitle: any
  yoverallSales: any
  yoverallUnits: any
  yoverallGP: any
  monthUnitsDiff: any;
  monthSalesDiff: any = undefined;
  monthGpDiff: any = undefined;
  yearUnitsDiff: any;
  yearSalesDiff: any = undefined;
  yearGpDiff: any = undefined;

  selectImpactPeriod(impactPeriodId) {
    this.timePeriodSelected = true;
    this.resetCard();
    this.impactPeriodValue = impactPeriodId;
    this.refreshImpact();
  }

  loadSpinner: boolean = false;
  refreshImpact() {
    this.moverallSales = 0;
    this.moverallUnits = 0;
    this.moverallGP = 0;

    this.yoverallSales = 0;
    this.yoverallUnits = 0;
    this.yoverallGP = 0;

    this.dummyItems = [];
    this.dummyItems = data.menuItemsPriceImpact.filter(el => {
      return el.resNum == 1;
    });

    if (this.impactModelSelected && this.impactPeriodValue && this.selectedSubGroup) {
      this.loadSpinner = true;
      this.pluLength = this.impactPeriodList.filter(el => el.id == this.impactPeriodValue)[0].periodLength;
      let dateStr = dayjs(`${this.impactDate.year}-${this.impactDate.month}-${this.impactDate.day}`).add(this.pluLength,'days').format('MM/DD/YYYY');
      forkJoin([this.conceptPluService.restaurantplupricemodels(this.impactModelSelected, this.selectedSubGroup),
      this.conceptPluService.conceptRestaurantPluSales(this.selectedRestaurant, this.impactPeriodValue, this.selectedSubGroup, dateStr)]).subscribe({
        next: (resp:any) => {
          let restModles = resp[0].filter(x => x.overridePrice != null && x.overridePrice - x.pluPrice != 0);
          let salesModles = resp[1];

          restModles.forEach(restM => {
            let pluGroup = this.categoryList.filter(el=> el.subgroupName == restM.subgroupName)[0];
            restM.subgroupSortId = pluGroup.subgroupSortId;
            restM.groupSortId = pluGroup.groupSortId;
            if(salesModles && salesModles.length>0){
              salesModles.forEach(salesM => {
                if (salesM.conceptPluId == restM.conceptPluId) {
                  restM.regUnits = salesM.regQty;
                  restM.regAmt = salesM.regSalesAmt;
                  restM.gpAmt = (salesM.regSalesAmt)-(salesM.regCostAmt);
                  restM.projectedUnits = restM.projectedUnits ? restM.projectedUnits : restM.regUnits;
                  restM.projectedAmt = restM.projectedUnits ? restM.overridePrice * restM.projectedUnits : restM.regAmt;
                  const costOfOneUnit = salesM.totalCostAmt / salesM.regQty;
                  restM.gpProjectedAmt = restM.projectedUnits ? restM.projectedAmt - (restM.projectedUnits* costOfOneUnit) : restM.gpAmt;
                  restM.sortId = salesM.sortId;
                }
              });
            }else{
              restM.regUnits = null;
              restM.regAmt = null;
              restM.gpAmt = null;
            }
          });
          restModles.sort((a, b) => {
            return a.groupSortId - b.groupSortId || a.subgroupSortId - b.subgroupSortId || a.sortId - b.sortId;
          })
          this.menuItems = restModles;
          this.loadSpinner = false;
          if (this.menuItems.length > 0) {
            this.getSalesCardData(restModles, salesModles);
          } else {
            this.moverallSales = 0;
            this.moverallUnits = 0;
            this.moverallGP = 0;

            this.yoverallSales = 0;
            this.yoverallUnits = 0;
            this.yoverallGP = 0;

            this.dummyItems = [];
            this.dummyItems = data.menuItemsPriceImpact.filter(el => {
              return el.resNum == 1;
            });
          }
        }
      });
    }
  }
  loadingCards = false;
  getSalesCardData(menuItems: any, salesModles: any) {
    let totalDailyUnits = 0;
    let totalActualUnits = 0;
    let totalActualGp = 0;
    let totalPlateCostAmt = 0;
    let totalCurrentAuv = 0;
    let projectedTotalPlateCostAmt = 0;
    let projectedTotalCurrentAuv = 0;
    let totalDailySales = 0;
    let totalActualSales = 0;
    let totalDailyGp=0;
    let  totalProjectedCostof1unit=0;
    menuItems.forEach(ele => {
      totalActualUnits += ele.regUnits;
      totalDailyUnits += ele.projectedUnits ;
      totalActualSales += ele.regAmt ;
      totalDailySales += ele.projectedAmt ;
      totalActualGp += ele.gpAmt;
      totalDailyGp += ele.gpProjectedAmt
 
    });

    let monthly = {
      title: 'Monthly Differences',
      overallUnits: totalDailyUnits * 30,
      overallSales: totalDailySales * 30,
      overallGP: totalDailyGp*30,
      actualUnits: totalActualUnits * 30 / this.pluLength,
      actualSales: totalActualSales* 30 / this.pluLength,
      actualGp: totalActualGp* 30 / this.pluLength,
      unitsDiff:0,
      salesDiff:0,
      gpDiff:0
    }
    monthly.unitsDiff = monthly.actualUnits -  monthly.overallUnits ;
    monthly.salesDiff = monthly.actualSales -monthly.overallSales ;
    monthly.gpDiff =  monthly.actualGp - monthly.overallGP ;
    this.monthCardDetails=monthly;

    let annual = {
      title: 'Annual Differences',
      overallUnits: totalDailyUnits * 365,
      overallSales: totalDailySales * 365,
      overallGP: totalDailyGp*365,
      actualUnits: totalActualUnits * 365 / this.pluLength,
      actualSales: totalActualSales* 365 / this.pluLength,
      actualGp: totalActualGp* 365 / this.pluLength,
      unitsDiff:0,
      salesDiff:0,
      gpDiff:0
    }
    annual.unitsDiff =  annual.actualUnits - annual.overallUnits ;
    annual.salesDiff =  annual.actualSales - annual.overallSales;
    annual.gpDiff =  annual.actualGp -annual.overallGP ;
    this.yearCardDetails =annual;

  }

  periodIsBeforeCurrentDate(p: string) {
    let period = p.replace('Days', '');
    period = period.replace(' ', '');
    let dateStr = this.impactDate;
    if (this.impactDate instanceof Object) {
      dateStr = `${this.impactDate['year']}-${this.impactDate['month']}-${this.impactDate['day']}`;
    }
    const day = dayjs(dateStr).add(+period, 'day');
    if (day.isBefore(dayjs())) {
      return true;
    }
    return false;
  }

  resetCard(){
    this.monthCardDetails= {
      title: 'Monthly Differences',
      overallUnits: undefined,
      overallSales: undefined,
      overallGP: undefined,
      actualUnits:undefined,
      actualSales: undefined,
      actualGp: undefined,
      unitsDiff:undefined,
      salesDiff:undefined,
      gpDiff:undefined
    };
    this.yearCardDetails = {
      title: 'Annual Differences',
      overallUnits: undefined,
      overallSales: undefined,
      overallGP: undefined,
      actualUnits: undefined,
      actualSales: undefined,
      actualGp: undefined,
      unitsDiff: undefined,
      salesDiff: undefined,
      gpDiff: undefined
    };
  }

  priceChangeDate(e: any) {
    this.resetCard();
    this.timePeriodSelected=false;
    this.impactPeriodValue = undefined;
    this.menuItems = [];

    this.moverallSales = 0;
    this.moverallUnits = 0;
    this.moverallGP = 0;

    this.yoverallSales = 0;
    this.yoverallUnits = 0;
    this.yoverallGP = 0;

    this.dummyItems = [];
    this.dummyItems = data.menuItemsPriceImpact.filter(el => {
      return el.resNum == 1;
    })

    this.conceptPluService.restaurantplupricemodels(this.impactModelSelected, this.selectedSubGroup).subscribe({
      next: resp => {
        let diff7 = dayjs(this.impactDate).add(+7, 'day');
        if (diff7.isBefore(resp[0]?.createdOn)) {
          this.impactPeriodValue = "b7da3bfa-8b70-4d65-8f00-092946331188";
          this.refreshImpact();
        }
      }
    })
  }

  getImpactPeriodValueNum(period) {
    var num;
    switch (this.impactPeriodValue) {
      case 'b7da3bfa-8b70-4d65-8f00-092946331188':
        num = 7;
        break;
      case '9551f8cd-8918-4a92-8cbc-99dc2cf6e9eb':
        num = 30;
        break;
      case '56eacbda-488f-4de2-b1a8-ef8eff6a9bf7':
        num = 90;
        break;
    }
    return num;
  }

  dateRangeMenu(event) {
    // let endDate = dayjs(event.split('-')[1].replace(' ','')).format();
    // console.log(endDate)
    // console.log(this.menuItems)
    // if(this.menuItems?.length>0){
    //   let v=dayjs(this.menuItems[0].createdOn).diff(endDate, 'days');
    //   console.log(v);
    // }

  }
  salesObj = {
    gpAmt: 0,
    projectedAmt: 0,
    cogsProjected: 0,
    gpProjectedAmt: 0,
    actualDailyUnits: 0,
    actualDailySales: 0,
    cosof1ProjectedUnit: 0,
    dailyUnits: 0,
    dailySales: 0,
    noNormUnits: 0
  }
  getSearchText(num: string) {
    num = num.replace(/\,/g,'');
    if (this.searchText?.length == 0) {
      return num;
    } else {
      const str = num + '';
      var regex = new RegExp(this.searchText, "gi");
      return str.replace(regex, function (matched) { return "<strong>" + matched + "</strong>"; })
    }
  };

  clearSearchText() {
    setTimeout(() => {
      this.searchText = "";
      this.groupSearchText = "";
    }, 500);
  }

  resNum: any;
  resGrp: any;
  prevRestGrp: any;
  canChange: boolean = true;
  refreshRestaurantNumModeling(groupValue) {
    this.timePeriodSelected=false;
    this.resetCard();
    this.changeGroup = true;
    this.resGrp = groupValue;
    this.availableModelingRestaurant=[];
    this.changeRestaurant.forEach((x: any) => {
      if (x.groupName == groupValue) {
        this.availableModelingRestaurant.push(x);
      }
    });
    if (this.canChange) {
      this.selectedRestaurant = (this.availableModelingRestaurant[0].restaurantNum);
      // this.ChangeRestaurantNum(this.selectedRestaurant);
     this.OnRestaurantGroup.emit(groupValue);
    }
    else {
      setTimeout(() => {
        this.selectedRestaurantGroup = this.prevRestGrp;
      }, 100);
      this.ngbmodalService.open(this.mymodal)
    }
  }
  getGroupSearchText(text: string) {
    if (this.groupSearchText?.length == 0) {
      return text;
    } else {
      const str = text + '';
      var regex = new RegExp(this.groupSearchText, "gi");
      return str.replace(regex, function (matched) { return "<strong>" + matched + "</strong>"; })
    }
  }
  ChangeRestaurantNum(value) {
    this.timePeriodSelected=false;
    this.resetCard();
    this.OnRestaurantNum.emit(value);
    this.selectedRestaurant = value;
  }
}
