import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SharedEventService } from 'src/app/services/shared-event.service';
import { ModelService } from 'src/app/services/model.service';

@Component({
  selector: 'app-save-model-modellingtool',
  templateUrl: './save-model-modellingtool.component.html',
  styleUrls: ['./save-model-modellingtool.component.scss']
})
export class SaveModelModellingtoolComponent implements OnInit {
  
  private saveModalRef: NgbModalRef | undefined;
  @Input() selectedModelingRes: any;
  @Input() modalPriceToSave: any;
  @ViewChild('saveModal') savelModel: ElementRef;
  showSavedModel = false;
  notesLength = 0;
  modelVersion = "v1";
  modelName: string = null;
  savedDate = new Date();
  note:string ='';
  isLoading = false;
  timeperiods = [/*7 Days*/'b7da3bfa-8b70-4d65-8f00-092946331188', /*30 Days*/ '9551f8cd-8918-4a92-8cbc-99dc2cf6e9eb', /*90 Days*/ '56eacbda-488f-4de2-b1a8-ef8eff6a9bf7'];

  constructor(private modalService: NgbModal, private sharedService : SharedEventService, private decimalPipe: DecimalPipe, private modelService: ModelService) { }

  ngOnInit(): void {
    this.sharedService.savedModelVersion.emit({
     version: this.modelVersion, name : this.modelName
    });
  }

  openModal(){
    this.showSavedModel=false;
    this.saveModalRef=this.modalService.open(this.savelModel, { windowClass: 'saveModel' });
  }

  saveModel() {
    this.isLoading = true;
    const modelWithAllPeriods = [];
    this.modelName = `${(this.decimalPipe.transform(this.selectedModelingRes, '7.0-0')).toString().replace(/\,/g,'') }-${new DatePipe('en-US').transform(this.savedDate, 'M.dd.yy')}-${this.modelVersion}`;
    for(var i=0; i < this.timeperiods.length; i++) {
    this.modalPriceToSave.forEach(element => {
      element.name = this.modelName;
      element.notes = this.note;
      element.storeId = this.selectedModelingRes;
      element.timeperiodId = this.timeperiods[i];
      modelWithAllPeriods.push(JSON.parse(JSON.stringify(element)));
    });
  }
    this.modelService.saveModel(modelWithAllPeriods).subscribe(res=>{
      this.showSavedModel = true;
      this.note='';   
      this.sharedService.savedModelVersion.emit({
        version: this.modelVersion, 
        name: this.modelName
      });
      this.sharedService.canChange.emit(true);
      this.sharedService.isSaved.emit(true);
      this.isLoading = false;
    })
  }

  CharCount(value) {
    this.notesLength = value.length;
    this.note = value;
  }
  removeComma(num:string){
    return num = num.replace(/\,/g,'');
  }
  openManageModels(){
    this.sharedService.openManageModelModalEvent.emit();
  }
}
