import { Directive } from '@angular/core';

@Directive({
  selector: '[appRestrictDecimalInput]'
})
export class RestrictDecimalInputDirective {

  constructor() { }

}
