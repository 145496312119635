import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing-data',
  templateUrl: './landing-data.component.html',
  styleUrls: ['./landing-data.component.scss']
})
export default class LandingDataComponent implements OnChanges{

  @Input()
  checkedCompetitors: boolean = true;
  @Input()
  competitors: any[] = [];
  @Input()
  pricingInput = {};
  @Input()
  selectedCategoryId = '';
  @Input()
  selectedConceptId = '';
  @Input()
  user: any;
  @Input()
  ServiceMode: string;
  @Input()
  scrollY: number = 0;
  @Input()
  subCategory: string = '';
  @Input()
  availableSoldTypes: any;
  @Input()
  restaurants: any[];
  @Input()
  hasCompetitor: false;

  mcDonaldsId: any;
  static HttpClientTestingModule: any;
  
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if(environment?.isTestEnvironment) {
      for(var i =0; i < this.competitors?.length;i++) {
        const regex =  new RegExp("[a-zA-Z0-9.]","g");
        this.competitors[i].addr1=this.competitors[i].addr1.replace(regex, '*');
        this.competitors[i].cityName=this.competitors[i].cityName.replace(regex, '*');
        this.competitors[i].stateCd=this.competitors[i].stateCd.replace(regex, '*');
        this.competitors[i].zipCd=this.competitors[i].zipCd.replace(regex, '*');
      }
     }  
     this.mcDonaldsId = this.competitors.filter(x=>x.competitorName == 'McDonalds')[0]?.competitorId;
  }

  ngOnInit(): void {
  }

}