<ng-template #content let-modal >
    <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" *ngIf="!isSubAccount">Please review and accept our terms of use</h4>
            <h4 class="modal-title" id="modal-basic-title" *ngIf="isSubAccount">Member Consent Required</h4>
    </div>
    <div class="modal-body">
        <div [innerHTML]="componentData"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="agree()" *ngIf="!isSubAccount">Agree & Continue</button>
    </div>
</ng-template>