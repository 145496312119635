import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SharedEventService } from 'src/app/services/shared-event.service';

@Component({
  selector: 'app-tab-view',
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss']
})
export class TabViewComponent implements OnInit {
  tabs:any[]=[];

  constructor(private sharedEventService:SharedEventService, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.sharedEventService.tabChange.subscribe(res=>{
      this.tabs.forEach(tab=>{
        if(tab.title == res){
          tab.selected = true;
        }
        else{
          tab.selected = false;
        }
      })
    })
    this.tabs=[
      {
        selected:true,
        id:'newest_price',
        title:'Newest Price'
      },
      {
        selected:false,
        id:'change_in_price_usd',
        title:'Change in Price (USD)'
      },
      {
        selected:false,
        id:'change_in_price_percent',
        title:'Change in Price (%)'
      }
    ]
  }

  
  selectTab(tab:any){

     this.tabs.forEach(ele=>{
      if(ele.id===tab.id) {
        ele.selected=true;
      }else {
        ele.selected=false;
      }
    });
    this.sharedEventService.tabChange.emit(tab.title);
    this.ref.detectChanges();
  }
}
