import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms'
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  FormData: FormGroup;
  showConfirmation: boolean = false;
  isSubmitting: boolean = false;
  submittedEmail: string = '';
  errorMessage: string = '';
  submissionTime: Date | null = null;
  file1: File = null;
  file2: File = null;
  file3: File = null;

  constructor(private builder: FormBuilder, private http: HttpClient) { }

  ngOnInit() {
    this.FormData = this.builder.group({
      Fullname: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      Phone: new FormControl('', [Validators.required]),
      File1: new FormControl(null, [Validators.required]),
      File2: new FormControl(null, [Validators.required]),
      File3: new FormControl(null, [Validators.required]),
    });
  }

  onFilechange(event: any, fileNum) {
    if(fileNum == 1) {
    console.log(event.target.files[0])
    this.file1 = event.target.files[0]
    }
    if(fileNum == 2) {
      console.log(event.target.files[0])
      this.file2 = event.target.files[0]
    }
    if(fileNum == 3) {
      console.log(event.target.files[0])
      this.file3 = event.target.files[0]
    }
  }


  onSubmit() {
    this.submittedEmail = this.FormData.get('Email').value;
    this.isSubmitting = true;
    const formData = new FormData();
    formData.append('name', this.FormData.get('Fullname').value);
    formData.append('email', this.FormData.get('Email').value);
    formData.append('phone', this.FormData.get('Phone').value);
    formData.append('file1', this.file1);
    formData.append('file2', this.file2);
    formData.append('file3', this.file3);

    this.submissionTime = new Date(); // Capture the time of submission
    
    this.http.post(`${environment.restaurantUrl}/api/submissions/UploadSubmission`, formData).subscribe(
      response => {
        console.log('Upload successful!');
        this.showConfirmation = true; // Show confirmation on success
        this.isSubmitting = false; // Reset submission flag
      },
      error => {
        console.error('Upload failed!', error);
        this.errorMessage = 'Error submitting form: please email attachments to janeikirk@rsilink.com';
        this.isSubmitting = false; // Reset submission flag on failure
      }
    );
  }
}
