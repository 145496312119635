<div *ngIf="hasCompetitor!== undefined && !hasCompetitor" class="error">* No Competitor data exist for restaurant &nbsp;<strong>#{{competitors[0]?.num}}</strong></div>
<div class="row mt-3">
    <table>
        <tbody class="competitors-row">
            <tr>
                <td *ngFor="let comp of competitors  | slice:0:5; let i=index">
                    <app-competitor-card [competitor]="comp"></app-competitor-card>
                </td>
                <td *ngFor="let data of [].constructor(5-competitors.length)">
                    <app-dummy-competitor-card></app-dummy-competitor-card>
                </td>
            </tr>
        </tbody>
        <tbody class="box pricing-cells" pricing-table [pricingInput]="pricingInput" [selectedCategoryId]="selectedCategoryId"
            [selectedConceptId]="selectedConceptId" [user]="user" [ServiceMode]="ServiceMode" [subCategory]="subCategory"
            [restaurants]="competitors" [mcDonaldsId]="mcDonaldsId" [scrollY]="scrollY" [availableSoldTypes]="availableSoldTypes" style="min-height: 200px; position: relative;">
        </tbody>
        
    </table>
</div>