import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dummy-competitor-card',
  templateUrl: './dummy-competitor-card.component.html',
  styleUrls: ['./dummy-competitor-card.component.scss']
})
export class DummyCompetitorCardComponent implements OnInit {
  assetUrl:String;
  constructor() { }

  ngOnInit(): void {
    this.assetUrl = environment.assetUrl;
  }

}
