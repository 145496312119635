import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedEventService {
    tabChange = new EventEmitter<any>();
    // token = new EventEmitter<any>();
    canChange = new EventEmitter<any>();
    isSaved = new EventEmitter<any>();
    savedModelVersion = new EventEmitter<any>();
    refreshUserSettings = new EventEmitter<any>();
    categoryList = new EventEmitter<any>();
    openManageModelModalEvent = new EventEmitter<any>();
    openExportModalEvent = new EventEmitter<any>();
    downloadModelEvent = new EventEmitter<any>();
    constructor(){
      this.canChange.emit(true);
    }

}