<div *ngIf="acceptedId !== null; else numberIsZero">
<div *ngIf="!editing; else editMode" style="display: flex; align-items: center; margin-top: 10px;">
    <img src="assets/1db.png" style="width: 25px; height: 30px; margin-right: 10px; margin-bottom: 5px;">
    <h3 style="margin-right: 10px;"><span>{{ text }}</span></h3>
    <div ngbDropdown class="btn-group drop">
        <button class="btn adjustbtn" ngbDropdownToggle style="width: auto; height: auto; padding: 0; border: none; background: transparent; margin-left: 10px;"></button>
        <div ngbDropdownMenu class="dropdown-menu">
            <button ngbDropdownItem (click)="enableEditing()">Rename Group</button>
            <button ngbDropdownItem (click)="deleteTier()">Delete Group</button>
        </div>
    </div>
</div>
</div>

<!-- Template displayed if the number is zero -->
<ng-template #numberIsZero>
    <div style="display: flex; align-items: center; margin-top: 0px;">
        <h3 style="margin-right: 10px;"><span>{{ text }}</span></h3>
        <div ngbDropdown class="btn-group drop">
            <button class="btn adjustbtn" ngbDropdownToggle style="width: auto; height: auto; padding: 0; border: none; background: transparent; margin-left: 10px;"></button>
            <div ngbDropdownMenu class="dropdown-menu">
                <button ngbDropdownItem (click)="selectAll()">Select All</button>
                <button ngbDropdownItem (click)="deselectAll()">Deselect All</button>
            </div>
        </div>
    </div>
</ng-template>
  
<ng-template #editMode>
    <mat-form-field appearance="fill">
        <mat-label>Press Enter to Save</mat-label>
        <input matInput [(ngModel)]="editText">
    </mat-form-field>
</ng-template>
