<ng-template #saveModal let-modal>
<div class="modal-header row">
    <div class="d-flex justify-content-center col-11">
        <span class="modal-title" id="modal-basic-title" *ngIf="!showSavedModel"><i class="bi bi-bookmark"
                style="margin-right: 5px;"></i> Save Model</span>
        <span class="modal-title" id="modal-basic-title" *ngIf="showSavedModel"><i class="bi bi-bookmark"
                style="margin-right: 5px;"></i>Model Saved</span>
    </div>
    <button type="button" class="btn-close col-1" aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
</div>

<div *ngIf="!showSavedModel" class="modal-body" style="padding: 20px 20px">
    <div class="mat-loading" *ngIf="isLoading">
    <div>
        <div class="d-flex justify-content-center align-items-center">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>
<div *ngIf="!isLoading">
    <div style="margin-bottom:20px">
        Please Enter Version for the model
        <input class="version_box" [(ngModel)]="modelVersion" style="text-align:center; margin-left:5px"
            type="text">
    </div>
    <div>
        <p style="margin-bottom: 0px;">Your model will be saved in the following format:</p>
        <strong>Restaurant#-Date-version</strong>
    </div>
    <div>
        <div style="margin-top: 20px;">
            <div class="notesLabel">
                <label>Notes</label>
                <label>{{notesLength}}/40 Characters</label>
            </div>
            <input class="notes" type="text" maxlength="40" #notes (keyup)="CharCount(notes.value)">
        </div>
    </div>
</div>
</div>
<div *ngIf="showSavedModel" class="modal-body" style="padding: 20px 20px">
    <p>Model <strong>{{removeComma(selectedModelingRes | number: '7.0-0') }}-{{savedDate | date:
            'M.d.yy'}}-{{modelVersion}}</strong> saved.</p>
    <p>You can access this saved model and modify it anytime in <a style="color:#3D73D1; cursor:pointer" (click)="openManageModels(); modal.dismiss('Cross click')">Manage
            Models Window</a></p>
</div>
<div *ngIf="!showSavedModel" class="modal-footer">
    <button class="btn cancel-btn" style="padding:initial" (click)="modal.dismiss('Cross click')">Cancel</button>
    <button type="button" style="padding:initial" (click)="saveModel()" class="btn set-btn">Save</button>
</div>
<div *ngIf="showSavedModel" class="modal-footer">
    <button class="btn set-btn" style="padding:15px" (click)="modal.dismiss('Cross click')">Close</button>
</div>
</ng-template>
