import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'rmp';

  constructor(public router: Router,) {    
    window.addEventListener("message",  function(event) {
     
      if(event.data && event.data.token) {
        console.log('post got token');
       (window as any).userTK = event.data.token;    
      router.navigate(['']);
      }
    },true);
  }  
}
