import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { forkJoin } from 'rxjs';
import { MenuItemPriceService } from 'src/app/services/menu-item-price.service';
import { RestaurantService } from 'src/app/services/restaurant.service';
import { SharedEventService } from 'src/app/services/shared-event.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bk-bentchmark-pricing-table',
  templateUrl: './bk-bentchmark-pricing-table.component.html',
  styleUrls: ['./bk-bentchmark-pricing-table.component.scss']
})
export class BkBentchmarkPricingTableComponent implements OnInit, OnChanges {

  @Input() currentConceptRestaurant;
  @Input() currentCompetitorRestaurant;
  @Input() selectedRestaurantNumber:string;
  @Input() selectedCategoryId: string;
  @Input() selectedConceptId: string;
  @Input() ShowDma;
  @Input() benchMarkToShow;
  @Input() benchMarkToHide;
  @Input() availableBenchMark;
  @Input() compId;
  @Input() subCategory;
  @Input() currentRes;
  @Input() availableSoldTypes;
  currentRestaurant
  @Input()
  ServiceMode: string ='';
  assetUrl;
  header: string = "";
  isLoading:boolean = false;
  tableData: any;
  copyRes: any;
  availableBentchMarkCount;
  result;
  response=[];
  selectedSoldTypeId=[];
  constructor(private sharedEventService: SharedEventService,private menuItemPriceService: MenuItemPriceService,private reataurantService: RestaurantService) { }

  ngOnInit(): void {
    this.assetUrl = environment.assetUrl;
}

  ngOnChanges(changes: SimpleChanges): void {   
    if(changes['availableBenchMark'] && !changes['currentConceptRestaurant']){
      this.formatPricingTable();
    }
    else if((changes['subCategory'] || changes['selectedConceptId'] || changes['ServiceMode'])&& !changes['selectedRestaurantNumber'] ||changes['selectedRestaurantNumber'] || changes['ShowDma'] ||changes['availableSoldTypes']){
      this.isLoading=true;
      this.refreshPricingTable();
    }
  }

  refreshPricingTable(){
    this.selectedSoldTypeId=[];
    this.availableSoldTypes.forEach(element => {
      if(element.isSelected){
        this.selectedSoldTypeId.push(element.id)
      }
    });
    if(this.selectedSoldTypeId?.length!=1){
      this.selectedSoldTypeId = null;
    }
     let pricingSources = [this.menuItemPriceService.getMenuItemPricePlu(this.currentCompetitorRestaurant.id,this.compId,this.subCategory,this.ServiceMode,this.selectedSoldTypeId?this.selectedSoldTypeId[0]:null)]
    pricingSources.push(this.menuItemPriceService.GetCompetitorPluPriceForBenchMark(this.selectedRestaurantNumber,this.compId,this.subCategory,this.ServiceMode,this.selectedSoldTypeId?this.selectedSoldTypeId[0]:null))
    // pricingSources.push(this.menuItemPriceService.GetCompetitorPluPriceForNational(this.selectedRestaurantNumber,this.compId,this.subCategory,this.ServiceMode,this.selectedSoldTypeId?this.selectedSoldTypeId[0]:null))

    if(this.result){
      this.result.unsubscribe();
    }
    this.result=forkJoin(pricingSources).subscribe((res :any[]) =>{  
      this.response[0] = res[0];
      this.response[1] = res[1][0];     
      this.response[2] = res[1][1];
      this.copyRes = this.response;
      this.formatPricingTable();
      this.isLoading = false;
   })
  }

  formatPricingTable (){
    let data = JSON.parse(JSON.stringify(this.copyRes));
    data.forEach(el => {
      el = el.sort((a, b) => {
        return a.groupSortId - b.groupSortId || a.subgroupSortId - b.subgroupSortId || a.sortId - b.sortId;
      })
    });
    this.availableBentchMarkCount = data.length;
    this.availableBenchMark.forEach(el =>{ 
      if(!el.isShow){
        let index = data.findIndex(el1=>{
          return el1[0].benchMarkCardName == el.benchMarkCardName;
        })
        data.splice(index,1)
      }     
    })
    this.tableData = new Array(data[0].length).fill(false).map(()=> new Array(data.length).fill(''))

    for(var i=0; i < data[0].length;i++){
      this.tableData[i][0] = data[0][i];
    }
    
    
    for(var i=1 ; i< data.length;i++){
      const orderedArray = this.getRowPositions(data[0],data[i]);
      for(let j=0;j< orderedArray.length;j++){
        this.tableData[j][i]= orderedArray[j];
      }
    }    
    

  }

  getRowPositions(master, curCol){
    const pos=[];

    for(var i=0;i< master.length;i++){
      const item = master[i];
      const idx = curCol.findIndex(e=> e.name == item.name);
      if(idx >=0){
        pos.push(curCol[idx]);
      }else{
        pos.push({noItemPrice:true})
      }
    }
    return pos;
    
  }
}

