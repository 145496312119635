import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-competitor-dropdown',
  templateUrl: './competitor-dropdown.component.html',
  styleUrls: ['./competitor-dropdown.component.scss']
})
export class CompetitorDropdownComponent implements OnInit, OnChanges {

  constructor() { }
  @Input() selectedMCD: object[] = [];
  @Input() selectedWendy: object[] = [];
  @Input() selectedOthers: object[] = [];
  selectedMCDL: object[] = [];
  selectedWendyL: object[] = [];
  selectedOthersL: object[] = [];
  selectedCompetitors: object[] = [];
  @Input() nearMcdCompetitors: any[] = [];
  @Input() nearWendyCompetitors: any[] = [];
  @Input() nearOtherCompetitors: any[] = [];
  assetUrl: string;
  @Output() RestaurantCompititors = new EventEmitter<object[]>();
  @Input() restaurantNum: number;
  allCompetitors: any[] = [];

  ngOnInit(): void {
    this.assetUrl = environment.assetUrl
  }

  ngOnChanges(changes: SimpleChanges) {
    /// detect any change in competitors when restaurant group or restaurant num change
    setTimeout(() => {

      if ((changes['selectedMCD'] || changes['selectedWendy'] || changes['selectedOthers']) || (changes['nearMcdCompetitors'] || changes['nearWendyCompetitors'] || changes['nearOtherCompetitors'])) {
        this.selectedCompetitors = [];
        this.allCompetitors = [];
        // push what every selected MCD, Wendy, and Other Competitors
        this.allCompetitors = [...changes['selectedMCD']?.currentValue, ...changes['selectedWendy']?.currentValue, ...changes['selectedOthers']?.currentValue];
        // arrange it on the basis of distance
        this.allCompetitors.sort((a, b) => a['distance'] - b['distance']);
        // push only two competitors
        for (let i = 0; i < 2; i++) {
          if (this.allCompetitors[i] != undefined) {
            this.selectedCompetitors.push(this.allCompetitors[i]);
          }
        }
        // empty the local selected MCD, wendy and other competitor
        this.selectedMCDL = []
        this.selectedWendyL = []
        this.selectedOthersL = []
        // push to the respective competitors
        // if nothing is selected from the market intelligence
        if (this.selectedCompetitors.length != 0) {
          this.selectedCompetitors.map((el) => {
            switch (el['competitorName']) {
              case 'McDonalds':
                this.selectedMCDL.push(el);
                break;
              case 'Wendys':
                this.selectedWendyL.push(el);
                break;
              default:
                this.selectedOthersL.push(el);
                break;
            }
            this.RestaurantCompititors.emit(this.selectedCompetitors)
          })
        }
        else {
          this.allCompetitors = [];
          this.allCompetitors = [...changes['nearMcdCompetitors']?.currentValue, ...changes['nearWendyCompetitors']?.currentValue, ...changes['nearOtherCompetitors']?.currentValue]
          this.allCompetitors.sort((a, b) => a['distance'] - b['distance']);
          // push only two competitors
          if(this.allCompetitors.length>0){

            for (let i = 0; i < 2; i++) {
              if (this.allCompetitors[i] != undefined) {
                this.selectedCompetitors.push(this.allCompetitors[i]);
              }
            }
            this.selectedCompetitors.map((el) => {
              switch (el['competitorName']) {
                case 'McDonalds':
                  this.selectedMCDL.push(el);
                  break;
                case 'Wendys':
                  this.selectedWendyL.push(el);
                  break;
                default:
                  this.selectedOthersL.push(el);
                  break;
              }
              this.RestaurantCompititors.emit(this.selectedCompetitors)
            })
          }

        }
      }


    }, 100);
  }

  changeCompititors() {
    // push the local mcd, wendy and other into selected
    this.selectedCompetitors = [...this.selectedMCDL, ...this.selectedWendyL, ...this.selectedOthersL];
    // deleteing if undefined the present in the seleteced competitors
    if (this.selectedCompetitors.length != 0) {
      for (let i = 0; i < this.selectedCompetitors.length; i++) {
        if (this.selectedCompetitors[i] == undefined) {
          this.selectedCompetitors.splice(i, 1);
          i--;
        }
      }
    } else {
      // If user unselected all the competitors then we selected the nearest competitors
      this.allCompetitors = [...this.nearMcdCompetitors, ...this.nearWendyCompetitors, ...this.nearOtherCompetitors];
      this.allCompetitors.sort((a, b) => a['distance'] - b['distance']);
      this.selectedMCDL = []
      this.selectedWendyL = []
      this.selectedOthersL = []
      // push only two competitors
      for (let i = 0; i < 2; i++) {
        if (this.allCompetitors[i] != undefined) {
          this.selectedCompetitors.push(this.allCompetitors[i]);
        }
      }
      this.selectedCompetitors.map((el) => {
        switch (el['competitorName']) {
          case 'McDonalds':
            this.selectedMCDL.push(el);
            break;
          case 'Wendys':
            this.selectedWendyL.push(el);
            break;
          default:
            this.selectedOthersL.push(el);
            break;
        }
      })
    }
    this.RestaurantCompititors.emit(this.selectedCompetitors);
  }

  check(data: object) {
    if (this.selectedCompetitors.includes(data)) {
      return false;
    } else {
      return true;
    }
  }

  getIcon(brand: string) {
    if (brand == 'Jack In A Box') {
      return this.assetUrl + "/assets/JackInTheBox Logo@2x.png";
    }
    if (brand == 'Sonic') {
      return this.assetUrl + "/assets/sonic-logo.png"
    }
    if (brand == 'Whataburger') {
      return this.assetUrl + "/assets/Whataburger_logo.png"
    }
    if (brand == 'Taco Bell') {
      return this.assetUrl + "/assets/taco-bell.png"
    }
    if (brand == 'In N Out') {
      return this.assetUrl + "/assets/In-N-Out-Burger.webp"
    }
    if (brand == 'Hardees') {
      return this.assetUrl + "/assets/hardees.webp"
    }
    if (brand == 'Carl\'s Jr.') {
      return this.assetUrl + "/assets/carlsjr_logo.png"
    }
    return "";
  }

  getOtherCompetitorName(group: any) {
    if (group instanceof Array) {
      return group[0].competitorName;
    } else {
      return group.competitorName;
    }
  }

  isObjArray(group: any) {
    return group instanceof Array;
  }
}
