import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  showMessage: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.checkMsg();
  }
  async checkMsg() {
    await this.delay(100);
    this.showMessage = true;
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
