import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Pipe({
  name: 'restaurantFilter',
  pure: false
})
@Injectable()
export class RestaurantSearchPipe implements PipeTransform {

    transform(items: any[], term: string): any {
        console.log
        // I am unsure what id is here. did you mean title?
        return items.filter(item => item.restaurantNum.toString().indexOf(term) !== -1);
    }
}
@Pipe({
    name: 'callback',
    pure: false
  })
  @Injectable()
  export class CallbackPipe implements PipeTransform {
  
      transform(items: any[],form:FormGroup, callback: (form, item: any) => any[]): any {
          if (!items || !callback) {
              return items;
          }
          return callback(form, items);
      }
  }

@Pipe({
    name: 'groupFilter',
    pure: false
  })
  @Injectable()
  export class RestaurantGroupSearchPipe implements PipeTransform {
  
      transform(items: any[], term: string): any {
          // I am unsure what id is here. did you mean title?
          return items.filter(item => item.groupName.toLowerCase().toString().indexOf(term.toLowerCase()) !== -1);
      }
  }