import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "src/environments/environment";

interface RestaurantItem {
  selected: boolean;
  parentRuNum: number;
  ruNum: number;
  groupId: string;
  groupName: string;
  restaurantNum: number;
  conceptId: string;
}

interface TierItem {
  name: string;
  tierId: string;
}

@Injectable({
  providedIn: 'root'
})

export class TierService {

  apiRoot: string = `${environment.restaurantUrl}/api/`;

  constructor(private http: HttpClient) {}

  //get all RUs API call
  //create two tier list by default

  restaurants: RestaurantItem[] = [];

  tiers: TierItem[] = [];

  selectedTier: TierItem = this.tiers[0];
  private nextTierId = 0;
  private currentSetupPage = 0;
  private fileUploaded = false;

  getRestaurantsForUser() {
    let result = this.http.get(`${environment.restaurantUrl}/api/restaurantrus/GetRestaurantRusForUserIdGrouped`);
    return result;
  }

  sortTiersByName(): void {
    this.tiers.sort((a, b) => a.name.localeCompare(b.name));
  }

  addTier() {
    this.nextTierId = this.getTiers().length + 1;
    this.createPriceGroup({ name: `Group ${this.nextTierId}`, ruNo: this.restaurants[0].ruNum }).subscribe(
      (response) => {
        const newTier = { name: response['name'], tierId: response['id'] };
        this.tiers.push(newTier);
      }
    );
  }

  getUnassignedGroupId() {
    return this.tiers.find(tier => tier.name === 'Unassigned').tierId;
  }

  getUnassingedTierNumbers() {
    return this.restaurants.find(restaurant => restaurant.groupId === null).restaurantNum;
  }

  createPriceGroup(priceGroupData: { name: string, ruNo: number }): Observable<any> {
    return this.http.post(`${environment.restaurantUrl}/api/pricegroups`, priceGroupData);
  }

  getRestaurants() {
    return this.restaurants;
  }

  getUnassignedRestaurants() {
    return this.restaurants.filter(restaurant => restaurant.groupName === null);
  }

  selectTier(tierId: string) {
    this.selectedTier = this.tiers.find(tier => tier.tierId === tierId);
  }

  selectAll(tierId: string) {
    this.restaurants.filter(restaurant => restaurant.groupName === tierId).forEach(restaurant => restaurant.selected = true);
  }

  deselectAll(tierId: string) {
    this.restaurants.filter(restaurant => restaurant.groupName === tierId).forEach(restaurant => restaurant.selected = false);
  }

  getSelectedTier() {
    return this.selectedTier;
  }

  deleteTier(tierId: string) {
    this.tiers = this.tiers.filter(tier => tier.tierId !== tierId);
    this.restaurants.filter(restaurant => restaurant.groupId === tierId).forEach(restaurant => restaurant.groupName = null);
    this.removePriceGroup({ id: tierId }).subscribe();
  }

  removePriceGroup(priceGroupData: { id: string }): Observable<any> {
    return this.http.request('delete', `${environment.restaurantUrl}/api/pricegroups/remove`, { body: priceGroupData });
  }

  setSetupPage(page: number) {
    this.currentSetupPage = page;
  }

  getSetupPage() {
    return this.currentSetupPage;
  }

  renameTier(tierId: string, newName: string) {
    if (this.tiers.find(tier => tier.tierId === tierId)) {
      this.tiers.find(tier => tier.tierId === tierId).name = newName;
      this.patchPriceGroup({ id: tierId, name: newName }).subscribe();
    }
  }

  patchPriceGroup(priceGroupData: { id: string, name: string }): Observable<any> {
    return this.http.patch(`${environment.restaurantUrl}/api/pricegroups/rename`, priceGroupData);
  }

  checkUnassignedEmpty() {
    if (this.restaurants.filter(restaurant => restaurant.groupId === '0').length === 0) {
      return true;
    } else {
      return false;
    }
  }

  getTierNumbers(tierId: string) {
      const foundRestaurants = this.restaurants.filter(restaurant => restaurant.groupId === tierId);
      return foundRestaurants;
  }

  getTiers() {
    return this.tiers;
  }

  getTierIDs() {
    return this.tiers.map(tier => tier.tierId);
  }

  getTierName(tierID: string) {
    return this.tiers.find(tier => tier.tierId === tierID).name;
  }

  assignToTier(tierId: string) {
      this.restaurants.filter(restaurant => restaurant.selected === true).forEach(restaurant => restaurant.groupId = tierId);
      this.restaurants.filter(restaurant => restaurant.selected === true).forEach(restaurant => restaurant.groupName = this.getTierName(tierId));
      this.restaurants.filter(restaurant => restaurant.selected === true).forEach(restaurant =>
        this.createPriceGroupRestaurant({ priceGroupId: tierId, conceptRestaurantId: restaurant.conceptId }).subscribe());
      this.restaurants.filter(restaurant => restaurant.selected === true).forEach(restaurant => restaurant.selected = false);
  }

  createPriceGroupRestaurant(priceGroupRestaurantData: { priceGroupId: string, conceptRestaurantId: string }): Observable<any> {
    return this.http.post(`${environment.restaurantUrl}/api/pricegrouprestaurants`, priceGroupRestaurantData);
  }

  toggleSelection(ru: number) {
    this.restaurants.find(restaurant => restaurant.restaurantNum === ru).selected = !this.restaurants.find(restaurant => restaurant.restaurantNum === ru).selected;
    }

  setFileUploaded(uploaded: boolean) {
      this.fileUploaded = uploaded;
    }
  
  checkFileUploaded(): boolean {
      return this.fileUploaded;
    }
  }
