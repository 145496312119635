import { Component } from '@angular/core';
import { TierService } from 'src/app/services/tier.service';

@Component({
  selector: 'app-slide-show',
  templateUrl: './slide-show.component.html',
  styleUrls: ['./slide-show.component.css'],
})
export class SlideShowComponent {
  // Index of the current slide

  TierService: TierService;

  constructor(TierService: TierService) {
    this.TierService = TierService;
  }

  // Array of slides (For simplicity, we're using strings here. This could be an array of objects for more complex slides.)
  slides: string[] = [
    'Please upload your current pricing file, availability file, and restaurant grouping file from SICOM. Enter the email address you would like us to reach out to and we will send you a report of pricing discrepencies. ',
    'Review the following exceptions we found in your pricing file. After your are finished, you can export the changes and upload to SICOM.',
    'For each group, please upload your current group pricing file and a product availability file.',
    // Add more slides as needed
  ];

  // Function to go to the next slide
  nextSlide(): void { 
    if (this.TierService.getSetupPage() < this.slides.length - 1) {
      this.setSetupPage(this.TierService.getSetupPage() + 1);
    } else {
      this.setSetupPage(0);
    }
  }

  checkUnassignedEmpty(): boolean {
    return this.TierService.checkFileUploaded();
  }  

  setSetupPage(page: number) {
    this.TierService.setSetupPage(page);
  }
}