import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConceptPluSalesService {

  apiRoot: string = `${environment.menuUrl}/api/conceptrestaurantplusales`;
  private apiRootRes: string = `${environment.restaurantUrl}/api/restaurantplupricemodels`;
  
  constructor(private http: HttpClient) { }

  GetConceptRestaurantPluSalesForCategory(resId:string,timePeriodId:string,subGroupName:string,selectedServiceModeId:string,soldtypeId:string){
    let result;

    if(soldtypeId && subGroupName!="All"){
      result = this.http.get<any>(`${this.apiRoot}/GetConceptRestaurantPluSalesForCategory?restaurantId=${resId}&timeperiodId=${timePeriodId}&subGroupName=${subGroupName}&soldtypeId=${soldtypeId}&servicemodeId=${selectedServiceModeId}`)
    }
    else if(!soldtypeId && subGroupName != "All"){
      result = this.http.get<any>(`${this.apiRoot}/GetConceptRestaurantPluSalesForCategory?restaurantId=${resId}&timeperiodId=${timePeriodId}&subGroupName=${subGroupName}&servicemodeId=${selectedServiceModeId}`)
    }
    else if(soldtypeId && subGroupName== "All"){
      result = this.http.get<any>(`${this.apiRoot}/GetConceptRestaurantPluSalesForCategory?restaurantId=${resId}&timeperiodId=${timePeriodId}&soldtypeId=${soldtypeId}&servicemodeId=${selectedServiceModeId}`)
    }
    else if(!soldtypeId && subGroupName== "All"){
      result = this.http.get<any>(`${this.apiRoot}/GetConceptRestaurantPluSalesForCategory?restaurantId=${resId}&timeperiodId=${timePeriodId}&servicemodeId=${selectedServiceModeId}`)

    }
    return result;
  }

  GetRestaurantPluPriceModelsForStore(storeId:string){
    let result = this.http.get<any>(`${this.apiRootRes}/GetRestaurantPluPriceModelsForStore?storeId=${storeId}`);
    return result;
  }

  restaurantplupricemodels(modelName, subgroupName?){
    if( (subgroupName!="All")){
      let result = this.http.get<any>(`${this.apiRootRes}?Filters=name==${modelName},subgroupName==${subgroupName}`);
      return result;
    }else{
      let result = this.http.get<any>(`${this.apiRootRes}?Filters=name==${modelName}`);
      return result;
    }
  }

  conceptRestaurantPluSales(storeNum:string, timePeriodId:string, subGroupName:string, processDate:string){
    let result ;
    if(subGroupName=="All"){
      result = this.http.get<any>(`${this.apiRoot}/GetConceptRestaurantPluSalesForImpact?storeNum=${storeNum}&timeperiodId=${timePeriodId}&processDate=${processDate} 00:00:00.000`);
    }else{
      result = this.http.get<any>(`${this.apiRoot}/GetConceptRestaurantPluSalesForImpact?storeNum=${storeNum}&timeperiodId=${timePeriodId}&subGroupName=${subGroupName}&processDate=${processDate} 00:00:00.000`); 
    }
    return result;
  }
}
