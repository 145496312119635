import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStoreService {

    constructor() { }

    public saveFilterState(value: any) {
        if (value?.length > 0) {
            const restaurantGroup = value[0];
            const restaurantNumber = value[1];
            const competitors = value[2];
            const benchMarks = value[3];
            const menuCategory = value[4];
            const userId = value['userId'];
            this.saveData("filters", JSON.stringify({ restaurant_group: restaurantGroup, restaurant_num: restaurantNumber, competitors: competitors, benchMarks:benchMarks, menuCategory:menuCategory, userId:userId }));
        }
    }

    public saveData(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    public getData(key: string) {
        return localStorage.getItem(key)
    }
    public removeData(key: string) {
        localStorage.removeItem(key);
    }

    public clearData() {
        localStorage.clear();
    }
}