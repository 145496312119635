<div class="wrapper-box">
  <div class="container">
    <div *ngIf="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <div *ngIf="showConfirmation" class="confirmation">
      <p class="checkmark">✔</p>
      <p class="message">The results will be sent to the email address: {{ submittedEmail }}</p>
      <p class="message">Submitted at: {{ submissionTime | date:'medium' }}</p>
    </div>
    <div *ngIf="!showConfirmation" class="row">
      <div class="col-md-6 offset-md-3">
        <form [formGroup]="FormData" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="Email">Email</label>
            <input
              type="email"
              class="form-control"
              name="Email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              formControlName="Email"
            />
          </div>
          <div class="spacer-row"></div>
          <div class="form-group">
            <label for="fullname">Full Name</label>
            <input
              type="text"
              class="form-control"
              name="Fullname"
              placeholder="Full Name"
              formControlName="Fullname"
            />
          </div>
          <div class="spacer-row"></div>
          <div class="form-group">
            <label for="phone">Phone #</label>
            <input
            type = "text"
              class="form-control"
              formControlName="Phone"
              placeholder="Phone #"
              name="Phone"
              />
          </div>
          <div class="spacer-row"></div>
          <div class="spacer-row"></div>
          <div class="form-group">
            <label for="File1">Product Price File</label>
            <input
            (change)="this.onFilechange($event, 1)"
              type = "file"
              class="form-control"
              formControlName="File1"
              name="File1"
              />
          </div>
          <div class="spacer-row"></div>
          <div class="form-group">
            <label for="File2">Product Availability File</label>
            <input
            (change)="this.onFilechange($event, 2)"
              type = "file"
              class="form-control"
              formControlName="File2"
              name="File2"
              />
          </div>
          <div class="spacer-row"></div>
          <div class="form-group">
            <label for="File3">Restaurant Groups File</label>
            <input
            (change)="this.onFilechange($event, 3)"
              type = "file"
              class="form-control"
              formControlName="File3"
              name="File3"
              />
          </div>
          
          <div class="spacer-box">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="!FormData.valid || isSubmitting"
          >
          <span *ngIf="!isSubmitting">Submit</span>
          <span *ngIf="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="isSubmitting">Submitting...</span>
          </button>
         </div>
        </form>
      </div>
    </div>
  </div>
</div>
