import { Component, Input } from '@angular/core';
import { TierService } from 'src/app/services/tier.service';

@Component({
  selector: 'app-text-bar',
  templateUrl: './text-bar.component.html',
  //styleUrls: ['./text-bar.component.css']
})
export class TextBarComponent {

  @Input() initialText: string = ''; // Accept initial text as input
  @Input() tierId: string; // Accept tier ID as input
  editText: string = '';
  editing: boolean = false;
  text: string;
  acceptedId: string;

  TierService: TierService;

  constructor(TierService: TierService) {
    this.TierService = TierService;
  }

  ngOnInit() {
    this.text = this.initialText; // Use the initial text as the starting text
    this.editText = this.initialText; // Also copy it to editText for editing
    this.acceptedId = this.tierId;

    window.addEventListener('keyup', (event) => {
      if (event.key === 'Enter') {
        this.saveChanges();
      }
    });
  }

  enableEditing() {
    this.editing = true;
  }

  /*tierExpand() {
    this.TierService.tierExpand(this.acceptedId);
  }*/

  selectAll() {
    this.TierService.selectAll(this.acceptedId);
  }

  deselectAll() {
    this.TierService.deselectAll(this.acceptedId);
  }

  getUnassignedGroupId() {
    return this.TierService.getUnassignedGroupId();
  }

  saveChanges(): void {
    console.log(`Saving changes for editText: '${this.editText}'`);
    if (this.editText.trim().toLowerCase() === 'unassigned') {
      this.editText = this.initialText;
      this.editing = true;
      return; 
    }

    this.text = this.editText; // Save edited text if validation passes
    this.editing = false; // Exit editing mode
    this.TierService.renameTier(this.acceptedId, this.text);
  }

  deleteTier() {
    this.TierService.deleteTier(this.acceptedId);
  }
}