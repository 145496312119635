
export const data = {
    competitors: {
        "004490": [
            {
                isUserRestaurant: true,
                brand: "BK",
                rating: "4.5",
                storeNumber: "004490",
                address: "1700 Biscayne Blvd Miami, FL 33132",
                trafficCount: "36000",
                houseIncome: "42093",
            },
            {
                storeNumber: "001234",
                distance: '0.1',
                isUserRestaurant: false,
                brand: "MCD",
                rating: "3.2",
                address: "345 NE 2nd Ave",
                trafficCount: "38000",
                houseIncome: "61024"
            },
            {
                storeNumber: "001235",
                distance: '0.3',
                isUserRestaurant: false,
                brand: "WDY",
                rating: "2.6",
                address: "200 SW 8th St",
                trafficCount: "42000",
                houseIncome: "54040"
            },
            {
                storeNumber: "001236",
                distance: '0.7',
                isUserRestaurant: false,
                brand: "MCD",
                rating: "1.5",
                address: "2600 NW 199th St",
                trafficCount: "39000",
                houseIncome: "40001"
            },
            {
                storeNumber: "001237",
                distance: '1.1',
                isUserRestaurant: false,
                brand: "JKB",
                rating: "5.0",
                address: "18 SW 1th St",
                trafficCount: "42000",
                houseIncome: "52013"
            },
        ],
        "004491": [
            {
                isUserRestaurant: true,
                brand: "BK",
                rating: "4.7",
                storeNumber: "004491",
                address: "10 SW 8th St",
                trafficCount: "35000",
                houseIncome: "41200",
            },
            {
                storeNumber: "001987",
                distance: '0.08',
                isUserRestaurant: false,
                brand: "WDY",
                rating: "1.9",
                address: "7801 Biscayne Blvd",
                trafficCount: "42000",
                houseIncome: "54040"
            },
            {
                storeNumber: "001988",
                distance: '0.2',
                isUserRestaurant: false,
                brand: "MCD",
                rating: "4.7",
                address: "299 SW 8th St",
                trafficCount: "38000",
                houseIncome: "31060"
            },

            {
                storeNumber: "001999",
                distance: '0.3',
                isUserRestaurant: false,
                brand: "MCD",
                rating: "2.2",
                address: "3747 NW 7th St",
                trafficCount: "39000",
                houseIncome: "62012"
            },
            {
                storeNumber: "001995",
                distance: '0.7',
                isUserRestaurant: false,
                brand: "JKB",
                rating: "4.8",
                address: "5534 NW 7th Ave",
                trafficCount: "46100",
                houseIncome: "42093"
            }
        ],
        "004492": [
            {
                isUserRestaurant: true,
                brand: "BK",
                rating: "4.9",
                storeNumber: "004492",
                address: "3051 Coral Way",
                trafficCount: "40000",
                houseIncome: "45092",
            },
            {
                storeNumber: "001237",
                distance: '0.7',
                isUserRestaurant: false,
                brand: "JKB",
                rating: "4.2",
                address: "6800 SW 8th St",
                trafficCount: "42760",
                houseIncome: "44091"
            },
            {
                storeNumber: "001234",
                distance: '0.8',
                isUserRestaurant: false,
                brand: "MCD",
                rating: "3.1",
                address: "1016 W Flagler St",
                trafficCount: "38000",
                houseIncome: "52010"
            },

            {
                storeNumber: "001236",
                distance: '1.9',
                isUserRestaurant: false,
                brand: "MCD",
                rating: "3.8",
                address: "1400 SW 8th St",
                trafficCount: "39000",
                houseIncome: "42093"
            },
            {
                storeNumber: "001235",
                distance: '2.0',
                isUserRestaurant: false,
                brand: "WDY",
                rating: "4.9",
                address: "687 NW 37th Ave",
                trafficCount: "42000",
                houseIncome: "41320"
            },
        ]
    }
    ,
    restaurants: [
        {
            isUserRestaurant: true,
            brand: "BK",
            storeNumber: "000064"
        },
        {
            storeNumber: "004491",
            isUserRestaurant: false,
            brand: "BK"
        },
        {
            storeNumber: "004492",
            isUserRestaurant: false,
            brand: "BK"
        }

    ],
    sideMenu: [
        {
            label: 'Anchors'
        }      
    ],
    competitorTable: [
        [
            { menuitemName: 'Whopper', price: '5.69', brand: "BK", address: "1700 Biscayne Blvd Miami, FL 33132" },
            { menuitemName: 'Big Mac', price: '5.09', brand: 'MCD', address: "345 NE 2nd Ave" },
            { menuitemName: 'Dave\'s Single', price: '5.39', brand: 'WDY', address: "200 SW 8th St" },
            { menuitemName: 'Big Mac', price: '5.09', brand: 'MCD', address: "2600 NW 199th St" },
            { menuitemName: 'Classic Jack', price: '5.69', brand: 'JKB', address: "18 SW 1th St" }
        ],
        [
            { menuitemName: 'Whopper Combo', price: '9.19' },
            { menuitemName: 'Big Mac Combo', price: '8.59', brand: 'MCD' },
            { menuitemName: 'Dave\'s Single Combo', price: '9.59', brand: 'WDY' },
            { menuitemName: 'Big Mac Combo', price: '8.59', brand: 'MCD' },
            { menuitemName: 'Classic Jack Combo', price: '9.19', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Small Soft Drink', price: '2.49' },
            { menuitemName: 'Medium Soft Drink', price: '1.49', brand: 'MCD' },
            { menuitemName: 'Small Soft Drink', price: '2.19', brand: 'WDY' },
            { menuitemName: 'Medium Soft Drink', price: '1.49', brand: 'MCD' },
            { menuitemName: 'Small Soft Drink', price: '2.49', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Small French Fry', price: '2.49' },
            { menuitemName: 'Medium French Fry', price: '2.19', brand: 'MCD' },
            { menuitemName: 'Small French Fry', price: '2.39', brand: 'WDY' },
            { menuitemName: 'Medium French Fry', price: '2.19', brand: 'MCD' },
            { menuitemName: 'Small French Fry', price: '2.59', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Cheeseburger', price: '1.89' },
            { menuitemName: 'Cheeseburger', price: '1.89', brand: 'MCD' },
            { menuitemName: 'Cheeseburger', price: '1.89', brand: 'WDY' },
            { menuitemName: 'Cheeseburger', price: '1.89', brand: 'MCD' },
            { menuitemName: 'Cheeseburger', price: '1.89', brand: 'JKB' }
        ],
        [
            { menuitemName: '4pc Nugget', price: '1.49' },
            { menuitemName: '4pc Nugget', price: '2.29', brand: 'MCD' },
            { menuitemName: '4pc Nugget', price: '1.89', brand: 'WDY' },
            { menuitemName: '4pc Nugget', price: '1.29', brand: 'MCD' },
            { menuitemName: '4pc Nugget', price: '1.49', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Royal Crispy', price: '5.29' },
            { menuitemName: 'Deluxe Crispy Chicken', price: '4.19', brand: 'MCD' },
            { menuitemName: 'Classic Chicken', price: '5.89', brand: 'WDY' },
            { menuitemName: 'Deluxe Classic Chicken', price: '4.19', brand: 'MCD' },
            { menuitemName: 'Chicken Jack', price: '5.29', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Royal Crispy Combo', price: '8.79' },
            { menuitemName: 'Deluxe Crispy Chicken Combo', price: '6.99', brand: 'MCD' },
            { menuitemName: 'Classic Chicken Combo', price: '10.29', brand: 'WDY' },
            { menuitemName: 'Deluxe Classic Chicken Combo', price: '6.99', brand: 'MCD' },
            { menuitemName: 'Chicken Jack Combo', price: '8.79', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Sausage, Egg & Cheese Croissan\'wich', price: '3.79' },
            { menuitemName: 'Sausage & Egg McMuffin', price: '3.79', brand: 'MCD' },
            { menuitemName: 'Sausage, Egg & Cheese Coissant', price: '3.99', brand: 'WDY' },
            { menuitemName: 'Sausage & Egg McMuffin', price: '3.79', brand: 'MCD' },
            { menuitemName: 'Sausage Egg & Cheese Croissan\'wich', price: '3.79', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Sausage, Egg & Cheese Coissan\'wich Combo', price: '6.09' },
            { menuitemName: 'Sausage & Egg McMuffin Combo', price: '6.99', brand: 'MCD' },
            { menuitemName: 'Sausage, Egg & Cheese Coissant', price: '6.29', brand: 'WDY' },
            { menuitemName: 'Sausage & Egg McMuffin', price: '6.99', brand: 'MCD' },
            { menuitemName: 'Sausage, Egg & Cheese Croissan\'wich', price: '6.09', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Small Hashbrown', price: '1.89' },
            { menuitemName: 'Hashbrown', price: '1.99', brand: 'MCD' },
            { menuitemName: 'Small Hashbrown', price: '1.69', brand: 'WDY' },
            { menuitemName: 'Hashbrown', price: '1.99', brand: 'MCD' },
            { menuitemName: 'Small Hashbrown', price: '1.89', brand: 'JKB' }
        ],
    ],
    competitorTable2: [
        [
            { menuitemName: 'Whopper', price: '5.90', brand: "BK", address: "10 SW 8th St" },
            { menuitemName: 'Big Mac', price: '5.19', brand: 'MCD', address: "299 SW 8th St" },
            { menuitemName: 'Dave\'s Single', price: '5.39', brand: 'WDY', address: "7801 Biscayne Blvd" },
            { menuitemName: 'Big Mac', price: '5.09', brand: 'MCD', address: "3747 NW 7th St" },
            { menuitemName: 'Classic Jack', price: '5.69', brand: 'JKB', address: "5534 NW 7th Ave" }
        ],
        [
            { menuitemName: 'Whopper Combo', price: '9.29' },
            { menuitemName: 'Big Mac Combo', price: '8.59', brand: 'MCD' },
            { menuitemName: 'Dave\'s Single Combo', price: '9.59', brand: 'WDY' },
            { menuitemName: 'Big Mac Combo', price: '8.59', brand: 'MCD' },
            { menuitemName: 'Classic Jack Combo', price: '9.19', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Small Soft Drink', price: '2.69' },
            { menuitemName: 'Medium Soft Drink', price: '1.49', brand: 'MCD' },
            { menuitemName: 'Small Soft Drink', price: '2.19', brand: 'WDY' },
            { menuitemName: 'Medium Soft Drink', price: '1.49', brand: 'MCD' },
            { menuitemName: 'Small Soft Drink', price: '2.49', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Small French Fry', price: '2.99' },
            { menuitemName: 'Medium French Fry', price: '2.19', brand: 'MCD' },
            { menuitemName: 'Small French Fry', price: '2.39', brand: 'WDY' },
            { menuitemName: 'Medium French Fry', price: '2.19', brand: 'MCD' },
            { menuitemName: 'Small French Fry', price: '2.59', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Cheeseburger', price: '1.69' },
            { menuitemName: 'Cheeseburger', price: '1.89', brand: 'MCD' },
            { menuitemName: 'Cheeseburger', price: '1.89', brand: 'WDY' },
            { menuitemName: 'Cheeseburger', price: '1.89', brand: 'MCD' },
            { menuitemName: 'Cheeseburger', price: '1.89', brand: 'JKB' }
        ],
        [
            { menuitemName: '4pc Nugget', price: '1.39' },
            { menuitemName: '4pc Nugget', price: '2.29', brand: 'MCD' },
            { menuitemName: '4pc Nugget', price: '1.89', brand: 'WDY' },
            { menuitemName: '4pc Nugget', price: '1.29', brand: 'MCD' },
            { menuitemName: '4pc Nugget', price: '1.49', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Royal Crispy', price: '5.99' },
            { menuitemName: 'Deluxe Crispy Chicken', price: '4.19', brand: 'MCD' },
            { menuitemName: 'Classic Chicken', price: '5.89', brand: 'WDY' },
            { menuitemName: 'Deluxe Classic Chicken', price: '4.19', brand: 'MCD' },
            { menuitemName: 'Chicken Jack', price: '5.29', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Royal Crispy Combo', price: '8.09' },
            { menuitemName: 'Deluxe Crispy Chicken Combo', price: '6.99', brand: 'MCD' },
            { menuitemName: 'Classic Chicken Combo', price: '10.29', brand: 'WDY' },
            { menuitemName: 'Deluxe Classic Chicken Combo', price: '6.99', brand: 'MCD' },
            { menuitemName: 'Chicken Jack Combo', price: '8.79', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Sausage, Egg & Cheese Croissan\'wich', price: '3.49' },
            { menuitemName: 'Sausage & Egg McMuffin', price: '3.79', brand: 'MCD' },
            { menuitemName: 'Sausage, Egg & Cheese Coissant', price: '3.99', brand: 'WDY' },
            { menuitemName: 'Sausage & Egg McMuffin', price: '3.79', brand: 'MCD' },
            { menuitemName: 'Sausage Egg & Cheese Croissan\'wich', price: '3.79', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Sausage, Egg & Cheese Coissan\'wich Combo', price: '6.19' },
            { menuitemName: 'Sausage & Egg McMuffin Combo', price: '6.99', brand: 'MCD' },
            { menuitemName: 'Sausage, Egg & Cheese Coissant', price: '6.29', brand: 'WDY' },
            { menuitemName: 'Sausage & Egg McMuffin', price: '6.99', brand: 'MCD' },
            { menuitemName: 'Sausage, Egg & Cheese Croissan\'wich', price: '6.09', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Small Hashbrown', price: '1.29' },
            { menuitemName: 'Hashbrown', price: '1.99', brand: 'MCD' },
            { menuitemName: 'Small Hashbrown', price: '1.69', brand: 'WDY' },
            { menuitemName: 'Hashbrown', price: '1.99', brand: 'MCD' },
            { menuitemName: 'Small Hashbrown', price: '1.89', brand: 'JKB' }
        ],
    ],
    competitorTable3: [
        [
            { menuitemName: 'Whopper', price: '5.10', brand: "BK", address: "3051 Coral Way" },
            { menuitemName: 'Big Mac', price: '5.18', brand: 'MCD', address: "1016 W Flagler St" },
            { menuitemName: 'Dave\'s Single', price: '5.39', brand: 'WDY', address: "687 NW 37th Ave" },
            { menuitemName: 'Big Mac', price: '5.09', brand: 'MCD', address: "1400 SW 8th St" },
            { menuitemName: 'Classic Jack', price: '5.69', brand: 'JKB', address: "6800 SW 8th St" }
        ],
        [
            { menuitemName: 'Whopper Combo', price: '9.49' },
            { menuitemName: 'Big Mac Combo', price: '8.59', brand: 'MCD' },
            { menuitemName: 'Dave\'s Single Combo', price: '9.99', brand: 'WDY' },
            { menuitemName: 'Big Mac Combo', price: '8.59', brand: 'MCD' },
            { menuitemName: 'Classic Jack Combo', price: '9.19', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Small Soft Drink', price: '2.79' },
            { menuitemName: 'Medium Soft Drink', price: '1.49', brand: 'MCD' },
            { menuitemName: 'Small Soft Drink', price: '2.19', brand: 'WDY' },
            { menuitemName: 'Medium Soft Drink', price: '1.49', brand: 'MCD' },
            { menuitemName: 'Small Soft Drink', price: '2.09', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Small French Fry', price: '2.99' },
            { menuitemName: 'Medium French Fry', price: '2.29', brand: 'MCD' },
            { menuitemName: 'Small French Fry', price: '2.39', brand: 'WDY' },
            { menuitemName: 'Medium French Fry', price: '2.19', brand: 'MCD' },
            { menuitemName: 'Small French Fry', price: '2.59', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Cheeseburger', price: '1.69' },
            { menuitemName: 'Cheeseburger', price: '1.89', brand: 'MCD' },
            { menuitemName: 'Cheeseburger', price: '1.89', brand: 'WDY' },
            { menuitemName: 'Cheeseburger', price: '1.89', brand: 'MCD' },
            { menuitemName: 'Cheeseburger', price: '1.89', brand: 'JKB' }
        ],
        [
            { menuitemName: '4pc Nugget', price: '1.39' },
            { menuitemName: '4pc Nugget', price: '2.29', brand: 'MCD' },
            { menuitemName: '4pc Nugget', price: '1.89', brand: 'WDY' },
            { menuitemName: '4pc Nugget', price: '1.29', brand: 'MCD' },
            { menuitemName: '4pc Nugget', price: '1.49', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Royal Crispy', price: '5.99' },
            { menuitemName: 'Deluxe Crispy Chicken', price: '4.19', brand: 'MCD' },
            { menuitemName: 'Classic Chicken', price: '5.89', brand: 'WDY' },
            { menuitemName: 'Deluxe Classic Chicken', price: '4.19', brand: 'MCD' },
            { menuitemName: 'Chicken Jack', price: '5.29', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Royal Crispy Combo', price: '8.09' },
            { menuitemName: 'Deluxe Crispy Chicken Combo', price: '6.99', brand: 'MCD' },
            { menuitemName: 'Classic Chicken Combo', price: '10.29', brand: 'WDY' },
            { menuitemName: 'Deluxe Classic Chicken Combo', price: '6.99', brand: 'MCD' },
            { menuitemName: 'Chicken Jack Combo', price: '8.79', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Sausage, Egg & Cheese Croissan\'wich', price: '3.49' },
            { menuitemName: 'Sausage & Egg McMuffin', price: '3.79', brand: 'MCD' },
            { menuitemName: 'Sausage, Egg & Cheese Coissant', price: '3.99', brand: 'WDY' },
            { menuitemName: 'Sausage & Egg McMuffin', price: '3.79', brand: 'MCD' },
            { menuitemName: 'Sausage Egg & Cheese Croissan\'wich', price: '3.79', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Sausage, Egg & Cheese Coissan\'wich Combo', price: '6.19' },
            { menuitemName: 'Sausage & Egg McMuffin Combo', price: '6.99', brand: 'MCD' },
            { menuitemName: 'Sausage, Egg & Cheese Coissant', price: '6.29', brand: 'WDY' },
            { menuitemName: 'Sausage & Egg McMuffin', price: '6.99', brand: 'MCD' },
            { menuitemName: 'Sausage, Egg & Cheese Croissan\'wich', price: '6.09', brand: 'JKB' }
        ],
        [
            { menuitemName: 'Small Hashbrown', price: '1.29' },
            { menuitemName: 'Hashbrown', price: '1.99', brand: 'MCD' },
            { menuitemName: 'Small Hashbrown', price: '1.69', brand: 'WDY' },
            { menuitemName: 'Hashbrown', price: '1.99', brand: 'MCD' },
            { menuitemName: 'Small Hashbrown', price: '1.89', brand: 'JKB' }
        ],
    ],
    priceChangeNumber: [
        [
            { menuitemName: 'Whopper', price: '5.90' },
            { menuitemName: 'Big Mac', price: '5.19' },
            { menuitemName: 'Dave\'s Single', price: '5.39' },
            { menuitemName: 'Big Mac', price: '5.09' },
            { menuitemName: 'Classic Jack', price: '5.69' }
        ],
        [
            { menuitemName: 'Whopper Combo', price: '9.29' },
            { menuitemName: 'Big Mac Combo', price: '8.59' },
            { menuitemName: 'Dave\'s Single Combo', price: '9.59' },
            { menuitemName: 'Big Mac Combo', price: '8.59' },
            { menuitemName: 'Classic Jack Combo', price: '9.19' }
        ],
        [
            { menuitemName: 'Small Soft Drink', price: '2.69' },
            { menuitemName: 'Medium Soft Drink', price: '1.49' },
            { menuitemName: 'Small Soft Drink', price: '2.19' },
            { menuitemName: 'Medium Soft Drink', price: '1.49' },
            { menuitemName: 'Small Soft Drink', price: '2.49' }
        ],
        [
            { menuitemName: 'Small French Fry', price: '2.99' },
            { menuitemName: 'Medium French Fry', price: '2.19' },
            { menuitemName: 'Small French Fry', price: '2.39' },
            { menuitemName: 'Medium French Fry', price: '2.19' },
            { menuitemName: 'Small French Fry', price: '2.59' }
        ],
        [
            { menuitemName: 'Cheeseburger', price: '1.69' },
            { menuitemName: 'Cheeseburger', price: '1.89' },
            { menuitemName: 'Cheeseburger', price: '1.89' },
            { menuitemName: 'Cheeseburger', price: '1.89' },
            { menuitemName: 'Cheeseburger', price: '1.89' }
        ],
        [
            { menuitemName: '4pc Nugget', price: '1.39' },
            { menuitemName: '4pc Nugget', price: '2.29' },
            { menuitemName: '4pc Nugget', price: '1.89' },
            { menuitemName: '4pc Nugget', price: '1.29' },
            { menuitemName: '4pc Nugget', price: '1.49' }
        ],
        [
            { menuitemName: 'Royal Crispy', price: '5.99' },
            { menuitemName: 'Deluxe Crispy Chicken', price: '4.19' },
            { menuitemName: 'Classic Chicken', price: '5.89' },
            { menuitemName: 'Deluxe Classic Chicken', price: '4.19' },
            { menuitemName: 'Chicken Jack', price: '5.29' }
        ],
        [
            { menuitemName: 'Royal Crispy Combo', price: '8.09' },
            { menuitemName: 'Deluxe Crispy Chicken Combo', price: '6.99' },
            { menuitemName: 'Classic Chicken Combo', price: '10.29' },
            { menuitemName: 'Deluxe Classic Chicken Combo', price: '6.99' },
            { menuitemName: 'Chicken Jack Combo', price: '8.79' }
        ],
        [
            { menuitemName: 'Sausage, Egg & Cheese Croissan\'wich', price: '3.49' },
            { menuitemName: 'Sausage & Egg McMuffin', price: '3.79' },
            { menuitemName: 'Sausage, Egg & Cheese Coissant', price: '3.99' },
            { menuitemName: 'Sausage & Egg McMuffin', price: '3.79' },
            { menuitemName: 'Sausage Egg & Cheese Croissan\'wich', price: '3.79' }
        ],
        [
            { menuitemName: 'Sausage, Egg & Cheese Coissan\'wich Combo', price: '6.19' },
            { menuitemName: 'Sausage & Egg McMuffin Combo', price: '6.99' },
            { menuitemName: 'Sausage, Egg & Cheese Coissant', price: '6.29' },
            { menuitemName: 'Sausage & Egg McMuffin', price: '6.99' },
            { menuitemName: 'Sausage, Egg & Cheese Croissan\'wich', price: '6.09' }
        ],
        [
            { menuitemName: 'Small Hashbrown', price: '1.29' },
            { menuitemName: 'Hashbrown', price: '1.99' },
            { menuitemName: 'Small Hashbrown', price: '1.69' },
            { menuitemName: 'Hashbrown', price: '1.99' },
            { menuitemName: 'Small Hashbrown', price: '1.89' }
        ],
     ],
    menuItemsPrice:[
        {resNum: 1, categoryId:'Whopper', menuItemName:'Kids',price:0.55,thirdpartyprice:2.98,compPrice:[0.65,0.70,0.63,0.68],comp3rdPartPrice:[3.14, 2.73, 1.59, 0.27],currentSalesPrice:3.19,recomendedRBIPrice:0.65,projectedRSIPrice:4.8,lastweekAUV:0.2,currentGp:0.46,currentcogs:74.2,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Kids',price:0.44,thirdpartyprice:3.42,compPrice:[0.45,0.37,0.50,0.54],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],currentSalesPrice:2.29,recomendedRBIPrice:0.55,projectedRSIPrice:4.7,lastweekAUV:4.2,currentGp:0.38,currentcogs:70.6,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper',menuItemName:'Small',price:1.56,thirdpartyprice:0.45,compPrice:[1.60,1.65,1.70,1.73],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],currentSalesPrice:138.17,recomendedRBIPrice:0,projectedRSIPrice:87.7,lastweekAUV:45.3,currentGp:0.12,currentcogs:78.8,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Small',price:1.44,thirdpartyprice:0.69,compPrice:[1.48,1.49,1.50,1.52],comp3rdPartPrice:[1.88, 0.03, 3.27, 2.11],currentSalesPrice:137.21,recomendedRBIPrice:0,projectedRSIPrice:87.6,lastweekAUV:60.2,currentGp:0.67,currentcogs:73.8,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper',menuItemName:'Value',price:1.32,thirdpartyprice:2.34,compPrice:[1.50,1.56,1.48,1.38],comp3rdPartPrice:[4.11, 2.42, 5.75, 6.86],currentSalesPrice:2.42,recomendedRBIPrice:1.42,projectedRSIPrice:0.8,lastweekAUV:46.2,currentGp:0.87,currentcogs:89.7,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Value',price:1.43,thirdpartyprice:2.45,compPrice:[1.43,1.46,1.48,1.36],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],currentSalesPrice:2.64,recomendedRBIPrice:1.79,projectedRSIPrice:0.7,lastweekAUV:78.5,currentGp:1.46,currentcogs:47.7,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper',menuItemName:'Medium',price:2.26,thirdpartyprice:1.78,compPrice:[2.28,2.26,2.40,2.26],comp3rdPartPrice:[0.88, 3.03, 1.27, 0.11],currentSalesPrice:161.16,recomendedRBIPrice:2.26,projectedRSIPrice:70.2,lastweekAUV:8.2,currentGp:0.92,currentcogs:63.7,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Medium',price:3.36,thirdpartyprice:2.32,compPrice:[3.30,3.43,3.34,3.40],comp3rdPartPrice:[1.88, 0.03, 3.27, 2.11],currentSalesPrice:153.21,recomendedRBIPrice:3.67,projectedRSIPrice:70.3,lastweekAUV:9.2,currentGp:0.26,currentcogs:38.7,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper',menuItemName:'Large',price:2.55,thirdpartyprice:3.21,compPrice:[2.50,2.34,2.52,2.58],comp3rdPartPrice:[2.35, 3.71, 1.05, 0.99],currentSalesPrice:102.96,recomendedRBIPrice:2.65,projectedRSIPrice:39.3,lastweekAUV:80.2,currentGp:0.52,currentcogs:10.7,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Large',price:3.66,thirdpartyprice:2.19,compPrice:[3.49,3.66,2.52,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],currentSalesPrice:100.44,recomendedRBIPrice:3.89,projectedRSIPrice:39.4,lastweekAUV:60.5,currentGp:2.45,currentcogs:98.4,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper',menuItemName:'Loyalty - Medium',price:1.38,thirdpartyprice:2.54,compPrice:[3.49,2.00,2.52,4.00],comp3rdPartPrice:[2.11, 1.42, 4.75, 2.86],currentSalesPrice:0.91,recomendedRBIPrice:1.48,projectedRSIPrice:-0.3,lastweekAUV:64.2,currentGp:4.44,currentcogs:53.9,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Loyalty - Medium',price:1.66,thirdpartyprice:2.12,compPrice:[3.66,2.00,2.52,2.26],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],currentSalesPrice:0.56,recomendedRBIPrice:0,projectedRSIPrice:-0.2,lastweekAUV:94.2,currentGp:9.42,currentcogs:34.4,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper',menuItemName:'Loyalty - Large',price:1.91,thirdpartyprice:1.34,compPrice:[1.00,2.00,3.66,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],currentSalesPrice:2.23,recomendedRBIPrice:0,projectedRSIPrice:0.2,lastweekAUV:21.2,currentGp:0.92,currentcogs:49.4,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Loyalty - Large',price:2.06,thirdpartyprice:2.54,compPrice:[3.66,2.00,2.52,4.00],comp3rdPartPrice:[2.35, 3.71, 1.05, 0.99],currentSalesPrice:2.01,recomendedRBIPrice:2.36,projectedRSIPrice:0.3,lastweekAUV:6.2,currentGp:5.44,currentcogs:84.5,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Kids',price:1.22,thirdpartyprice:2.23,compPrice:[1.00,2.00,2.52,3.66],comp3rdPartPrice:[4.11, 2.42, 5.75, 6.86],currentSalesPrice:4.33,recomendedRBIPrice:1.43,projectedRSIPrice:4.9,lastweekAUV:6.2,currentGp:4.42,currentcogs:80.7,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Kids',price:2.33,thirdpartyprice:4.21,compPrice:[1.00,3.66,2.52,2.26],comp3rdPartPrice:[4.11, 2.42, 5.75, 6.86],currentSalesPrice:3.91,recomendedRBIPrice:2.54,projectedRSIPrice:4.10,lastweekAUV:77.1,currentGp:60.45,currentcogs:60.7,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Small',price:2.56,thirdpartyprice:1.23,compPrice:[1.00,2.00,3.66,4.00],comp3rdPartPrice:[2.11, 1.42, 4.75, 2.86],currentSalesPrice:140.33,recomendedRBIPrice:2.69,projectedRSIPrice:87.8,lastweekAUV:87.2,currentGp:59.58,currentcogs:48.9,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Small',price:1.98,thirdpartyprice:1.54,compPrice:[3.66,2.00,2.52,4.00],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],currentSalesPrice:141.11,recomendedRBIPrice:0,projectedRSIPrice:87.9,lastweekAUV:64.1,currentGp:9.79,currentcogs:49.5,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Value',price:1.56,thirdpartyprice:4.21,compPrice:[1.00,2.00,2.52,4.00],comp3rdPartPrice:[2.11, 1.42, 4.75, 2.86],currentSalesPrice:2.66,recomendedRBIPrice:0,projectedRSIPrice:0.9,lastweekAUV:56.6,currentGp:1.96,currentcogs:48.9,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Value',price:1.76,thirdpartyprice:1.43,compPrice:[1.00,3.66,2.52,2.26],comp3rdPartPrice:[1.88, 0.03, 3.27, 2.11],currentSalesPrice:1.98,recomendedRBIPrice:1.89,projectedRSIPrice:0.10,lastweekAUV:56.7,currentGp:5.46,currentcogs:10.5,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Medium',price:2.99,thirdpartyprice:3.23,compPrice:[1.00,2.00,2.52,1.43],comp3rdPartPrice:[0.88, 3.03, 1.27, 0.11],currentSalesPrice:158.34,recomendedRBIPrice:3.21,projectedRSIPrice:70.1,lastweekAUV:56.8,currentGp:75.45,currentcogs:32.9,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Medium',price:2.52,thirdpartyprice:1.23,compPrice:[3.66,2.00,2.52,2.26],comp3rdPartPrice:[2.35, 3.71, 1.05, 0.99],currentSalesPrice:166.65,recomendedRBIPrice:2.76,projectedRSIPrice:70.0,lastweekAUV:66.2,currentGp:56.65,currentcogs:20.6,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Large',price:3.49,thirdpartyprice:4.43,compPrice:[1.00,2.00,2.52,3.66],comp3rdPartPrice:[2.11, 1.42, 4.75, 2.86],currentSalesPrice:100.78,recomendedRBIPrice:3.79,projectedRSIPrice:39.1,lastweekAUV:61.5,currentGp:52.54,currentcogs:39.8,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Large',price:2.99,thirdpartyprice:3.21,compPrice:[3.66,2.00,2.52,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],currentSalesPrice:101.78,recomendedRBIPrice:3.09,projectedRSIPrice:39.2,lastweekAUV:76.2,currentGp:47.46,currentcogs:20.6,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Loyalty - Medium',price:1.78,thirdpartyprice:2.65,compPrice:[3.66,2.00,3.00,1.43],comp3rdPartPrice:[2.11, 1.42, 4.75, 2.86],currentSalesPrice:0.99,recomendedRBIPrice:1.87,projectedRSIPrice:-0.4,lastweekAUV:36.2,currentGp:6.65,currentcogs:39.5,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Loyalty - Medium',price:1.93,thirdpartyprice:2.98,compPrice:[1.00,2.00,3.00,4.00],comp3rdPartPrice:[2.11, 1.42, 4.75, 2.86],currentSalesPrice:1.22,recomendedRBIPrice:0,projectedRSIPrice:-0.5,lastweekAUV:66.9,currentGp:1.92,currentcogs:38.5,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Loyalty - Large',price:2.00,thirdpartyprice:3.21,compPrice:[3.66,2.00,3.00,2.26],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],currentSalesPrice:2.32,recomendedRBIPrice:0,projectedRSIPrice:0.1,lastweekAUV:45.2,currentGp:23.45,currentcogs:91.2,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Loyalty - Large',price:2.05,thirdpartyprice:4.21,compPrice:[1.00,2.00,3.66,4.00],comp3rdPartPrice:[0.88, 3.03, 1.27, 0.11],currentSalesPrice:2.46,recomendedRBIPrice:2.41,projectedRSIPrice:0.5,lastweekAUV:64.8,currentGp:5.79,currentcogs:66.5,RbiPriceLoaded:false,override:'',},
        {resNum: 4, categoryId:'Whopper Jr.', menuItemName:'Whopper',price:0.55,thirdpartyprice:1.23,compPrice:[1.00,2.00,3.00,4.00],comp3rdPartPrice:[5.39, 4.71, 2.59, 1.37],currentSalesPrice:3.19,recomendedRBIPrice:0.65,projectedRSIPrice:4.8,lastweekAUV:0.2,currentGp:0.46,currentcogs:74.6,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'Whopper',price:0.44,thirdpartyprice:2.43,compPrice:[1.00,3.66,3.00,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],currentSalesPrice:2.29,recomendedRBIPrice:0.55,projectedRSIPrice:4.7,lastweekAUV:4.2,currentGp:0.35,currentcogs:70.9,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'Drink - S',price:1.56,thirdpartyprice:3.21,compPrice:[1.00,2.00,3.66,4.00],comp3rdPartPrice:[4.11, 2.42, 5.75, 6.86],currentSalesPrice:138.17,recomendedRBIPrice:1.66,projectedRSIPrice:87.7,lastweekAUV:45.3,currentGp:0.16,currentcogs:78.5,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'Drink - S',price:1.44,thirdpartyprice:3.42,compPrice:[1.00,3.66,3.00,2.26],comp3rdPartPrice:[0.88, 3.03, 1.27, 0.11],currentSalesPrice:137.21,recomendedRBIPrice:1.63,projectedRSIPrice:87.6,lastweekAUV:60.2,currentGp:0.62,currentcogs:73.0,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'French Fry - S',price:1.32,thirdpartyprice:2.31,compPrice:[1.00,2.00,3.00,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],currentSalesPrice:2.42,recomendedRBIPrice:1.42,projectedRSIPrice:0.8,lastweekAUV:46.2,currentGp:0.88,currentcogs:89.5,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'French Fry - S',price:1.43,thirdpartyprice:2.31,compPrice:[1.00,2.00,3.66,4.00],comp3rdPartPrice:[5.39, 4.71, 2.59, 1.37],currentSalesPrice:2.64,recomendedRBIPrice:1.79,projectedRSIPrice:0.7,lastweekAUV:78.5,currentGp:1.4,currentcogs:47.7,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'Burger Ch',price:2.26,thirdpartyprice:3.21,compPrice:[1.00,3.66,3.00,1.43],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],currentSalesPrice:161.16,recomendedRBIPrice:2.26,projectedRSIPrice:70.2,lastweekAUV:8.2,currentGp:0.99,currentcogs:63.2,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'Burger Ch',price:3.36,thirdpartyprice:2.32,compPrice:[1.00,2.00,3.00,3.66],comp3rdPartPrice:[0.88, 3.03, 1.27, 0.11],currentSalesPrice:153.21,recomendedRBIPrice:3.67,projectedRSIPrice:70.3,lastweekAUV:9.2,currentGp:0.25,currentcogs:38.7,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'Chicken Nuggets 4 Pc',price:2.55,thirdpartyprice:1.23,compPrice:[1.00,2.00,3.00,0.55],comp3rdPartPrice:[1.88, 0.03, 3.27, 2.11],currentSalesPrice:102.96,recomendedRBIPrice:2.65,projectedRSIPrice:39.3,lastweekAUV:80.2,currentGp:0.56,currentcogs:10.6,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'Chicken Nuggets 4 Pc',price:3.66,thirdpartyprice:3.21,compPrice:[3.66,2.00,3.00,4.00],comp3rdPartPrice:[0.88, 3.03, 1.27, 0.11],currentSalesPrice:100.44,recomendedRBIPrice:3.89,projectedRSIPrice:39.4,lastweekAUV:60.5,currentGp:2.48,currentcogs:98.6,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'Royal Crispy Chicken',price:1.38,thirdpartyprice:1.43,compPrice:[1.00,2.00,3.00,1.43],comp3rdPartPrice:[2.35, 3.71, 1.05, 0.99],currentSalesPrice:0.91,recomendedRBIPrice:1.48,projectedRSIPrice:-0.3,lastweekAUV:64.2,currentGp:4.46,currentcogs:53.1,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'Royal Crispy Chicken',price:1.66,thirdpartyprice:2.21,compPrice:[1.00,3.66,3.00,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],currentSalesPrice:0.56,recomendedRBIPrice:1.77,projectedRSIPrice:-0.2,lastweekAUV:94.2,currentGp:9.49,currentcogs:34.6,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'Hash Rounds - S',price:1.91,thirdpartyprice:2.32,compPrice:[1.00,2.00,3.00,0.55],comp3rdPartPrice:[1.88, 0.03, 3.27, 2.11],currentSalesPrice:2.23,recomendedRBIPrice:2.01,projectedRSIPrice:0.2,lastweekAUV:21.2,currentGp:0.95,currentcogs:49.7,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'Hash Rounds - S',price:2.06,thirdpartyprice:2.32,compPrice:[1.00,3.66,3.00,4.00],comp3rdPartPrice:[4.11, 2.42, 5.75, 6.86],currentSalesPrice:2.01,recomendedRBIPrice:2.36,projectedRSIPrice:0.3,lastweekAUV:6.2,currentGp:5.41,currentcogs:84.3,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'Whopper',price:1.22,thirdpartyprice:2.32,compPrice:[1.00,2.00,2.52,4.00],comp3rdPartPrice:[5.39, 4.71, 2.59, 1.37],currentSalesPrice:4.33,recomendedRBIPrice:1.43,projectedRSIPrice:4.9,lastweekAUV:6.2,currentGp:4.45,currentcogs:80.2,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper Jr.',menuItemName:'Whopper',price:2.33,thirdpartyprice:3.56,compPrice:[1.00,1.93,2.52,1.43],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],currentSalesPrice:3.91,recomendedRBIPrice:2.54,projectedRSIPrice:4.10,lastweekAUV:77.1,currentGp:60.46,currentcogs:60.7,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper Jr.',menuItemName:'Drink - S',price:2.56,thirdpartyprice:2.65,compPrice:[1.00,1.93,3.66,0.55],comp3rdPartPrice:[5.39, 4.71, 2.59, 1.37],currentSalesPrice:140.33,recomendedRBIPrice:0,projectedRSIPrice:87.8,lastweekAUV:87.2,currentGp:59.55,currentcogs:48.5,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper Jr.',menuItemName:'Drink - S',price:1.98,thirdpartyprice:3.54,compPrice:[1.00,1.93,2.52,4.00],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],currentSalesPrice:141.11,recomendedRBIPrice:0,projectedRSIPrice:87.9,lastweekAUV:64.1,currentGp:9.71,currentcogs:49.1,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper Jr.',menuItemName:'French Fry - S',price:1.56,thirdpartyprice:2.22,compPrice:[3.66,1.93,2.52,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],currentSalesPrice:2.66,recomendedRBIPrice:1.65,projectedRSIPrice:0.9,lastweekAUV:56.6,currentGp:1.93,currentcogs:48.6,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper Jr.',menuItemName:'French Fry - S',price:1.76,thirdpartyprice:2.89,compPrice:[1.00,1.93,2.52,2.99],comp3rdPartPrice:[1.88, 0.03, 3.27, 2.11],currentSalesPrice:1.98,recomendedRBIPrice:1.89,projectedRSIPrice:0.10,lastweekAUV:56.7,currentGp:5.41,currentcogs:10.6,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper Jr.',menuItemName:'Burger Ch',price:2.99,thirdpartyprice:2.23,compPrice:[1.00,1.93,2.52,4.00],comp3rdPartPrice:[5.39, 4.71, 2.59, 1.37],currentSalesPrice:158.34,recomendedRBIPrice:3.21,projectedRSIPrice:70.1,lastweekAUV:56.8,currentGp:75.45,currentcogs:32.5,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper Jr.',menuItemName:'Burger Ch',price:2.52,thirdpartyprice:3.23,compPrice:[1.00,1.93,2.52,0.55],comp3rdPartPrice:[2.35, 3.71, 1.05, 0.99],currentSalesPrice:166.65,recomendedRBIPrice:2.76,projectedRSIPrice:70.0,lastweekAUV:66.2,currentGp:56.64,currentcogs:20.5,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper Jr.',menuItemName:'Chicken Nuggets 4 Pc',price:3.49,thirdpartyprice:3.21,compPrice:[3.49,1.93,2.52,2.99],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],currentSalesPrice:100.78,recomendedRBIPrice:3.79,projectedRSIPrice:39.1,lastweekAUV:61.5,currentGp:52.55,currentcogs:39.4,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper Jr.',menuItemName:'Chicken Nuggets 4 Pc',price:2.99,thirdpartyprice:5.32,compPrice:[3.49,1.93,2.52,4.00],comp3rdPartPrice:[0.88, 3.03, 1.27, 0.11],currentSalesPrice:101.78,recomendedRBIPrice:3.09,projectedRSIPrice:39.2,lastweekAUV:76.2,currentGp:47.41,currentcogs:20.1,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper Jr.',menuItemName:'Royal Crispy Chicken',price:1.78,thirdpartyprice:2.21,compPrice:[3.49,3.66,2.52,0.55],comp3rdPartPrice:[4.11, 2.42, 5.75, 6.86],currentSalesPrice:0.99,recomendedRBIPrice:1.87,projectedRSIPrice:-0.4,lastweekAUV:36.2,currentGp:6.61,currentcogs:39.1,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper Jr.',menuItemName:'Royal Crispy Chicken',price:1.93,thirdpartyprice:1.54,compPrice:[3.49,1.93,2.52,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],currentSalesPrice:1.22,recomendedRBIPrice:2.03,projectedRSIPrice:-0.5,lastweekAUV:66.9,currentGp:1.91,currentcogs:38.3,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper Jr.',menuItemName:'Hash Rounds - S',price:2.00,thirdpartyprice:1.67,compPrice:[3.66,2.00,2.52,2.99],comp3rdPartPrice:[1.88, 0.03, 3.27, 2.11],currentSalesPrice:2.32,recomendedRBIPrice:2.41,projectedRSIPrice:0.1,lastweekAUV:45.2,currentGp:23.43,currentcogs:91.3,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper Jr.',menuItemName:'Hash Rounds - S',price:2.05,thirdpartyprice:1.89,compPrice:[3.49,2.00,3.66,4.00],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],currentSalesPrice:2.46,recomendedRBIPrice:0,projectedRSIPrice:0.5,lastweekAUV:64.8,currentGp:5.72,currentcogs:66.2,RbiPriceLoaded:false,override:'',},

    ],
    modelingRestaurantUsers :[
        {
            "parentRuNum": null,
            "ruNum": null,
            "ruName": "All",
            "groupId": 23845,
            "groupName": "Chicago North",
            "restaurantNum": 1,
            "userId": "Jcarlos1",
            "id": "eef7f6e8-98b6-4fd1-9eea-deec8e069e32"
        },
        {
            "parentRuNum": null,
            "ruNum": null,
            "ruName": "Dhanani  Shoukat - RU    ",
            "groupId": 14211,
            "groupName": "Chicago West",
            "restaurantNum": 2,
            "userId": "Jcarlos1",
            "id": "ea23c303-9641-4523-879b-63bb157a4e6c"
        },
        {
            "parentRuNum": null,
            "ruNum": null,
            "ruName": "Dhanani  Shoukat - RU                             ",
            "groupId": 14211,
            "groupName": "Chicago West",
            "restaurantNum": 3,
            "userId": "Jcarlos1",
            "id": "fb7a05d3-2052-4b00-9859-0a3c94d4e50d"
        },
        {
            "parentRuNum": null,
            "ruNum": null,
            "ruName": "Dhanani  Shoukat - RU                             ",
            "groupId": 23845,
            "groupName": "Chicago North",
            "restaurantNum": 4,
            "userId": "Jcarlos1",
            "id": "51e2f724-4f4d-4133-8e3a-5a6e8005d5f1"
       }],
    svedModel:[
        {
            "id": "fb7a05d3-2052-4b00-9859-0a3c94d4e50d",
            "ResNUm": '1',
            "modelName": '000001-2.11.23-v1',
            "createdBy": 'John',
            "createdOn": '02/11/2023',
            "notes": '',
            
        },
        {
            "id": "51e2f724-4f4d-4133-8e3a-5a6e8005d5f1",
            "ResNUm": '1',
            "modelName": '000001-10.23.22-v1',
            "createdBy": 'John',
            "createdOn": '10/23/2022',
            "notes": 'Drinks Price Update +$.10',
            
        },
        {
            "id": "fb7a05d3-2052-4b00-9859-0a3c94d4e50d",
            "ResNUm": '6',
            "modelName": '000006-2.11.23-v1',
            "createdBy": 'steve',
            "createdOn": '03/11/2023',
            "notes": '',
            
        },
        {
            "id": "51e2f724-4f4d-4133-8e3a-5a6e8005d5f1",
            "ResNUm": '6',
            "modelName": '000006-10.23.22-v1',
            "createdBy": 'Smith',
            "createdOn": '11/23/2022',
            "notes": 'Drinks Price Update +$.90',
            
        },
        {
            "id": "52e2f724-4f4d-4133-8e3a-5a6e8005d6f1",
            "ResNUm": '6',
            "modelName": '000006-10.23.22-v1',
            "createdBy": 'Joe',
            "createdOn": '16/23/2022',
            "notes": 'Drinks Price Update +$.80',
            
        },
        {
            "id": "fb7a05d3-2052-4b00-9859-0a3c94d4e50d",
            "ResNUm": '4',
            "modelName": '000004-2.11.23-v1',
            "createdBy": 'Nick',
            "createdOn": '03/11/2023',
            "notes": '',
            
        },
        {
            "id": "51e2f724-4f4d-4133-8e3a-5a6e8005d5f1",
            "ResNUm": '4',
            "modelName": '000004-10.23.22-v1',
            "createdBy": 'Mor',
            "createdOn": '11/23/2022',
            "notes": 'Drinks Price Update +$.50',
            
        },
        {
            "id": "52e2f724-4f4d-4133-8e3a-5a6e8005d6f1",
            "ResNUm": '29',
            "modelName": '000004-10.23.22-v1',
            "createdBy": 'MJ',
            "createdOn": '16/23/2022',
            "notes": 'Drinks Price Update +$.30',
            
        }
    ],
   menuItemsPriceImpact:[
        {resNum: 1, categoryId:'Whopper', menuItemName:'Kids',price:0.55,projectedAUV:2.98,compPrice:[0.65,0.70,0.63,0.68],comp3rdPartPrice:[3.14, 2.73, 1.59, 0.27],actualImpact:3.19,recomendedRBIPrice:0.65,projectedImpact:4.8,currentGp:0.46,currentcogs:74.2,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Kids',price:0.44,projectedAUV:3.42,compPrice:[0.45,0.37,0.50,0.54],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],actualImpact:2.29,recomendedRBIPrice:0.55,projectedImpact:4.7,currentGp:0.38,currentcogs:70.6,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper',menuItemName:'Small',price:1.56,projectedAUV:0.45,compPrice:[1.60,1.65,1.70,1.73],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],actualImpact:138.17,recomendedRBIPrice:0,projectedImpact:87.7,currentGp:0.12,currentcogs:78.8,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Small',price:1.44,projectedAUV:0.69,compPrice:[1.48,1.49,1.50,1.52],comp3rdPartPrice:[1.88, 0.03, 3.27, 2.11],actualImpact:137.21,recomendedRBIPrice:0,projectedImpact:87.6,currentGp:0.67,currentcogs:73.8,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper',menuItemName:'Value',price:1.32,projectedAUV:2.34,compPrice:[1.50,1.56,1.48,1.38],comp3rdPartPrice:[4.11, 2.42, 5.75, 6.86],actualImpact:2.42,recomendedRBIPrice:1.42,projectedImpact:0.8,currentGp:0.87,currentcogs:89.7,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Value',price:1.43,projectedAUV:2.45,compPrice:[1.43,1.46,1.48,1.36],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],actualImpact:2.64,recomendedRBIPrice:1.79,projectedImpact:0.7,currentGp:1.46,currentcogs:47.7,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper',menuItemName:'Medium',price:2.26,projectedAUV:1.78,compPrice:[2.28,2.26,2.40,2.26],comp3rdPartPrice:[0.88, 3.03, 1.27, 0.11],actualImpact:161.16,recomendedRBIPrice:2.26,projectedImpact:70.2,currentGp:0.92,currentcogs:63.7,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Medium',price:3.36,projectedAUV:2.32,compPrice:[3.30,3.43,3.34,3.40],comp3rdPartPrice:[1.88, 0.03, 3.27, 2.11],actualImpact:153.21,recomendedRBIPrice:3.67,projectedImpact:70.3,currentGp:0.26,currentcogs:38.7,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper',menuItemName:'Large',price:2.55,projectedAUV:3.21,compPrice:[2.50,2.34,2.52,2.58],comp3rdPartPrice:[2.35, 3.71, 1.05, 0.99],actualImpact:102.96,recomendedRBIPrice:2.65,projectedImpact:39.3,currentGp:0.52,currentcogs:10.7,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Large',price:3.66,projectedAUV:2.19,compPrice:[3.49,3.66,2.52,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],actualImpact:100.44,recomendedRBIPrice:3.89,projectedImpact:39.4,currentGp:2.45,currentcogs:98.4,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper',menuItemName:'Loyalty - Medium',price:1.38,projectedAUV:2.54,compPrice:[3.49,2.00,2.52,4.00],comp3rdPartPrice:[2.11, 1.42, 4.75, 2.86],actualImpact:0.91,recomendedRBIPrice:1.48,projectedImpact:-0.3,currentGp:4.44,currentcogs:53.9,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Loyalty - Medium',price:1.66,projectedAUV:2.12,compPrice:[3.66,2.00,2.52,2.26],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],actualImpact:0.56,recomendedRBIPrice:0,projectedImpact:-0.2,currentGp:9.42,currentcogs:34.4,RbiPriceLoaded:false,override:'',},
        {resNum: 1, categoryId:'Whopper',menuItemName:'Loyalty - Large',price:1.91,projectedAUV:1.34,compPrice:[1.00,2.00,3.66,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],actualImpact:2.23,recomendedRBIPrice:0,projectedImpact:0.2,currentGp:0.92,currentcogs:49.4,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper',menuItemName:'Loyalty - Large',price:2.06,projectedAUV:2.54,compPrice:[3.66,2.00,2.52,4.00],comp3rdPartPrice:[2.35, 3.71, 1.05, 0.99],actualImpact:2.01,recomendedRBIPrice:2.36,projectedImpact:0.3,currentGp:5.44,currentcogs:84.5,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Kids',price:1.22,projectedAUV:2.23,compPrice:[1.00,2.00,2.52,3.66],comp3rdPartPrice:[4.11, 2.42, 5.75, 6.86],actualImpact:4.33,recomendedRBIPrice:1.43,projectedImpact:4.9,currentGp:4.42,currentcogs:80.7,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Kids',price:2.33,projectedAUV:4.21,compPrice:[1.00,3.66,2.52,2.26],comp3rdPartPrice:[4.11, 2.42, 5.75, 6.86],actualImpact:3.91,recomendedRBIPrice:2.54,projectedImpact:4.10,currentGp:60.45,currentcogs:60.7,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Small',price:2.56,projectedAUV:1.23,compPrice:[1.00,2.00,3.66,4.00],comp3rdPartPrice:[2.11, 1.42, 4.75, 2.86],actualImpact:140.33,recomendedRBIPrice:2.69,projectedImpact:87.8,currentGp:59.58,currentcogs:48.9,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Small',price:1.98,projectedAUV:1.54,compPrice:[3.66,2.00,2.52,4.00],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],actualImpact:141.11,recomendedRBIPrice:0,projectedImpact:87.9,currentGp:9.79,currentcogs:49.5,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Value',price:1.56,projectedAUV:4.21,compPrice:[1.00,2.00,2.52,4.00],comp3rdPartPrice:[2.11, 1.42, 4.75, 2.86],actualImpact:2.66,recomendedRBIPrice:0,projectedImpact:0.9,currentGp:1.96,currentcogs:48.9,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Value',price:1.76,projectedAUV:1.43,compPrice:[1.00,3.66,2.52,2.26],comp3rdPartPrice:[1.88, 0.03, 3.27, 2.11],actualImpact:1.98,recomendedRBIPrice:1.89,projectedImpact:0.10,currentGp:5.46,currentcogs:10.5,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Medium',price:2.99,projectedAUV:3.23,compPrice:[1.00,2.00,2.52,1.43],comp3rdPartPrice:[0.88, 3.03, 1.27, 0.11],actualImpact:158.34,recomendedRBIPrice:3.21,projectedImpact:70.1,currentGp:75.45,currentcogs:32.9,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Medium',price:2.52,projectedAUV:1.23,compPrice:[3.66,2.00,2.52,2.26],comp3rdPartPrice:[2.35, 3.71, 1.05, 0.99],actualImpact:166.65,recomendedRBIPrice:2.76,projectedImpact:70.0,currentGp:56.65,currentcogs:20.6,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Large',price:3.49,projectedAUV:4.43,compPrice:[1.00,2.00,2.52,3.66],comp3rdPartPrice:[2.11, 1.42, 4.75, 2.86],actualImpact:100.78,recomendedRBIPrice:3.79,projectedImpact:39.1,currentGp:52.54,currentcogs:39.8,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Large',price:2.99,projectedAUV:3.21,compPrice:[3.66,2.00,2.52,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],actualImpact:101.78,recomendedRBIPrice:3.09,projectedImpact:39.2,currentGp:47.46,currentcogs:20.6,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Loyalty - Medium',price:1.78,projectedAUV:2.65,compPrice:[3.66,2.00,3.00,1.43],comp3rdPartPrice:[2.11, 1.42, 4.75, 2.86],actualImpact:0.99,recomendedRBIPrice:1.87,projectedImpact:-0.4,currentGp:6.65,currentcogs:39.5,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Loyalty - Medium',price:1.93,projectedAUV:2.98,compPrice:[1.00,2.00,3.00,4.00],comp3rdPartPrice:[2.11, 1.42, 4.75, 2.86],actualImpact:1.22,recomendedRBIPrice:0,projectedImpact:-0.5,currentGp:1.92,currentcogs:38.5,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper',menuItemName:'Loyalty - Large',price:2.00,projectedAUV:3.21,compPrice:[3.66,2.00,3.00,2.26],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],actualImpact:2.32,recomendedRBIPrice:0,projectedImpact:0.1,currentGp:23.45,currentcogs:91.2,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper',menuItemName:'Loyalty - Large',price:2.05,projectedAUV:4.21,compPrice:[1.00,2.00,3.66,4.00],comp3rdPartPrice:[0.88, 3.03, 1.27, 0.11],actualImpact:2.46,recomendedRBIPrice:2.41,projectedImpact:0.5,currentGp:5.79,currentcogs:66.5,RbiPriceLoaded:false,override:'',},
        {resNum: 4, categoryId:'Whopper Jr.', menuItemName:'Whopper',price:0.55,projectedAUV:1.23,compPrice:[1.00,2.00,3.00,4.00],comp3rdPartPrice:[5.39, 4.71, 2.59, 1.37],actualImpact:3.19,recomendedRBIPrice:0.65,projectedImpact:4.8,currentGp:0.46,currentcogs:74.6,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'Whopper',price:0.44,projectedAUV:2.43,compPrice:[1.00,3.66,3.00,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],actualImpact:2.29,recomendedRBIPrice:0.55,projectedImpact:4.7,currentGp:0.35,currentcogs:70.9,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'Drink - S',price:1.56,projectedAUV:3.21,compPrice:[1.00,2.00,3.66,4.00],comp3rdPartPrice:[4.11, 2.42, 5.75, 6.86],actualImpact:138.17,recomendedRBIPrice:1.66,projectedImpact:87.7,currentGp:0.16,currentcogs:78.5,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'Drink - S',price:1.44,projectedAUV:3.42,compPrice:[1.00,3.66,3.00,2.26],comp3rdPartPrice:[0.88, 3.03, 1.27, 0.11],actualImpact:137.21,recomendedRBIPrice:1.63,projectedImpact:87.6,currentGp:0.62,currentcogs:73.0,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'French Fry - S',price:1.32,projectedAUV:2.31,compPrice:[1.00,2.00,3.00,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],actualImpact:2.42,recomendedRBIPrice:1.42,projectedImpact:0.8,currentGp:0.88,currentcogs:89.5,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'French Fry - S',price:1.43,projectedAUV:2.31,compPrice:[1.00,2.00,3.66,4.00],comp3rdPartPrice:[5.39, 4.71, 2.59, 1.37],actualImpact:2.64,recomendedRBIPrice:1.79,projectedImpact:0.7,currentGp:1.4,currentcogs:47.7,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'Burger Ch',price:2.26,projectedAUV:3.21,compPrice:[1.00,3.66,3.00,1.43],comp3rdPartPrice:[4.82, 5.11, 6.42, 8.92],actualImpact:161.16,recomendedRBIPrice:2.26,projectedImpact:70.2,currentGp:0.99,currentcogs:63.2,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'Burger Ch',price:3.36,projectedAUV:2.32,compPrice:[1.00,2.00,3.00,3.66],comp3rdPartPrice:[0.88, 3.03, 1.27, 0.11],actualImpact:153.21,recomendedRBIPrice:3.67,projectedImpact:70.3,currentGp:0.25,currentcogs:38.7,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'Chicken Nuggets 4 Pc',price:2.55,projectedAUV:1.23,compPrice:[1.00,2.00,3.00,0.55],comp3rdPartPrice:[1.88, 0.03, 3.27, 2.11],actualImpact:102.96,recomendedRBIPrice:2.65,projectedImpact:39.3,currentGp:0.56,currentcogs:10.6,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'Chicken Nuggets 4 Pc',price:3.66,projectedAUV:3.21,compPrice:[3.66,2.00,3.00,4.00],comp3rdPartPrice:[0.88, 3.03, 1.27, 0.11],actualImpact:100.44,recomendedRBIPrice:3.89,projectedImpact:39.4,currentGp:2.48,currentcogs:98.6,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'Royal Crispy Chicken',price:1.38,projectedAUV:1.43,compPrice:[1.00,2.00,3.00,1.43],comp3rdPartPrice:[2.35, 3.71, 1.05, 0.99],actualImpact:0.91,recomendedRBIPrice:1.48,projectedImpact:-0.3,currentGp:4.46,currentcogs:53.1,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'Royal Crispy Chicken',price:1.66,projectedAUV:2.21,compPrice:[1.00,3.66,3.00,4.00],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],actualImpact:0.56,recomendedRBIPrice:1.77,projectedImpact:-0.2,currentGp:9.49,currentcogs:34.6,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'Hash Rounds - S',price:1.91,projectedAUV:2.32,compPrice:[1.00,2.00,3.00,0.55],comp3rdPartPrice:[1.88, 0.03, 3.27, 2.11],actualImpact:2.23,recomendedRBIPrice:2.01,projectedImpact:0.2,currentGp:0.95,currentcogs:49.7,RbiPriceLoaded:false,override:'',},
        {resNum: 7, categoryId:'Whopper Jr.',menuItemName:'Hash Rounds - S',price:2.06,projectedAUV:2.32,compPrice:[1.00,3.66,3.00,4.00],comp3rdPartPrice:[4.11, 2.42, 5.75, 6.86],actualImpact:2.01,recomendedRBIPrice:2.36,projectedImpact:0.3,currentGp:5.41,currentcogs:84.3,RbiPriceLoaded:false,override:'',},
        {resNum: 10, categoryId:'Whopper Jr.',menuItemName:'Whopper',price:1.22,projectedAUV:2.32,compPrice:[1.00,2.00,2.52,4.00],comp3rdPartPrice:[5.39, 4.71, 2.59, 1.37],actualImpact:4.33,recomendedRBIPrice:1.43,projectedImpact:4.9,currentGp:4.45,currentcogs:80.2,RbiPriceLoaded:false,override:'',},
        {resNum: 6, categoryId:'Whopper Jr.',menuItemName:'Whopper',price:2.33,projectedAUV:3.56,compPrice:[1.00,1.93,2.52,1.43],comp3rdPartPrice:[3.11, 1.42, 4.75, 2.86],actualImpact:3.91,recomendedRBIPrice:2.54,projectedImpact:4.10,currentGp:60.46,currentcogs:60.7,RbiPriceLoaded:false,override:'',},
    ]

    
};