import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class CompetitorService {
  
     apiRoot: string = `${environment.competitorUrl}/api/competitors/`;

     constructor(private http: HttpClient){
     }

     getAll() {
        let result = this.http.get(this.apiRoot);
        return result;
      }

  }