import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UiComponentsService {

  constructor(private http: HttpClient) { }
  private apiRoot: string = `${environment.restaurantUrl}/api`

  getUIComponentByType(type: any) {   
    let url = `${this.apiRoot}/uicomponents/GetUiComponentsByComponentType?componentType=${type}`;
    return this.http.get(url);
  }


}