import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecommendationsService {
  apiRoot: string = `${environment.menuUrl}/api/recommendations`;

  constructor(private http: HttpClient) {}

  getRBIRecommendations(
    restaurantId: string,
    soldTypeId: string,
    subgroupName: string,
    serviceModeId: string
  ) {
    var query = `${this.apiRoot}/GetRBIRecommendations?restaurantId=${restaurantId}&subGroupName=${subgroupName}&servicemodeId=${serviceModeId}`;
    if (soldTypeId != null) {
      query += `&soldTypeId=${soldTypeId}`;
    }
    return this.http.get<Array<any>>(query);
  }

  getAllRBIRecommendations(restaurantNum: number) {
    var query = `${this.apiRoot}/GetAllVWRBIRecommendations?restaurantNum=${restaurantNum}`;
    return this.http.get<Array<any>>(query);
  }
}
